import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
    Chart,
    ArgumentAxis,
    ValueAxis,
    LineSeries,
    Title,
    Legend,
    PieSeries
} from "@devexpress/dx-react-chart-material-ui";
import Paper from "@mui/material/Paper";
import { Animation, EventTracker, HoverState } from "@devexpress/dx-react-chart";
import { Horse } from "../../types";
import { useSelectFromRedux } from "../../utils/_hooks";
import {
    Button,
    ButtonGroup,
    createTheme,
    FormControl,
    InputBase,
    InputLabel,
    MenuItem,
    Select,
    styled,
    TextField,
    ThemeProvider
} from "@mui/material";
import { Column, Row, CenteredText, MarketBuyContainer, ModalHeader, SpacedCenteredText, BasicListText } from "../_shared";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import { makeOrderServer, userUpdate } from "../../state/user/operations";
import { UserActions } from "../../state/user";
import CloseIcon from '@mui/icons-material/Close';
import { isMobileOnly } from "react-device-detect";


export default (props: {horse: Horse, buyMode: Boolean, setBuyMode: Dispatch<SetStateAction<Boolean>>}) => {
    const dispatch = useDispatch();

    // const horse = props.horse;
    // const buyMode = props.buyMode;
    // const setBuyMode = props.setBuyMode;

    const {horse, buyMode, setBuyMode} = props;

    const [text, background, logo, horses, user, transactionResponse] = useSelectFromRedux((state) => [
        state.color.scheme.text,
        state.color.scheme.background,
        state.color.scheme.logo,
        state.publicData.horses,
        state.user.user,
        state.user.transactionResponse
    ]);

    interface CostBreakDown {
        name: string;
        percent: number;
        point: number;
    }

    const theme = createTheme({
        palette: {
            primary: {
                main: "#ffc000"
            }
        }
    });

    const [type1, setType1] = useState<"horse" | "tokens">("tokens");
    const [type2, setType2] = useState<"horse" | "tokens">("horse");
    const [id1, setid1] = useState<number>(-1);
    const [id2, setid2] = useState<number>(horse.id);
    const [num1, setnum1] = useState<string>("");
    const [num2, setnum2] = useState<string>("");
    const [price1, setprice1] = useState<number>(1);
    const [price2, setprice2] = useState<number>(horse.price);

    const setHorse1 = (id: number) => {
        const horse = horses.find((horse) => horse.id == id);
        setid1(id);
        setprice1(horse?.price ?? 0);
    };
    const setHorse2 = (id: number) => {
        const horse = horses.find((horse) => horse.id == id);
        setid2(id);
        setprice2(horse?.price ?? 0);
    };

    const BootstrapInput = styled(InputBase)(({ theme }) => ({
        "label + &": {
            marginTop: theme.spacing(3)
        },
        "& .MuiInputBase-input": {
            borderRadius: 4,
            position: "relative",
            backgroundColor: background,
            border: "1px solid #ced4da",
            fontSize: 16,
            padding: "10px 26px 10px 12px",
            color: logo,
            // Use the system font instead of the default Roboto font.
            fontFamily: [
                "-apple-system",
                "BlinkMacSystemFont",
                '"Segoe UI"',
                "Roboto",
                '"Helvetica Neue"',
                "Arial",
                "sans-serif",
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"'
            ].join(","),
            "&:focus": {
                borderRadius: 4,
                borderColor: logo,
                boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
            }
        }
    }));

    useEffect(() => {
        dispatch(UserActions.setTransactionResponse(null))
    }, [num1, price1, price2]);

    const submit = () => {
        if(num1 && num2 && price1 && price2) {

            dispatch(
                makeOrderServer({
                    type: type1 == 'horse' ? 'sell' : 'buy',
                    order: {
                        user_id: user?.id,
                        horse_id: type1 == 'horse' ? id1 : id2,
                        shares: type1 == 'horse' ? Number(num1) : Number(num2),
                        price: type1 == 'horse' ? Number(num2)*price2/Number(num1) : Number(num1)*price1/Number(num2),
                        buy_req: type1 == 'horse' && type2 == 'horse' 
                            ? {
                                user_id: user?.id,
                                horse_id: id2,
                                shares: Number(num2),
                                price: price2,
                            }
                            : undefined
                    }
                })
            );
        }
    };

    const [error, setError] = useState<string>();

    const myOwnershipIDs = (user?.ownerships.length ?? 0) > 0 ? user?.ownerships.map((ownership) => ownership.horse_id) : [];

    useEffect(() => {
        if(type1 == 'tokens' && (Number(num1) ?? 0) > (user?.tokens ?? 0)) {
            setError('Not enough tokens for this transaction.');
        } else if(type1 == 'horse' && (Number(num1) ?? 0) > (user?.ownerships.find((ownership) => ownership.horse_id == id1)?.totalShares ?? -1)) {
            setError('Not enough shares for this transaction');
        } else { 
            setError(undefined)
        }
    }, [type1, num1, user])

    return (
        <>
        {buyMode && 
        <>
            {user ? 
            // <MarketBuyContainer color={text} background={background} style={{ top: '180px', left: '29%', borderColor: logo, borderWidth: '5px', borderStyle: 'solid', position: 'fixed'}} >
            //         <CloseIcon style={{paddingLeft:'90%', cursor: 'pointer'}} onClick={() => setBuyMode(false)}/>
            //         <ModalHeader color={logo} style={{minHeight: '20px'}}>
            //             Buy, Sell, or Trade
            //         </ModalHeader>
            <Column>
                <Row style={{ width: "100%", justifyContent: "center", alignContent: "center", background: background }}>
                    <Column style={{alignItems:'center'}}>
                        <BasicListText style={{ fontSize: "20px", marginBottom: "20px", color: logo }} >
                            Sell
                        </BasicListText>
                        <ThemeProvider theme={theme}>
                            <FormControl fullWidth={false} >
                                {/* <InputLabel id="type1-select">Type</InputLabel> */}
                                <Select
                                    labelId="type1-select"
                                    id="type1--select"
                                    value={type1}
                                    label="Type"
                                    onChange={(event) => {
                                        setType1(event.target.value as "horse" | "tokens");
                                        if (event.target.value == "tokens") {
                                            setType2("horse");
                                            setHorse2(horses[0].id);
                                            setprice1(1);
                                            setid1(-1);
                                        } else {
                                            setHorse1(horses[0].id);
                                            if(id2 == horses[0].id) {
                                                setid2(horses[1].id)
                                                setprice2(horses[1].price)
                                            }
                                        }
                                    }}
                                    input={<BootstrapInput />}
                                    style={{ width: isMobileOnly? "130px" : "150px", marginBottom: "30px" }}
                                >
                                    <MenuItem value={"horse"}>Horse</MenuItem>
                                    <MenuItem value={"tokens"}>Tokens</MenuItem>
                                </Select>
                            </FormControl>
                            {type1 == "horse" && (
                                <FormControl fullWidth>
                                    {/* <InputLabel id="type1-select">Type</InputLabel> */}
                                    <Select
                                        labelId="horse1-select"
                                        id="horse1--select"
                                        value={id1}
                                        label="Horse"
                                        onChange={(event) => setHorse1(event.target.value as number)}
                                        input={<BootstrapInput />}
                                        style={{ marginBottom: "30px", width: isMobileOnly? "130px" : "150px" }}
                                    >
                                        {horses.filter((horse) => myOwnershipIDs?.includes(horse.id)).map((horse) => (
                                            <MenuItem key={horse.id} value={horse.id}>
                                                {horse.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}
                            <TextField
                                id="standard-basic"
                                label={type1 == "horse" ? "shares" : "tokens"}
                                // variant='standard'
                                color="primary"
                                focused
                                value={num1}
                                onChange={(event) => {
                                     setnum1(event.target.value);
                                     setnum2(String(Number(event.target.value)*price1/price2))
                                }}
                                sx={{ input: { color: text } }}
                                style={{ marginBottom: "5px", maxWidth: isMobileOnly? "130px" : "150px", background: background  }}
                            />
                            <ButtonGroup variant='text' aria-label ='outlined button group' color='inherit' style={{color: text, paddingLeft: isMobileOnly?'0%':'5%', height: '20px', marginBottom: '20px'}}>
                                <Button 
                                    onClick={() => {
                                        if(type1 == 'tokens'){
                                            setnum1(String((user?.tokens) ?? 0))
                                            setnum2(String((user?.tokens ?? 0)*price1/price2))
                                        } else if(type1 == 'horse') {
                                            setnum1(String(user?.ownerships.find((ownership) => ownership.horse_id == id1)?.totalShares ?? -1))
                                            setnum2(String((user?.ownerships.find((ownership) => ownership.horse_id == id1)?.totalShares ?? -1)*price1/price2))
                                        }
                                    }}
                                    color='inherit' style={{color: text}}
                                >MAX</Button>
                                <Button 
                                    onClick={() => {
                                        if(type1 == 'tokens'){
                                            setnum1(String(1/2*(user?.tokens ?? 0)))
                                            setnum2(String(1/2*(user?.tokens ?? 0)*price1/price2))
                                        } else if(type1 == 'horse') {
                                            setnum1(String(1/2*(user?.ownerships.find((ownership) => ownership.horse_id == id1)?.totalShares ?? 0)))
                                            setnum2(String(1/2*(user?.ownerships.find((ownership) => ownership.horse_id == id1)?.totalShares ?? -1)*price1/price2))
                                        }
                                    }}
                                    color='inherit' style={{color:text}}
                                >1/2</Button>
                                <Button 
                                    onClick={() => {
                                        if(type1 == 'tokens'){
                                            setnum1(String(1/4*(user?.tokens ?? 0)))
                                            setnum2(String(1/4*(user?.tokens ?? 0)*price1/price2))
                                        } else if(type1 == 'horse') {
                                            setnum1(String(1/4*(user?.ownerships.find((ownership) => ownership.horse_id == id1)?.totalShares ?? 0)))
                                            setnum2(String(1/4*(user?.ownerships.find((ownership) => ownership.horse_id == id1)?.totalShares ?? -1)*price1/price2))
                                        }
                                    }}
                                    color='inherit' 
                                >1/4</Button>
                        </ButtonGroup>
                            <div style={{ color: text }}>Value: ${((Number(num1) ?? 0) * (price1 ?? 0)).toPrecision(6)}</div>
                        </ThemeProvider>
                    </Column>
                    <SwapHorizIcon
                        color="inherit"
                        style={{ color: logo }}
                        sx={{ fontSize: isMobileOnly?'80px':"120px", alignSelf: "center" }}
                    />
                    <Column>
                        <CenteredText style={{ fontSize: "20px", marginBottom: "20px" }} text={logo}>
                            Buy
                        </CenteredText>
                        <ThemeProvider theme={theme}>
                            <FormControl fullWidth>
                                {/* <InputLabel id="type1-select">Type</InputLabel> */}
                                <Select
                                    labelId="type2-select"
                                    id="type2--select"
                                    value={type2}
                                    label="Type"
                                    onChange={(event) => {
                                        setType2(event.target.value as "horse" | "tokens");
                                        if (event.target.value == "tokens") {
                                            setType1("horse");
                                            setHorse1(horses[0].id);
                                            setprice2(1);
                                        } else {
                                            setHorse2(horses[0].id);
                                        }
                                    }}
                                    input={<BootstrapInput />}
                                    style={{ width: isMobileOnly? "130px" : "150px", marginBottom: "30px" }}
                                >
                                    <MenuItem value={"horse"}>Horse</MenuItem>
                                    <MenuItem value={"tokens"}>Tokens</MenuItem>
                                </Select>
                            </FormControl>
                            {type2 == "horse" && (
                                <FormControl fullWidth>
                                    {/* <InputLabel id="type1-select">Type</InputLabel> */}
                                    <Select
                                        labelId="horse2-select"
                                        id="horse2--select"
                                        value={id2}
                                        label="Horse"
                                        onChange={(event) => setHorse2(event.target.value as number)}
                                        input={<BootstrapInput />}
                                        style={{ marginBottom: "30px", width: isMobileOnly? "130px" : "150px" }}
                                    >
                                        {horses.filter((horse) => horse.id != id1).map((horse) => (
                                            <MenuItem key={horse.id} value={horse.id}>
                                                {horse.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}
                            <TextField
                                id="standard-basic"
                                label={type2 == "horse" ? "shares" : "tokens"}
                                // variant='standard'
                                color="primary"
                                focused
                                value={num2}
                                onChange={(event) => {
                                    setnum2(event.target.value);
                                }}
                                sx={{ input: { color: text } }}
                                style={{ marginBottom: "30px", width: isMobileOnly? "130px" : "150px", background: background }}
                            />
                            <div style={{ color: text }}>Value: ${((Number(num2) ?? 0) * (price2 ?? 0)).toPrecision(7)}</div>
                        </ThemeProvider>
                    </Column>
                </Row>
                <CenteredText style={{ fontSize: "20px" }} text={text}>
                    {transactionResponse}
                </CenteredText>
                <CenteredText style={{ fontSize: "20px", marginBottom: "20px", marginTop: '20px' }} text={'red'}>
                    {error}
                </CenteredText>
                <Button variant="contained" style={{ alignSelf: "center", marginBottom: "10px" }} onClick={submit} disabled={!user ? true : error ? true : false}>
                    Make Order
                </Button>
                <CenteredText style={{ fontSize: "10px", marginBottom: "20px", marginTop: '5px' }} text={'text'}>
                    Orders with equal value will be completed immediately.
                </CenteredText>
            </Column>
        //</MarketBuyContainer>
         : 
         //<MarketBuyContainer color={text} background={background} style={{ top: '180px', left: '29%', borderColor: logo, borderWidth: '5px', borderStyle: 'solid', position: 'fixed', alignItems:'center', justifyContent:'center'}} >
            //<CloseIcon style={{paddingLeft:'90%', cursor: 'pointer'}} onClick={() => setBuyMode(false)}/>
            <SpacedCenteredText text={logo} style={{fontSize: '20px'}}>
                Sign up or Sign in to Buy, Trade, or Sell
            </SpacedCenteredText>
        //</MarketBuyContainer>
        }
        </>}
        </>
    );
};


import React, { useEffect, useRef, useState } from "react";

// redux
import { useSelectFromRedux, useDispatchPromise } from "../../utils/_hooks";
import { useDispatch } from "react-redux";
import EditIcon from '@mui/icons-material/Edit'
import { CenteredText, Column, LineDivider, Row, SpacedCenteredText } from "../_shared";
import { Button, createTheme, TextField, ThemeProvider } from "@mui/material";
import { UserActions } from "../../state/user";
import { ContentActions } from "../../state/content";
import { CenteredDiv } from "../site/styled";
import api from "../../api";
import { serverSignIn } from "../../state/user/operations";

//components

export default (_props: any) => {

    const [
        user, 
        text, 
        error, 
        success,
        logo,
        background,
        signInError,
        signUpError
    ] = useSelectFromRedux((state) => [
        state.user.user, 
        state.color.scheme.text, 
        state.color.general.error,
        state.color.general.success,
        state.color.scheme.logo,
        state.color.scheme.background,
        state.user.signInError,
        state.user.signUpError
    ]);
    const dispatch = useDispatch();

    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const signIn = () => {
        dispatch(serverSignIn({email: email, password: password}))
    }

    const theme = createTheme({
        palette: {
            primary: {
                main: '#E5C078'
            }
        }
    })

    useEffect(() => {
        dispatch(UserActions.setSignInError(null));
    }, [email, password])


    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems:'center', overflow: 'scroll', height: '100vh', transitionDuration: '500ms'}}>
            <SpacedCenteredText text={text} style={{
                display: 'flex', 
                flexDirection:'column', 
                marginTop:'150px', 
                fontSize: '40px', 
                zIndex: 5,
                width:'fit-content'
            }}>
                Sign In
            </SpacedCenteredText>
            <Column style={{
                alignItems: 'center', 
                zIndex: 4, 
                background: background, 
                marginTop: '30px', 
                width:'fit-content',
                padding: '20px', 
                borderRadius: '10px',
                borderWidth: '5px',
                borderColor: logo,
                borderStyle: 'outset'
                }}>
                {/* <CenteredDiv text={text} style={{paddingBottom: '20px'}}>
                    Password
                </CenteredDiv> */}
                {signUpError == "User Registered Successfully" && <CenteredText text={success} style={{paddingLeft: 0, marginBottom: '15px'}}>
                    {signUpError}
                </CenteredText>}
                <ThemeProvider theme={theme}>
                    <TextField 
                        id='standard-basic' 
                        label='Email'
                        variant='standard' 
                        focused
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        sx={{input: {color: text}, color: text}}
                        style={{marginBottom: '10px', width: '200px', background: background, zIndex: 4}}
                    />
                    <TextField 
                        id='standard-basic' 
                        label='Password'
                        variant='standard'
                        focused
                        type='password'
                        color='primary'
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                        sx={{input: {color: text}, color: text}}
                        style={{marginBottom: '10px', width: '200px', background: background, zIndex: 4}}
                    />
                </ThemeProvider>
                <CenteredText text={error} style={{paddingLeft: 0}}>
                    {signInError}
                </CenteredText>
                <Button
                    variant='contained'
                    style={{alignSelf: 'center', background: logo, color: background, marginTop: '20px'}}
                    onClick={signIn}
                >
                    Sign in
                </Button>
            </Column>
        </div>
    );
};
import React, { useEffect, useState } from 'react';
import { CenteredText, VerticallyCenteredRow, Column, Row, SpacedCenteredText, ModalContainer, ModalHeader, FilterContainer, LineDivider} from "../_shared";
import { serverAddEvent, serverAddHorse, serverAddNews, serverModifyEvent, serverModifyHorse, serverModifyNews } from '../../state/publicData/operations'
import { useSelectFromRedux } from '../../utils/_hooks';
import { useDispatch } from "react-redux";
import { Button, createTheme, MenuItem, Select, TextField, ThemeProvider } from "@mui/material";
import api from "../../api";
import CloseIcon from '@mui/icons-material/Close';
import { Horse, HorseValueData, NewsItem, RaceEvent, UserAccount, TransactionInfo } from "../../types";


export default (props: any) => {

    const [horses] = useSelectFromRedux((state) => [state.publicData.horses]);

    const [news, setNews] = useState<NewsItem[]>([]);

    const [events, setEvents] = useState<RaceEvent[]>([]);
    

    useEffect(() => {
        const fetchData = async () => {
            const response1 = await api.get('/public/getEvents');
            setEvents(response1.data);
            const response2 = await api.get('/public/getNews');
            setNews(response2.data);
        };

        fetchData().catch(console.error);
    }, [])


    const dispatch = useDispatch();
    
    


    const theme = createTheme({
        palette: {
            primary: {
                main: '#E5C078'
            }
        }
    })



    const [horseInfo, setHorseInfo] = useState({
        id: undefined,
        name: '',
        sire: '', 
        dam: '', 
        age: 0,
        grade: '',
        sex: '' ,
        trainer: '',
        circuit: '',
        price: 0, 
        historical_price: [],
        photo: '',
        jockey: '',
        size: '',
        speed: '',
        workout: '',
        description: '',
        block: ''
    })
    

 const [newsInfo, setNewsInfo] = useState({ 
    id: undefined,
    title: '',
    photo: '',
    text: '',
    block: ''
    })

const [eventInfo, setEventInfo] = useState({
    id: undefined,  
    title: '',
    photo: '',
    text: '',
    date: '',
    location: '',
    block: ''
    })

    const [incomplete, setincomplete] = useState<boolean>(false);

    const [openEvent, setOpenEvent] = useState(false);

    const [openHorse, setOpenHorse] = useState(false);

    const [openNews, setOpenNews] = useState(false);

    const [
        user, 
        text, 
        error, 
        success,
        logo,
        background,
    
    ] = useSelectFromRedux((state) => [
        state.user.user, 
        state.color.scheme.text, 
        state.color.general.error,
        state.color.general.success,
        state.color.scheme.logo,
        state.color.scheme.background,
   
    ]);


    const [modifyType, setModifyType] = useState<'horse' | 'news' | 'event'>();

    const addNews = () => {
        if(newsInfo.title === '' ||
            newsInfo.photo === '' ||
            newsInfo.text === '' ||
            newsInfo.block !== 'tc$trading234'
            
        ) {
            setincomplete(true)
        } else {
            dispatch(serverAddNews(newsInfo))
            setOpenNews(false)
        }
    }

    const modifyNews = () => {
        if(newsInfo.title === '' ||
            newsInfo.photo === '' ||
            newsInfo.text === '' ||
            newsInfo.block !== 'tc$trading234'
            
        ) {
            setincomplete(true)
        } else {
            dispatch(serverModifyNews(newsInfo))
            setModifyType(undefined)
        }
    }



    const addHorse = () => {
        if(horseInfo.name === '' ||
            horseInfo.sire === '' ||
            horseInfo.dam === '' ||
            horseInfo.age === 0 ||
            horseInfo.grade === '' ||
            horseInfo.sex === '' ||
            horseInfo.trainer === '' ||
            horseInfo.circuit === '' ||
            horseInfo.price === 0 ||
            horseInfo.photo === '' ||
            horseInfo.jockey === '' ||
            horseInfo.size === '' ||
            horseInfo.speed === '' ||
            horseInfo.workout === '' ||
            horseInfo.description === '' ||
            horseInfo.block !== 'tc$trading234'
            
        ) {
            setincomplete(true)
        } else {
            dispatch(serverAddHorse(horseInfo))
            setOpenHorse(false)
        }
    }

    const modifyHorse = () => {
        if(horseInfo.name === '' ||
            horseInfo.sire === '' ||
            horseInfo.dam === '' ||
            horseInfo.age === 0 ||
            horseInfo.grade === '' ||
            horseInfo.sex === '' ||
            horseInfo.trainer === '' ||
            horseInfo.circuit === '' ||
            horseInfo.price === 0 ||
            horseInfo.photo === '' ||
            horseInfo.jockey === '' ||
            horseInfo.size === '' ||
            horseInfo.speed === '' ||
            horseInfo.workout === '' ||
            horseInfo.description === '' ||
            horseInfo.block !== 'tc$trading234'
            
        ) {
            setincomplete(true)
        } else {
            dispatch(serverModifyHorse(horseInfo))
            setModifyType(undefined)
        }
    }

    const addEvent = () => {
        if(eventInfo.title === '' ||
            eventInfo.photo === '' ||
            eventInfo.text === '' ||
            eventInfo.date === '' ||
            eventInfo.location === '' ||
            eventInfo.block !== 'tc$trading234'
            
        ) {
            setincomplete(true)
        } else {
            dispatch(serverAddEvent(eventInfo))
            setOpenEvent(false)
            
        }
    }

    const modifyEvent = () => {
        if(eventInfo.title === '' ||
            eventInfo.photo === '' ||
            eventInfo.text === '' ||
            eventInfo.date === '' ||
            eventInfo.location === '' ||
            eventInfo.block !== 'tc$trading234'
            
        ) {
            setincomplete(true)
        } else {
            dispatch(serverModifyEvent(eventInfo))
            setModifyType(undefined)
            
        }
    }



    if(!user?.admin) {
        return (
            <>
            You don't have privileges to access this.
            </>
        )
    }

  


    return (
        <div style={{minHeight: '100vh', display: 'flex', justifyContent: 'space-evenly', alignContent: 'center', alignItems: 'center', flexWrap: 'wrap'}}>
        <Button  onClick = {() => {setOpenNews(true); setOpenHorse(false); setOpenEvent(false);}} style={{color: logo, textAlign: 'center', marginTop: '130px', fontSize: '30px', zIndex: 4, background: background, width: '400px'}}>
                Add News
            </Button>
        <Button onClick = {() => {setOpenNews(false); setOpenHorse(true); setOpenEvent(false);}}  style={{color: logo, textAlign: 'center', marginTop: '130px', fontSize: '30px', zIndex: 4, background: background, width: '400px'}}>
                Add Horse
            </Button>

        <Button onClick = {() => {setOpenNews(false); setOpenHorse(false); setOpenEvent(true);}}  style={{color: logo, textAlign: 'center', marginTop: '130px', fontSize: '30px', zIndex: 4, background: background, width: '400px'}}>
                Add Event
            </Button>
        <Button  onClick = {() => setModifyType('news')} style={{color: logo, textAlign: 'left', marginTop: '130px', fontSize: '30px', zIndex: 4, background: background, width: '400px'}}>
                Modify News
            </Button>
        <Button onClick = {() => setModifyType('horse')}  style={{color: logo, textAlign: 'center', marginTop: '130px', fontSize: '30px', zIndex: 4, background: background, width: '400px'}}>
                Modify Horse
            </Button>
        <Button onClick = {() => setModifyType('event')}  style={{color: logo, textAlign: 'right', marginTop: '130px', fontSize: '30px', zIndex: 4, background: background, width: '400px'}}>
                Modify Event
            </Button>
        {/* modals */}
            {openNews && 
            <ModalContainer color={text} background={background} style={{borderColor: logo, borderWidth: '5px', borderStyle: 'solid', height: '80%' }} >
                 <CloseIcon style={{top: '15x', right: '15px', position: 'absolute', cursor: 'pointer'}} onClick={() => {setOpenNews(false);setincomplete(false)}}/>
                 <Column style={{alignItems: 'center', zIndex: 4, background: background, marginTop: '30px'}}>
                {/* <CenteredDiv text={text} style={{paddingBottom: '20px'}}>
                    Password
                </CenteredDiv> */}
                <ThemeProvider theme={theme}>
                    <TextField 
                        label='Title'
                        variant='standard'
                        focused
                        value={newsInfo.title}
                        onChange={(event) => setNewsInfo({...newsInfo, title: event.target.value})}
                        sx={{input: {color: text}, color: text}}
                        style={{marginBottom: '10px', width: '200px', background: background, zIndex: 4}}
                    />
                    <TextField 
                        label='Photo'
                        variant='standard'
                        focused
                        value={newsInfo.photo}
                        onChange={(event) => setNewsInfo({...newsInfo, photo: event.target.value})}
                        sx={{input: {color: text}, color: text}}
                        style={{marginBottom: '10px', width: '200px', background: background, zIndex: 4}}
                    />
                    <TextField 
                        label='Text'
                        variant='standard'
                        focused
                        multiline
                        value={newsInfo.text}
                        onChange={(event) => setNewsInfo({...newsInfo, text: event.target.value})}
                        sx={{input: {color: text}, color: text}}
                        style={{marginBottom: '10px', width: '200px', background: background, zIndex: 4}}
                    />
                   <TextField 
                        label='Block'
                        variant='standard'
                        focused
                        value={newsInfo.block}
                        onChange={(event) => setNewsInfo({...newsInfo, block: event.target.value})}
                        sx={{input: {color: text}, color: text}}
                        style={{marginBottom: '10px', width: '200px', background: background, zIndex: 4}}
                    />  
                </ThemeProvider>
            </Column>

            {incomplete && <CenteredText text={error} style={{paddingLeft: 0, marginTop: '10px'}}>
                    Information is missing and/or the block is incorrect
                </CenteredText>}
            <Button
                    variant='contained'
                    style={{alignSelf: 'center', background: logo, color: background, marginTop: '20px', marginBottom: '200px'}}
                    onClick={addNews}
                >
                    Add News 
                </Button>
            </ModalContainer>}



            {modifyType === 'news' && 
            <ModalContainer color={text} background={background} style={{borderColor: logo, borderWidth: '5px', borderStyle: 'solid', height: '80%' }} >
                 <CloseIcon style={{top: '15x', right: '15px', position: 'absolute', cursor: 'pointer'}} onClick={() => setModifyType(undefined)}/>
                 <Column style={{alignItems: 'center', zIndex: 4, background: background, marginTop: '30px'}}>
                {/* <CenteredDiv text={text} style={{paddingBottom: '20px'}}>
                    Password
                </CenteredDiv> */}
                <Select
                    labelId="newsselect"
                    id="newsselect"
                    value={newsInfo}
                    renderValue={(value) => value.title}
                    label="Select News Item to Modify"
                    color='primary'
                    onChange={(event: any) => setNewsInfo(event.target.value)}
                    style={{ marginBottom: "30px", width: "500px", color: text}}
                >
                    {news.map((news : any) => (
                        <MenuItem key={news.id} value={news}>
                            {news.title}
                        </MenuItem>
                    ))}
                </Select>
                <ThemeProvider theme={theme}>
                    <TextField 
                        label='Title'
                        variant='standard'
                        focused
                        value={newsInfo.title}
                        onChange={(event) => setNewsInfo({...newsInfo, title: event.target.value})}
                        sx={{input: {color: text}, color: text}}
                        style={{marginBottom: '10px', width: '200px', background: background, zIndex: 4}}
                    />
                    <TextField 
                        label='Photo'
                        variant='standard'
                        focused
                        value={newsInfo.photo}
                        onChange={(event) => setNewsInfo({...newsInfo, photo: event.target.value})}
                        sx={{input: {color: text}, color: text}}
                        style={{marginBottom: '10px', width: '200px', background: background, zIndex: 4}}
                    />
                    <TextField 
                        label='Text'
                        variant='standard'
                        focused
                        value={newsInfo.text}
                        multiline
                        onChange={(event) => setNewsInfo({...newsInfo, text: event.target.value})}
                        sx={{input: {color: text}, color: text}}
                        style={{marginBottom: '10px', width: '200px', background: background, zIndex: 4}}
                    />
                   <TextField 
                        label='Block'
                        variant='standard'
                        focused
                        value={newsInfo.block}
                        onChange={(event) => setNewsInfo({...newsInfo, block: event.target.value})}
                        sx={{input: {color: text}, color: text}}
                        style={{marginBottom: '10px', width: '200px', background: background, zIndex: 4}}
                    />  
                </ThemeProvider>
            </Column>

            {incomplete && <CenteredText text={error} style={{paddingLeft: 0, marginTop: '10px'}}>
                    Information is missing and/or the block is incorrect
                </CenteredText>}
            <Button
                    variant='contained'
                    style={{alignSelf: 'center', background: logo, color: background, marginTop: '20px', marginBottom: '200px'}}
                    onClick={modifyNews}
                >
                    Modify News 
                </Button>
            </ModalContainer>}
                  
            {openHorse && 
            <ModalContainer color={text} background={background} style={{borderColor: logo, borderWidth: '5px', borderStyle: 'solid', height: '80%' }} >
                 <CloseIcon style={{top: '15x', right: '15px', position: 'absolute', cursor: 'pointer'}} onClick={() => {setOpenHorse(false); setincomplete(false)}}/>
                 <Column style={{alignItems: 'center', zIndex: 4, background: background, marginTop: '30px'}}>
                {/* <CenteredDiv text={text} style={{paddingBottom: '20px'}}>
                    Password
                </CenteredDiv> */}
                <ThemeProvider theme={theme}>
                    <TextField 
                        label='Name'
                        variant='standard'
                        focused
                        value={horseInfo.name}
                        onChange={(event) => setHorseInfo({...horseInfo, name: event.target.value})}
                        sx={{input: {color: text}, color: text}}
                        style={{marginBottom: '10px', width: '200px', background: background, zIndex: 4}}
                    />
                     
                     <TextField 
                        label='Sire'
                        variant='standard'
                        focused
                        value={horseInfo.sire}
                        onChange={(event) => setHorseInfo({...horseInfo, sire: event.target.value})}
                        sx={{input: {color: text}, color: text}}
                        style={{marginBottom: '10px', width: '200px', background: background, zIndex: 4}}
                    />

                    <TextField 
                        label='Dam'
                        variant='standard'
                        focused
                        value={horseInfo.dam}
                        onChange={(event) => setHorseInfo({...horseInfo, dam: event.target.value})}
                        sx={{input: {color: text}, color: text}}
                        style={{marginBottom: '10px', width: '200px', background: background, zIndex: 4}}
                    />

                    <TextField 
                        label='Age'
                        variant='standard'
                        focused
                        value={horseInfo.age.toString() == 'NaN' ? '' : horseInfo.age}
                        onChange={(event) => setHorseInfo({...horseInfo, age: +event.target.value})}
                        sx={{input: {color: text}, color: text}}
                        style={{marginBottom: '10px', width: '200px', background: background, zIndex: 4}}
                    />

                    <TextField 
                        label='Grade'
                        variant='standard'
                        focused
                        value={horseInfo.grade}
                        onChange={(event) => setHorseInfo({...horseInfo, grade: event.target.value})}
                        sx={{input: {color: text}, color: text}}
                        style={{marginBottom: '10px', width: '200px', background: background, zIndex: 4}}
                    />
                   
                   <TextField 
                        label='Sex'
                        variant='standard'
                        focused
                        value={horseInfo.sex}
                        onChange={(event) => setHorseInfo({...horseInfo, sex: event.target.value})}
                        sx={{input: {color: text}, color: text}}
                        style={{marginBottom: '10px', width: '200px', background: background, zIndex: 4}}
                    />

                    <TextField 
                        label='Trainer'
                        variant='standard'
                        focused
                        value={horseInfo.trainer}
                        onChange={(event) => setHorseInfo({...horseInfo, trainer: event.target.value})}
                        sx={{input: {color: text}, color: text}}
                        style={{marginBottom: '10px', width: '200px', background: background, zIndex: 4}}
                    />  

                    <TextField 
                        label='Circuit'
                        variant='standard'
                        focused
                        value={horseInfo.circuit}
                        onChange={(event) => setHorseInfo({...horseInfo, circuit: event.target.value})}
                        sx={{input: {color: text}, color: text}}
                        style={{marginBottom: '10px', width: '200px', background: background, zIndex: 4}}
                    />

                    <TextField 
                        label='Price'
                        variant='standard'
                        focused
                        value={horseInfo.price.toString() == 'NaN' ? '' : horseInfo.price}
                        onChange={(event) => setHorseInfo({...horseInfo, price: +event.target.value})}
                        sx={{input: {color: text}, color: text}}
                        style={{marginBottom: '10px', width: '200px', background: background, zIndex: 4}}
                    />

                    {/* <TextField 
                        label='Historical Price'
                        variant='standard'
                        focused
                        value={horseInfo.historical_price}
                        onChange={(event) => setHorseInfo({...horseInfo, historical_price: +event.target.value})}
                        sx={{input: {color: text}, color: text}}
                        style={{marginBottom: '10px', width: '200px', background: background, zIndex: 4}}
                    /> */}

                    <TextField 
                        label='Photo'
                        variant='standard'
                        focused
                        value={horseInfo.photo}
                        onChange={(event) => setHorseInfo({...horseInfo, photo: event.target.value})}
                        sx={{input: {color: text}, color: text}}
                        style={{marginBottom: '10px', width: '200px', background: background, zIndex: 4}}
                    />    
                    
                    <TextField 
                        label='Jockey'
                        variant='standard'
                        focused
                        value={horseInfo.jockey}
                        onChange={(event) => setHorseInfo({...horseInfo, jockey: event.target.value})}
                        sx={{input: {color: text}, color: text}}
                        style={{marginBottom: '10px', width: '200px', background: background, zIndex: 4}}
                    />

                    <TextField 
                        label='Size'
                        variant='standard'
                        focused
                        value={horseInfo.size}
                        onChange={(event) => setHorseInfo({...horseInfo, size: event.target.value})}
                        sx={{input: {color: text}, color: text}}
                        style={{marginBottom: '10px', width: '200px', background: background, zIndex: 4}}
                    />

                    <TextField 
                        label='Speed'
                        variant='standard'
                        focused
                        value={horseInfo.speed}
                        onChange={(event) => setHorseInfo({...horseInfo, speed: event.target.value})}
                        sx={{input: {color: text}, color: text}}
                        style={{marginBottom: '10px', width: '200px', background: background, zIndex: 4}}
                    />

                    <TextField 
                        label='Workout'
                        variant='standard'
                        focused
                        value={horseInfo.workout}
                        onChange={(event) => setHorseInfo({...horseInfo, workout: event.target.value})}
                        sx={{input: {color: text}, color: text}}
                        style={{marginBottom: '10px', width: '200px', background: background, zIndex: 4}}
                    />

                    <TextField 
                        label='Description'
                        variant='standard'
                        focused
                        value={horseInfo.description}
                        multiline
                        onChange={(event) => setHorseInfo({...horseInfo, description: event.target.value})}
                        sx={{input: {color: text}, color: text}}
                        style={{marginBottom: '10px', width: '200px', background: background, zIndex: 4}}
                    />

                    <TextField 
                        label='Block'
                        variant='standard'
                        focused
                        value={horseInfo.block}
                        onChange={(event) => setHorseInfo({...horseInfo, block: event.target.value})}
                        sx={{input: {color: text}, color: text}}
                        style={{marginBottom: '10px', width: '200px', background: background, zIndex: 4}}
                    />

                </ThemeProvider>
            </Column>
            {incomplete && <CenteredText text={error} style={{paddingLeft: 0, marginTop: '10px'}}>
                    Information is missing and/or the block is incorrect
                </CenteredText>}
            <Button
                    variant='contained'
                    style={{alignSelf: 'center', background: logo, color: background, marginTop: '20px', marginBottom: '200px'}}
                    onClick={addHorse}
                >
                    Add Horse 
                </Button>
            </ModalContainer>}

            {modifyType == "horse" && 
            <ModalContainer color={text} background={background} style={{borderColor: logo, borderWidth: '5px', borderStyle: 'solid', height: '80%' }} >
                 <CloseIcon style={{top: '15x', right: '15px', position: 'absolute', cursor: 'pointer'}} onClick={() => setModifyType(undefined)}/>
                 <Column style={{alignItems: 'center', zIndex: 4, background: background, marginTop: '30px'}}>
                {/* <CenteredDiv text={text} style={{paddingBottom: '20px'}}>
                    Password
                </CenteredDiv> */}
                <Select
                    labelId="horseselect"
                    id="horseselect"
                    value={horseInfo}
                    renderValue={(value) => value.name}
                    label="Select Horse to Modify"
                    color='primary'
                    onChange={(event: any) => setHorseInfo(event.target.value)}
                    style={{ marginBottom: "30px", width: "500px", color: text}}
                >
                    {horses?.map((horse : any) => (
                        <MenuItem key={horse.id} value={horse}>
                            {horse.name}
                        </MenuItem>
                    ))}
                </Select>
                <ThemeProvider theme={theme}>
                    <TextField 
                        label='Name'
                        variant='standard'
                        focused
                        value={horseInfo.name}
                        onChange={(event) => setHorseInfo({...horseInfo, name: event.target.value})}
                        sx={{input: {color: text}, color: text}}
                        style={{marginBottom: '10px', width: '200px', background: background, zIndex: 4}}
                    />
                     
                     <TextField 
                        label='Sire'
                        variant='standard'
                        focused
                        value={horseInfo.sire}
                        onChange={(event) => setHorseInfo({...horseInfo, sire: event.target.value})}
                        sx={{input: {color: text}, color: text}}
                        style={{marginBottom: '10px', width: '200px', background: background, zIndex: 4}}
                    />

                    <TextField 
                        label='Dam'
                        variant='standard'
                        focused
                        value={horseInfo.dam}
                        onChange={(event) => setHorseInfo({...horseInfo, dam: event.target.value})}
                        sx={{input: {color: text}, color: text}}
                        style={{marginBottom: '10px', width: '200px', background: background, zIndex: 4}}
                    />

                    <TextField 
                        label='Age'
                        variant='standard'
                        focused
                        value={horseInfo.age.toString() == 'NaN' ? '' : horseInfo.age}
                        onChange={(event) => setHorseInfo({...horseInfo, age: +event.target.value})}
                        sx={{input: {color: text}, color: text}}
                        style={{marginBottom: '10px', width: '200px', background: background, zIndex: 4}}
                    />

                    <TextField 
                        label='Grade'
                        variant='standard'
                        focused
                        value={horseInfo.grade}
                        onChange={(event) => setHorseInfo({...horseInfo, grade: event.target.value})}
                        sx={{input: {color: text}, color: text}}
                        style={{marginBottom: '10px', width: '200px', background: background, zIndex: 4}}
                    />
                   
                   <TextField 
                        label='Sex'
                        variant='standard'
                        focused
                        value={horseInfo.sex}
                        onChange={(event) => setHorseInfo({...horseInfo, sex: event.target.value})}
                        sx={{input: {color: text}, color: text}}
                        style={{marginBottom: '10px', width: '200px', background: background, zIndex: 4}}
                    />

                    <TextField 
                        label='Trainer'
                        variant='standard'
                        focused
                        value={horseInfo.trainer}
                        onChange={(event) => setHorseInfo({...horseInfo, trainer: event.target.value})}
                        sx={{input: {color: text}, color: text}}
                        style={{marginBottom: '10px', width: '200px', background: background, zIndex: 4}}
                    />  

                    <TextField 
                        label='Circuit'
                        variant='standard'
                        focused
                        value={horseInfo.circuit}
                        onChange={(event) => setHorseInfo({...horseInfo, circuit: event.target.value})}
                        sx={{input: {color: text}, color: text}}
                        style={{marginBottom: '10px', width: '200px', background: background, zIndex: 4}}
                    />

                    <TextField 
                        label='Price'
                        variant='standard'
                        focused
                        value={horseInfo.price.toString() == 'NaN' ? '' : horseInfo.price}
                        onChange={(event) => setHorseInfo({...horseInfo, price: +event.target.value})}
                        sx={{input: {color: text}, color: text}}
                        style={{marginBottom: '10px', width: '200px', background: background, zIndex: 4}}
                    />

                    {/* <TextField 
                        label='Historical Price'
                        variant='standard'
                        focused
                        value={horseInfo.historical_price}
                        onChange={(event) => setHorseInfo({...horseInfo, historical_price: +event.target.value})}
                        sx={{input: {color: text}, color: text}}
                        style={{marginBottom: '10px', width: '200px', background: background, zIndex: 4}}
                    /> */}

                    <TextField 
                        label='Photo'
                        variant='standard'
                        focused
                        value={horseInfo.photo}
                        onChange={(event) => setHorseInfo({...horseInfo, photo: event.target.value})}
                        sx={{input: {color: text}, color: text}}
                        style={{marginBottom: '10px', width: '200px', background: background, zIndex: 4}}
                    />    
                    
                    <TextField 
                        label='Jockey'
                        variant='standard'
                        focused
                        value={horseInfo.jockey}
                        onChange={(event) => setHorseInfo({...horseInfo, jockey: event.target.value})}
                        sx={{input: {color: text}, color: text}}
                        style={{marginBottom: '10px', width: '200px', background: background, zIndex: 4}}
                    />

                    <TextField 
                        label='Size'
                        variant='standard'
                        focused
                        value={horseInfo.size}
                        onChange={(event) => setHorseInfo({...horseInfo, size: event.target.value})}
                        sx={{input: {color: text}, color: text}}
                        style={{marginBottom: '10px', width: '200px', background: background, zIndex: 4}}
                    />

                    <TextField 
                        label='Speed'
                        variant='standard'
                        focused
                        value={horseInfo.speed}
                        onChange={(event) => setHorseInfo({...horseInfo, speed: event.target.value})}
                        sx={{input: {color: text}, color: text}}
                        style={{marginBottom: '10px', width: '200px', background: background, zIndex: 4}}
                    />

                    <TextField 
                        label='Workout'
                        variant='standard'
                        focused
                        value={horseInfo.workout}
                        onChange={(event) => setHorseInfo({...horseInfo, workout: event.target.value})}
                        sx={{input: {color: text}, color: text}}
                        style={{marginBottom: '10px', width: '200px', background: background, zIndex: 4}}
                    />

                    <TextField 
                        label='Description'
                        variant='standard'
                        focused
                        value={horseInfo.description}
                        multiline
                        onChange={(event) => setHorseInfo({...horseInfo, description: event.target.value})}
                        sx={{input: {color: text}, color: text}}
                        style={{marginBottom: '10px', width: '200px', background: background, zIndex: 4}}
                    />

                    <TextField 
                        label='Block'
                        variant='standard'
                        focused
                        value={horseInfo.block}
                        onChange={(event) => setHorseInfo({...horseInfo, block: event.target.value})}
                        sx={{input: {color: text}, color: text}}
                        style={{marginBottom: '10px', width: '200px', background: background, zIndex: 4}}
                    />

                </ThemeProvider>
            </Column>
            {incomplete && <CenteredText text={error} style={{paddingLeft: 0, marginTop: '10px'}}>
                    Information is missing and/or the block is incorrect
                </CenteredText>}
            <Button
                    variant='contained'
                    style={{alignSelf: 'center', background: logo, color: background, marginTop: '20px', marginBottom: '200px'}}
                    onClick={modifyHorse}
                >
                    Modify Horse 
                </Button>
            </ModalContainer>}
         
            {openEvent && 
            <ModalContainer color={text} background={background} style={{borderColor: logo, borderWidth: '5px', borderStyle: 'solid', height: '80%' }} >
                 <CloseIcon style={{top: '15x', right: '15px', position: 'absolute', cursor: 'pointer'}} onClick={() => {setOpenEvent(false); setincomplete(false)}}/>
                 <Column style={{alignItems: 'center', zIndex: 4, background: background, marginTop: '30px'}}>
                {/* <CenteredDiv text={text} style={{paddingBottom: '20px'}}>
                    Password
                </CenteredDiv> */}
                <ThemeProvider theme={theme}>
                    <TextField 
                        label='Title'
                        variant='standard'
                        focused
                        value={eventInfo.title}
                        onChange={(event) => setEventInfo({...eventInfo, title: event.target.value})}
                        sx={{input: {color: text}, color: text}}
                        style={{marginBottom: '10px', width: '200px', background: background, zIndex: 4}}
                    />
                    <TextField 
                        label='Photo'
                        variant='standard'
                        focused
                        value={eventInfo.photo}
                        onChange={(event) => setEventInfo({...eventInfo, photo: event.target.value})}
                        sx={{input: {color: text}, color: text}}
                        style={{marginBottom: '10px', width: '200px', background: background, zIndex: 4}}
                    />
                    <TextField 
                        label='Text'
                        variant='standard'
                        focused
                        value={eventInfo.text}
                        multiline
                        onChange={(event) => setEventInfo({...eventInfo, text: event.target.value})}
                        sx={{input: {color: text}, color: text}}
                        style={{marginBottom: '10px', width: '200px', background: background, zIndex: 4}}
                    />

                    <TextField 
                        label='Date'
                        variant='standard'
                        focused
                        value={eventInfo.date}
                        onChange={(event) => setEventInfo({...eventInfo, date: event.target.value})}
                        sx={{input: {color: text}, color: text}}
                        style={{marginBottom: '10px', width: '200px', background: background, zIndex: 4}}
                    />

                    <TextField 
                        label='Location'
                        variant='standard'
                        focused
                        value={eventInfo.location}
                        onChange={(event) => setEventInfo({...eventInfo, location: event.target.value})}
                        sx={{input: {color: text}, color: text}}
                        style={{marginBottom: '10px', width: '200px', background: background, zIndex: 4}}
                    />       

                   <TextField 
                        label='Block'
                        variant='standard'
                        focused
                        value={eventInfo.block}
                        onChange={(event) => setEventInfo({...eventInfo, block: event.target.value})}
                        sx={{input: {color: text}, color: text}}
                        style={{marginBottom: '10px', width: '200px', background: background, zIndex: 4}}
                    />
                    
                </ThemeProvider>
            </Column>
            {incomplete && <CenteredText text={error} style={{paddingLeft: 0, marginTop: '10px'}}>
                    Information is missing and/or the block is incorrect
                </CenteredText>}
            <Button
                    variant='contained'
                    style={{alignSelf: 'center', background: logo, color: background, marginTop: '20px', marginBottom: '200px'}}
                    onClick={addEvent}
                >
                    Add Event
                </Button>
            </ModalContainer>}

            {modifyType == 'event' && 
            <ModalContainer color={text} background={background} style={{borderColor: logo, borderWidth: '5px', borderStyle: 'solid', height: '80%' }} >
                 <CloseIcon style={{top: '15x', right: '15px', position: 'absolute', cursor: 'pointer'}} onClick={() => setModifyType(undefined)}/>
                 <Column style={{alignItems: 'center', zIndex: 4, background: background, marginTop: '30px'}}>
                {/* <CenteredDiv text={text} style={{paddingBottom: '20px'}}>
                    Password
                </CenteredDiv> */}
                <Select
                    labelId="eventselect"
                    id="eventselect"
                    value={eventInfo}
                    renderValue={(value) => value.title}
                    label="Select Event Item to Modify"
                    color='primary'
                    onChange={(event: any) => setEventInfo(event.target.value)}
                    style={{ marginBottom: "30px", width: "500px", color: text}}
                >
                    {events.map((news : any) => (
                        <MenuItem key={news.id} value={news}>
                            {news.title}
                        </MenuItem>
                    ))}
                </Select>
                <ThemeProvider theme={theme}>
                    <TextField 
                        label='Title'
                        variant='standard'
                        focused
                        value={eventInfo.title}
                        onChange={(event) => setEventInfo({...eventInfo, title: event.target.value})}
                        sx={{input: {color: text}, color: text}}
                        style={{marginBottom: '10px', width: '200px', background: background, zIndex: 4}}
                    />
                    <TextField 
                        label='Photo'
                        variant='standard'
                        focused
                        value={eventInfo.photo}
                        onChange={(event) => setEventInfo({...eventInfo, photo: event.target.value})}
                        sx={{input: {color: text}, color: text}}
                        style={{marginBottom: '10px', width: '200px', background: background, zIndex: 4}}
                    />
                    <TextField 
                        label='Text'
                        variant='standard'
                        focused
                        value={eventInfo.text}
                        multiline
                        onChange={(event) => setEventInfo({...eventInfo, text: event.target.value})}
                        sx={{input: {color: text}, color: text}}
                        style={{marginBottom: '10px', width: '200px', background: background, zIndex: 4}}
                    />

                    <TextField 
                        label='Date'
                        variant='standard'
                        focused
                        value={eventInfo.date}
                        onChange={(event) => setEventInfo({...eventInfo, date: event.target.value})}
                        sx={{input: {color: text}, color: text}}
                        style={{marginBottom: '10px', width: '200px', background: background, zIndex: 4}}
                    />

                    <TextField 
                        label='Location'
                        variant='standard'
                        focused
                        value={eventInfo.location}
                        onChange={(event) => setEventInfo({...eventInfo, location: event.target.value})}
                        sx={{input: {color: text}, color: text}}
                        style={{marginBottom: '10px', width: '200px', background: background, zIndex: 4}}
                    />       

                   <TextField 
                        label='Block'
                        variant='standard'
                        focused
                        value={eventInfo.block}
                        onChange={(event) => setEventInfo({...eventInfo, block: event.target.value})}
                        sx={{input: {color: text}, color: text}}
                        style={{marginBottom: '10px', width: '200px', background: background, zIndex: 4}}
                    />
                    
                </ThemeProvider>
            </Column>
            {incomplete && <CenteredText text={error} style={{paddingLeft: 0, marginTop: '10px'}}>
                    Information is missing and/or the block is incorrect
                </CenteredText>}
            <Button
                    variant='contained'
                    style={{alignSelf: 'center', background: logo, color: background, marginTop: '20px', marginBottom: '200px'}}
                    onClick={modifyEvent}
                >
                    Modify Event
                </Button>
            </ModalContainer>}
         
        </div>
    )
}



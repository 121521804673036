import React, { useEffect, useRef, useState } from "react";
import { debounce } from 'lodash';
// redux
import { useSelectFromRedux, useDispatchPromise } from "../../utils/_hooks";
import { useDispatch } from "react-redux";

// components
import {
    CenteredDiv,
    FullscreenColor, 
    TopLogo,
} from "../site/styled";
import Site from "../site";
import Explore from '../explore';
import Trade from '../trade';
import Newsfeed from '../newsfeed';
import UpcomingEvents from '../upcoming_events';
import MyStable from '../my_stable';
import Account from '../account';
import SignIn from '../sign_in';
import SignUp from '../sign_up';
import Admin from "../admin";
import Policy from '../policy';
import Terms from "../terms";
import { StyledButton, DropDown, TopBar, NameDiv } from "./styled";
import { Button, ButtonGroup, createTheme, IconButton } from "@material-ui/core";
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { ContentActions } from "../../state/content";
import { BasicListText, Column, Row, SpacedCenteredText, VerticallyCenteredRow, FormGroup, FormField, FormLabel, ModalMakeOrderButton, QuickLink } from "../_shared";
import ParticleComp from '../_shared/particles';
import { loadPublicData } from "../../state/publicData/operations";
import NewspaperIcon from '@mui/icons-material/Newspaper';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import HouseSidingIcon from '@mui/icons-material/HouseSiding';
import PersonIcon from '@mui/icons-material/Person';
import GroupsIcon from '@mui/icons-material/Groups';
import ExploreIcon from '@mui/icons-material/Explore';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import KeyIcon from '@mui/icons-material/Key';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import Transaction from "../transaction";
import { serverSignIn } from "../../state/user/operations";
import api from "../../api";
import { Horse, UserAccount } from "../../types";
import MenuIcon from '@mui/icons-material/Menu';
import { ToggleButtonGroup, ToggleButton, ThemeProvider } from "@mui/material";
import { colorActions } from "../../state/color";
import Community from "../community";
import { isBrowser, BrowserView, isMobileOnly, MobileView } from "react-device-detect";
import Footer from './footer';

let first = true;

export default (_props: any) => {
    const dispatch = useDispatch();
    // const dispatchPromise = useDispatchPromise();
    // const errorIsOpen = useSelectFromRedux((state) => state.error.isOpen);

    const Logo = 'https://tctrading.s3.amazonaws.com/logos/LogoS.png';

    const [
        background,
        text,
        content,
        logo,
        user,
        horses,
        colorMode,
        particles
    ] = useSelectFromRedux((state) => [
        state.color.scheme.background,
        state.color.scheme.text,
        state.content.content,
        state.color.scheme.logo,
        state.user.user,
        state.publicData.horses,
        state.color.mode,
        state.color.particles
    ])

    const theme = createTheme({
        palette: {
            primary: {
                main: '#000000',
            },
            secondary: {
                main: '#FFFFFF',
            },
            warning: {
                main: '#203764',
            },
            error: {
                main: '#42464E',
            },
        }
    })

    const setContent = async (c : "home" | "explore" | "trade" | "newsfeed" | "upcoming events" | "my stable" | "account" | "sign in" | 'sign up' | 'admin' | 'privacy policy' | 'terms of service' | 'community') => {
        window.scrollTo(0,0);
        setDropDown(false);
        dispatch(ContentActions.setContent(c));
        await api.post('/public/addActivity', {name: c});
    }

    // if (first) {
    //     dispatch(ContentActions.setContent("home"));
    //     first = false;
    // }
    
    

    useEffect(() => {
        dispatch(loadPublicData())
        if(localStorage.getItem('user')) {
            const user = JSON.parse(localStorage.getItem('user') ?? '');
            dispatch(serverSignIn({email: user.email, password: user.password}))
        }
    }, [])

    useEffect(() => {

    }, [logo])

    const getMyHorses = (user: UserAccount): Horse[] => {
        if(Object.keys(user.ownerships).length === 0) {
            return []
        }
        const myOwnerships = user.ownerships;
        const myOwnershipIDS = myOwnerships.map((ownership) => ownership.horse_id);
        let myHorses: Horse[] = [];
        for (var horse of horses) {
            if(myOwnershipIDS.includes(horse.id)) {
                myHorses.push(horse);            
            }
        }
        return myHorses
    }

    let ftSize: number = 16;

    const myAssets = user ? getMyHorses(user): [];

    const getOwnership = (horse: Horse): any => {
        if(user != null){
            for(let ownership of user.ownerships){
                if(ownership.horse_id == horse.id) {
                    return ownership
                }
            }
        }
        return user?.ownerships[0];
    }

    const getHorse = (horseID: number) : Horse | undefined => {
        if(myAssets) {
            for(let horse of myAssets){
                if(horse.id == horseID){
                    return horse
                }
            }
        }
        return undefined
    }

    const getTotalInvestmentValue = (): number => {
        let amount: number = 0;
        if(user != null && Object.keys(user.ownerships).length > 0){
            for(let horseOwnership of user.ownerships){
                amount += (getTotalShares(getHorse(horseOwnership.horse_id)) ?? 0) * (getHorse(horseOwnership.horse_id)?.price ?? 0)
            }
        }
        return amount;
    }

    const getTotalShares = (horse: Horse | undefined): number | undefined => {
        if (!horse) {
            return undefined
        }
        let shareCount: number = 0;
        if(user){
        for(let transaction of getOwnership(horse).transactions){
            shareCount += transaction.shares;
        }}
        return shareCount;
    }

    const [windowSize, setWindowSize] = useState({
        windowWidth: window.outerWidth,
        windowHeight: window.outerHeight,
      });
    const [resized, setResized] = useState<Boolean>(window.outerWidth < 990)
    
      useEffect(() => {
        const handleResize = debounce(() => {
          setWindowSize({
            windowWidth: window.outerWidth,
            windowHeight: window.outerHeight,
          });
          setResized(window.outerWidth < 990);

        }, 250); // Adjust the debounce time (in milliseconds) as needed
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          handleResize.cancel(); // Cancel any pending debounced calls
          window.removeEventListener('resize', handleResize);
        };
      }, []);


    const [dropDown, setDropDown] = React.useState<Boolean>(false)

    useEffect(()=>{

    }, [dropDown])

    const [scrollPosition, setScrollPosition] = useState(0);
    // const [topBarBackground, setTopBarBackground] = useState('transparent');

    const topBar = document.getElementById('topbar');

    const handleScroll = () => {
    if (topBar) {
        const scrollPosition = window.scrollY;
        if (scrollPosition > 0) {
            
            topBar.style.borderBottomColor = logo;
        } else {
            
            topBar.style.borderColor = 'transparent';
        }
    }
    };

    window.addEventListener('scroll', handleScroll);
      
      
      

    return (
        <FullscreenColor background={background}>
            <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1094097634004455"
                    crossOrigin="anonymous"></script>
            <TopBar id = 'topbar' style = {{ zIndex: 10, borderWidth: '1px', transitionDuration: '1500ms', display: 'flex', background: background}}>
                {window.outerWidth < 1090 ? 
                 <Column style={{ alignItems: 'center', width: '100%',  }}>
                 <Row style={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                   <div style={{ display: 'flex', alignItems: 'center', marginLeft: '-24px' }}>
                     <TopLogo
                       src={Logo}
                       alt="Logo"
                       onClick={() => setContent('home')}
                       style={{ userSelect: 'none', height: '32px', marginRight: '7px'}}
                     />
                     <NameDiv style={{ height: '27px', width: '251px', color: text, fontFamily: 'Cinzel'}}>
                       TRIPLE CROWN TRADING
                     </NameDiv>
                   </div>
                   <IconButton
                     style={{ color: text, width: '30px', height: '30px',marginRight: '35px' }}
                     onClick={() => setDropDown(!dropDown)}
                   >
                     <MenuIcon style={{ width: '30px', }} />
                   </IconButton>
                 </Row>
                    {dropDown &&
                    <>
                    <DropDown style={{
                        background: background, borderBottomColor : logo, borderWidth: '1px', borderBottom: logo, alignItems: 'start', paddingTop: '24px', marginLeft: '-24px'}}>
                            <StyledButton 
                                onClick={() => setContent('explore')} 
                                color='inherit' style={{color: content == 'explore' ? logo : text, borderColor: background, transitionDuration: '500ms', fontFamily: 'IBM Plex Mono', fontWeight: 400}}
                                
                            >Offerings</StyledButton>
                            <StyledButton 
                                onClick={() => setContent('trade')}
                                color='inherit' style={{color: content == 'trade' ? logo : text, borderColor: background, transitionDuration: '500ms',fontFamily: 'IBM Plex Mono', fontWeight: 400}}
                                
                            >Trade</StyledButton>
                            <StyledButton 
                                onClick={() => setContent('newsfeed')}
                                color='inherit' style={{color: content == 'newsfeed' ? logo : text, borderColor: background, transitionDuration: '500ms',fontFamily: 'IBM Plex Mono', fontWeight: 400}}
                                
                            >Newsfeed</StyledButton>
                            
                            <StyledButton 
                                onClick={() => setContent('community')}
                                color='inherit' style={{color: content == 'community' ? logo : text, borderColor: background, transitionDuration: '500ms',fontFamily: 'IBM Plex Mono', fontWeight: 400}}
                                
                            >Community</StyledButton>
                        {/* </ButtonGroup> */}
                        {user &&
                        <>
                        {/* <ButtonGroup variant='text' aria-label ='outlined button group' color='inherit' style={{justifyContent: 'start', alignItems:'center', color: text, paddingRight: '20px', transitionDuration: '500ms'}}>  */}
                            <StyledButton 
                                onClick={() => setContent('my stable')}
                                color='inherit' style={{color: content == 'my stable' ? logo : text, borderColor: background, transitionDuration: '500ms',fontFamily: 'IBM Plex Mono', fontWeight: 400}}
                                
                            >My Stable</StyledButton>
                            <StyledButton 
                                onClick={() => setContent('account')}
                                color='inherit' style={{color: content == 'account' ? logo : text, borderColor: background, transitionDuration: '500ms',fontFamily: 'IBM Plex Mono', fontWeight: 400}}
                                
                            >Account</StyledButton>
                            {user.admin &&
                            <StyledButton 
                                onClick={() => setContent('admin')}
                                color='inherit' style={{color: content == 'admin' ? logo : text, borderColor: background, transitionDuration: '500ms',fontFamily: 'IBM Plex Mono', fontWeight: 400}}
                                
                            >Admin</StyledButton>
                            }
                        {/* </ButtonGroup> */}
                        {/* <VerticallyCenteredRow style={{ height:'90%', marginTop: '5px', color: text, justifyContent:'end', zIndex: 'inherit', gap: '10px', flexGrow: 2}}>
                            <Column style={{alignItems: 'flex-end', justifyContent: 'flex-start', zIndex: 'inherit', minWidth: '159px'}}>
                                <BasicListText style={{marginTop: '2px', marginBottom: '2px', transitionDuration: '500ms'}}>Net Investment Value:</BasicListText>
                                <BasicListText style={{marginTop: '2px', marginBottom: '2px', transitionDuration: '500ms'}}>Currency:</BasicListText>
                                <BasicListText style={{marginTop: '2px', marginBottom: '2px', transitionDuration: '500ms'}}>On Order:</BasicListText>
                            </Column >
                            <Column style={{alignItems: 'flex-start', justifyContent: 'flex-start', zIndex: 'inherit'}}>
                                <BasicListText style={{marginTop: '2px', marginBottom: '2px', transitionDuration: '500ms'}}>${(Math.round(getTotalInvestmentValue() * 100)/100).toLocaleString(undefined, {minimumFractionDigits: 2})}</BasicListText>
                                <BasicListText style={{marginTop: '2px', marginBottom: '2px', transitionDuration: '500ms'}}>${(+user.tokens).toFixed(3)}</BasicListText>
                                <BasicListText style={{marginTop: '2px', marginBottom: '2px', transitionDuration: '500ms'}}>$1,234.56</BasicListText>
                            </Column>
                        </VerticallyCenteredRow> */}
                    </>}
                    {!user && 
                    // <ButtonGroup variant='text' aria-label ='outlined button group' color='inherit' style={{color: text, paddingRight: '20px', marginLeft: '0%', alignItems: 'center', borderColor: background, transitionDuration: '500ms'}}>
                        <><StyledButton 
                            onClick={() => setContent('sign up')}
                            color='inherit' style={{color: content == 'sign up' ? logo : text, borderColor: background, transitionDuration: '500ms',fontFamily: 'IBM Plex Mono', fontWeight: 400}}
                           
                        >Sign Up</StyledButton>
                        <StyledButton 
                            onClick={() => setContent('sign in')}
                            color='inherit' style={{color: content == 'sign in' ? logo : text, borderColor: background, transitionDuration: '500ms',fontFamily: 'IBM Plex Mono', fontWeight: 400}}
                           
                        >Sign In</StyledButton>
                        </>
                    // </ButtonGroup>
                    }
                    </DropDown>
                    </>
                    }
                </Column>
                 :  <>
                <Row style={{width:'98%', justifyContent: 'space-between', alignItems: 'center'}}>
                    <div style ={{display:'flex', alignItems:'center', marginLeft: '-30px'}}>
                    <TopLogo
                        src={Logo}
                        alt="Logo"
                        onClick={() => setContent('home')}
                        style={{userSelect: 'none', height: '32px', width: '53px', marginRight: '7px'}}
                    /> 
                    <NameDiv style={{height: '27px', width: '251px', color: text}}>
                        TRIPLE CROWN TRADING</NameDiv>
                    </div>
                    <ButtonGroup  style={{ display:'flex',alignItems:'right', borderColor: background, gap: '27.5px', transitionDuration: '500ms'}}>
                        <StyledButton 
                            onClick={() => setContent('explore')} 
                            color='inherit' style={{color: content == 'explore' ? logo : text, borderColor: background, transitionDuration: '500ms',fontFamily: 'IBM Plex Mono', fontWeight: 400}}
                            // startIcon={<ExploreIcon
                            //     color='inherit'
                            //     style={{color: logo}}
                            //     sx={{fontSize: '120px'}}
                            // />}
                        >Offerings</StyledButton>
                        <StyledButton 
                            onClick={() => setContent('trade')}
                            color='inherit' style={{color: content == 'trade' ? logo : text, borderColor: background, transitionDuration: '500ms',fontFamily: 'IBM Plex Mono', fontWeight: 400}}
                            // startIcon={<StackedLineChartIcon
                            //     color='inherit'
                            //     style={{color: logo}}
                            //     sx={{fontSize: '120px'}}
                            // />}
                        >Trade</StyledButton>
                        <StyledButton 
                            onClick={() => setContent('newsfeed')}
                            color='inherit' style={{color: content == 'newsfeed' ? logo : text, borderColor: background, transitionDuration: '500ms',fontFamily: 'IBM Plex Mono', fontWeight: 400}}
                            // startIcon={<NewspaperIcon
                            //     color='inherit'
                            //     style={{color: logo}}
                            //     sx={{fontSize: '120px'}}
                            // />}
                        >Newsfeed</StyledButton>
                        {/* <StyledButton 
                            onClick={() => setContent('upcoming events')}
                            color='inherit' style={{minWidth: '184px', color: content == 'upcoming events' ? logo : text, borderColor: background, transitionDuration: '500ms', }}
                            startIcon={<EmojiEventsIcon
                                color='inherit'
                                style={{color: logo}}
                                sx={{fontSize: '120px'}}
                            />}
                        >Upcoming Events</StyledButton> */}
                        <StyledButton 
                            onClick={() => setContent('community')}
                            color='inherit' style={{color: content == 'community' ? logo : text, borderColor: background, transitionDuration: '500ms',fontFamily: 'IBM Plex Mono', fontWeight: 400}}
                            // startIcon={<GroupsIcon
                            //     color='inherit'
                            //     style={{color: logo}}
                            //     sx={{fontSize: '120px'}}
                            // />}
                        >Community</StyledButton>
                    
                    {user &&
                    <ButtonGroup style={{ display:'flex',alignItems:'flex-start', gap:'27.5px', borderColor: background, transitionDuration: '500ms', justifyContent: 'flex-end'}}> 
                        <StyledButton 
                            onClick={() => setContent('my stable')}
                            color='inherit' style={{color: content == 'my stable' ? logo : text, borderColor: background, transitionDuration: '500ms', minWidth: '113px',fontFamily: 'IBM Plex Mono', fontWeight: 400}}
                            
                        >My Stable</StyledButton>
                        <StyledButton 
                            onClick={() => setContent('account')}
                            color='inherit' style={{color: content == 'account' ? logo : text, borderColor: background, transitionDuration: '500ms',fontFamily: 'IBM Plex Mono', fontWeight: 400}}
                          
                        >Account</StyledButton>
                        
                    </ButtonGroup>
                    
                    }
                    {!user && 
                    <ButtonGroup style={{ display:'flex',alignItems:'flex-start', borderColor: background, gap:'27.5px', transitionDuration: '500ms', }}>
                        <StyledButton 
                            onClick={() => setContent('sign up')}
                            color='inherit' style={{color: content == 'sign up' ? logo : text, borderColor: background, transitionDuration: '500ms', minWidth:'92px',fontFamily: 'IBM Plex Mono', fontWeight: 400}}
                            
                        >Sign Up</StyledButton>
                        <StyledButton 
                            onClick={() => setContent('sign in')}
                            color='inherit' style={{color: content == 'sign in' ? logo : text, borderColor: background, transitionDuration: '500ms', minWidth: '92px',fontFamily: 'IBM Plex Mono', fontWeight: 400}}
                            
                        >Sign In</StyledButton>
                    </ButtonGroup>
                    
                    }
                    </ButtonGroup>
                </Row>
                </>}
            </TopBar>
            {content == 'home' && <ParticleComp/>}
            {content == 'home' && <Site/>}
            {content == 'explore' && <Explore />}
            {content == 'trade' && <Trade/>}
            {content == 'newsfeed' && <Newsfeed/>}
            {/* {content == 'upcoming events' && <UpcomingEvents/>} */}
            {content == 'my stable' && <MyStable/>}
            {content == 'account' && <Account/>}
            {content == 'sign in' && <SignIn/>}
            {content == 'sign up' && <SignUp/>}
            {content == 'admin' && user?.admin && <Admin/>}
            {content == 'admin' && !user?.admin && <Site/>}
            {content == 'privacy policy' && <Policy/>}
            {content == 'terms of service' && <Terms/>}
            {content == 'community' && <Community/>}
            <Footer/>
        </FullscreenColor>
    );
};

import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useSelectFromRedux } from "../../utils/_hooks";
import { FilterContainer, MarketFilterContainer, ModalHeader, Row, Column, CenteredText, SpacedCenteredText, ModalContainer, LineDivider, VerticallyCenteredRow } from ".";
import { Button, Slider, Box, FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { Horse, NewsItem, RaceEvent, Ownership, UserAccount } from "../../types";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { debounce, inRange } from "lodash";
import { isMobileOnly } from "react-device-detect";
// import { Calendar, Whisper, Popover, Badge } from 'rsuite';
import api from "../../api";
import moment from "moment";
// import '../css/calendar.css';
// import 'rsuite/dist/rsuite-no-reset.min.css';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';




export default (props: { setModal: Dispatch<SetStateAction<RaceEvent | NewsItem | null>>, calendarOn: Boolean}) => {

    const setModal = props.setModal;

    const [
        logo, 
        textColor, 
        background,
        myAssetData,
        horses,
        user,
        colorMode,
        debugState
    ] = useSelectFromRedux((state) => [
        state.color.scheme.logo, 
        state.color.scheme.text,
        state.color.scheme.background,
        state.user.myAssetData,
        state.publicData.horses,
        state.user.user,
        state.color.mode,
        state
    ]);

    // const [fullArticle, showFullArticle] = React.useState<Boolean>(false);
    const [windowSize, setWindowSize] = useState({
        windowWidth: window.outerWidth,
        windowHeight: window.outerHeight,
      });
    const [resized, setResized] = useState<Boolean>(window.outerWidth < 990)
    
    useEffect(() => {
    const handleResize = debounce(() => {
        setWindowSize({
        windowWidth: window.outerWidth,
        windowHeight: window.outerHeight,
        });
        setResized(window.outerWidth < 990);

    }, 250); // Adjust the debounce time (in milliseconds) as needed

    window.addEventListener('resize', handleResize);

    return () => {
        handleResize.cancel(); // Cancel any pending debounced calls
        window.removeEventListener('resize', handleResize);
    };
    }, []);

    const [data, setData] = useState<RaceEvent[]>([]);

    const getMyHorses = (user: UserAccount): Horse[] => {
        if(Object.keys(user.ownerships).length === 0) {
            return []
        }
        const myOwnerships = user.ownerships;
        const myOwnershipIDS = myOwnerships.map((ownership) => ownership.horse_id);
        let myHorses: Horse[] = [];
        for (var horse of horses) {
            if(myOwnershipIDS.includes(horse.id)) {
                myHorses.push(horse);            
            }
        }
        console.log("myhorses" + myHorses)
        return myHorses
    }

    let myHorses: Horse[] = []

    useEffect(() => {
        const fetchData = async () => {
            const response = await api.get('/public/getEvents');
            console.log(response.data)
            setData(response.data);
        };

        fetchData().catch(console.error);


    }, [])

    useEffect(() => {

        myHorses = user ? getMyHorses(user) || [] : []
    }, [user])
    // events have string date
    // corresponds to day parameter

    const compareDateStrings = (date1: string, date2: string) : Boolean => {
        let temp1 : string[] = date1.split('/');
        let temp2 : string[] = date2.split('/');
        return ((Number(temp1[0])==Number(temp2[0])) && (Number(temp1[1])==Number(temp2[1])) && (Number(temp1[2])==Number(temp2[2])))
    }

    const horsesFilter = (event: RaceEvent) : Boolean => {
        if (filterHorses){
            for (let horse of horses) {
                if(event.horses.includes(horse.id)) return true
            }
            return false
        }
        else return true
        
    }

    const getDayEvents = (day: Date) : RaceEvent[] => {
        let dayString = moment(day).format('MM/DD/YYYY');
        let filteredEvents = data.filter((event: RaceEvent) => compareDateStrings(dayString, event.date))
        filteredEvents = filteredEvents.filter((event: RaceEvent) => horsesFilter(event))
        return filteredEvents
    }


    const [currentMonth, setCurrentMonth] = useState(moment());

    const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const startDay = currentMonth.clone().startOf('month').day();

    const daysInMonth = Array.from({ length: currentMonth.daysInMonth() }, (v, k) => k + 1);
    const daysInPrevMonth = Array.from({ length: startDay }, (v, k) => null);

    const changeMonth = (num: any) => {
        setCurrentMonth(currentMonth.clone().add(num, 'month'));
    }

    const [filterHorses, setFilterHorses] = useState<Boolean>(true)



    return (
            <Column style={{borderColor: logo, borderWidth: '5px', borderStyle: 'solid', width: '1000px',height:'fit-content',zIndex:4, background: background, borderRadius:'10px', alignItems:'center'}} >
                  <VerticallyCenteredRow style={{justifyContent: 'space-evenly', width:'50%',fontSize:'26px', color: logo, marginBottom:'10px', padding:'10px'}}>
                      <Button onClick={() => changeMonth(-1)}>{currentMonth.clone().add(-1, 'month').format('MMMM')}</Button>
                      {currentMonth.format('MMMM YYYY')}
                      <Button onClick={() => changeMonth(1)}>{currentMonth.clone().add(1, 'month').format('MMMM')}</Button>
                  </VerticallyCenteredRow>
                  <VerticallyCenteredRow style={{justifyContent: 'space-evenly', width:'50%',fontSize:'26px', color: logo, marginBottom:'10px', padding:'10px'}}>
                      <Button variant="contained" color='primary' onClick={() => setFilterHorses(true)} disabled={filterHorses==true}>My Horses</Button>
                      <Button variant="contained" color='primary' onClick={() => setFilterHorses(false)} disabled={filterHorses==false}>All Horses</Button>
                  </VerticallyCenteredRow>
                  <div style={{color: logo, width: '95%', justifyContent:'space-evenly', display: 'grid', gridTemplateColumns: 'repeat(7, 1fr)',gap: '15px', }}>
                      {daysOfWeek.map((day, index) => (
                          <div key={index} style={{textAlign:'center',borderBottomStyle:'solid', borderBottomWidth:'2px', borderBottomColor: textColor, fontSize:'24px'}}>{day}</div>
                      ))}
                  </div>
                  <div style={{color: logo, width:'95%', justifyContent:'space-evenly', display: 'grid', gridTemplateColumns: 'repeat(7, 1fr)',gap: '15px',marginTop:'10px', marginBottom:'10px'}}>
                      {daysInPrevMonth.map((day, index) => (
                          <div key={`prev_${index}`} style={{width:'75px', height: '75px'}}></div>
                      ))}
                      {daysInMonth.map((day, index) => {
                        const date = currentMonth.clone().startOf('month').add(index, 'days');
                        const events = getDayEvents(date.toDate());
                        return (
                          <div key={index} style={{width:'100%', height: '75px', textAlign:'center', alignSelf:'center', paddingTop:'5px', borderWidth:'2px', borderStyle:'solid', borderRadius:'3px'}}>
                            {day}
                            {events.map((event, eventIndex) => (
                                <VerticallyCenteredRow style={{justifyContent:'center', gap:'5px'}}>
                                  <EmojiEventsIcon style={{color: logo}}/>
                                  <div key={eventIndex} style={{color:textColor, cursor:'pointer', fontSize:'12px'}} onClick={()=> setModal(event)}>{event.title}</div> 
                                </VerticallyCenteredRow>
                            ))}
                          </div>
                        );
                      })}
                  </div>
            </Column>
    )
}
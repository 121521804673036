import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
    Chart,
    ArgumentAxis,
    ValueAxis,
    LineSeries,
    Title,
    Legend,
    PieSeries
} from "@devexpress/dx-react-chart-material-ui";
import Paper from "@mui/material/Paper";
import { Animation, EventTracker, HoverState } from "@devexpress/dx-react-chart";
import { Horse } from "../../types";
import { useSelectFromRedux } from "../../utils/_hooks";
import {
    Button,
    ButtonGroup,
    createTheme,
    FormControl,
    InputBase,
    InputLabel,
    MenuItem,
    Select,
    styled,
    TextField,
    ThemeProvider
} from "@mui/material";

import { Column, Row, CenteredText, CenteredText2, ModalMakeOrderButton, AnimatedSwaphori } from "../_shared";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import { makeOrderServer, userUpdate } from "../../state/user/operations";
import { UserActions } from "../../state/user";

export default (props: {horse: Horse, width: number}) => {
    const dispatch = useDispatch();

    const horse = props.horse;
    const width = props.width;

    const [text, background, logo, horses, user, transactionResponse] = useSelectFromRedux((state) => [
        state.color.scheme.text,
        state.color.scheme.background,
        state.color.scheme.logo,
        state.publicData.horses,
        state.user.user,
        state.user.transactionResponse
    ]);

    interface CostBreakDown {
        name: string;
        percent: number;
        point: number;
    }

    const theme = createTheme({
        palette: {
            primary: {
                main: "#E5C078"
            }
        }
    });

    const [type1, setType1] = useState<"horse" | "tokens">("tokens");
    const [type2, setType2] = useState<"horse" | "tokens">("horse");
    const [id1, setid1] = useState<number>(-1);
    const [id2, setid2] = useState<number>(horse.id);
    const [num1, setnum1] = useState<number>();
    const [num2, setnum2] = useState<number>();
    const [price1, setprice1] = useState<number>(1);
    const [price2, setprice2] = useState<number>(horse.price);

    const setHorse1 = (id: number) => {
        const horse = horses.find((horse) => horse.id == id);
        setid1(id);
        setprice1(horse?.price ?? 0);
    };
    const setHorse2 = (id: number) => {
        const horse = horses.find((horse) => horse.id == id);
        setid2(id);
        setprice2(horse?.price ?? 0);
        setnum2(((num1 ?? 0) * (price1 ?? 0)) / (horse?.price ?? 1));
    };

    const BootstrapInput = styled(InputBase)(({ theme }) => ({
        "label + &": {
            marginTop: theme.spacing(3)
        },
        "& .MuiInputBase-input": {
            borderRadius: 4,
            position: "relative",
            backgroundColor: background,
            border: "1px solid #ced4da",
            fontSize: 16,
            padding: "10px 26px 10px 12px",
            color: logo,
            // Use the system font instead of the default Roboto font.
            fontFamily: [
                "-apple-system",
                "BlinkMacSystemFont",
                '"Segoe UI"',
                "Roboto",
                '"Helvetica Neue"',
                "Allerta",
                "sans-serif",
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"'
            ].join(","),
            "&:focus": {
                borderRadius: 4,
                borderColor: logo,
                boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
            }
        }
    }));

    useEffect(() => {
        dispatch(UserActions.setTransactionResponse(null));
    }, [num1, price1, price2]);

    const submit = () => {
        if (num1 && num2 && price1 && price2) {
            dispatch(
                makeOrderServer({
                    type: type1 == "horse" ? "sell" : "buy",
                    order: {
                        user_id: user?.id,
                        horse_id: type1 == "horse" ? id1 : id2,
                        shares: type1 == "horse" ? num1 : num2,
                        price: type1 == "horse" ? (num2 * price2) / num1 : (num1 * price1) / num2,
                        buy_req:
                            type1 == "horse" && type2 == "horse"
                                ? {
                                      user_id: user?.id,
                                      horse_id: id2,
                                      shares: num2,
                                      price: price2
                                  }
                                : undefined
                    }
                })
            );
            setnum1(0);
            setnum2(0);
        }
    };

    const [error, setError] = useState<string>();

    const myOwnershipIDs =
        (user?.ownerships.length ?? 0) > 0 ? user?.ownerships.map((ownership) => ownership.horse_id) : [];

    useEffect(() => {
        if (type1 == "tokens" && (num1 ?? 0) > (user?.tokens ?? 0)) {
            setError("Not enough tokens for this transaction.");
        } else if (type1 == "horse" && id1 < 1) {
            setError("Please select a horse to sell.");
        } else if (type2 == "horse" && id2 < 1) {
            setError("Please select a horse to buy.");
        } else if (
            type1 == "horse" &&
            Object.keys(user?.ownerships ?? []).length > 0 &&
            (num1 ?? 0) > (user?.ownerships.find((ownership) => ownership.horse_id == id1)?.totalShares ?? -1)
        ) {
            setError("Not enough shares for this transaction");
        } else {
            setError(undefined);
        }
    }, [type1, num1, user, id1, id2]);

    return (
        <Column style={{alignItems:'center'}}>
            <Column style={{ width: "90%", justifyContent: "center", alignItems: "center", flexDirection: width>790? 'row':'column'}}>
                <Column style={{  justifyContent: "center", alignItems: "center"}}>
                    <CenteredText2 style={{ fontSize: "20px", marginBottom: "20px" }} text={logo}>
                        Sell
                    </CenteredText2>
                    <ThemeProvider theme={theme}>
                        <FormControl fullWidth>
                            {/* <InputLabel id="type1-select">Type</InputLabel> */}
                            <Select
                                labelId="type1-select"
                                id="type1--select"
                                value={type1}
                                label="Type"
                                onChange={(event) => {
                                    setType1(event.target.value as "horse" | "tokens");
                                    if (event.target.value == "tokens") {
                                        setType2("horse");
                                        setHorse2(horses[0].id);
                                        setprice1(1);
                                        setid1(-1);
                                    }
                                }}
                                input={<BootstrapInput />}
                                style={{ maxWidth: "100%", marginBottom: "5px" }}
                            >
                                {Object.keys(user?.ownerships ?? []).length > 0 && (
                                    <MenuItem value={"horse"}>Horse</MenuItem>
                                )}
                                <MenuItem value={"tokens"}>Tokens</MenuItem>
                            </Select>
                        </FormControl>
                        {type1 == "horse" && (
                            <FormControl fullWidth>
                                {/* <InputLabel id="type1-select">Type</InputLabel> */}
                                <Select
                                    labelId="horse1-select"
                                    id="horse1--select"
                                    value={id1}
                                    label="Horse"
                                    onChange={(event) => {
                                        setHorse1(event.target.value as number);
                                        if (id2 == event.target.value) {
                                            setid2(-1);
                                        } else {
                                            const horse = horses.find((horse) => horse.id == event.target.value);
                                            const price1 = horse?.price;
                                            setnum2(((num1 ?? 0) * (price1 ?? 0)) / price2);
                                        }
                                    }}
                                    input={<BootstrapInput />}
                                    style={{
                                        marginBottom: type1 == "horse" && id1 == -1 ? "30px" : "5px",
                                        width: "100%",
                                        marginTop: "25px"
                                    }}
                                >
                                    {horses
                                        .filter((horse) => myOwnershipIDs?.includes(horse.id))
                                        .map((horse) => (
                                            <MenuItem key={horse.id} value={horse.id}>
                                                {horse.name}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        )}
                        {!(type1 == "horse" && id1 == -1) && (
                            <div style={{ color: text, marginBottom: "25px" }}>
                                My {type1 == "horse" ? "shares" : "tokens"}:{" "}
                                {(+(type1 == "horse"
                                    ? user?.ownerships.find((ownership) => ownership.horse_id == id1)?.totalShares ?? -1
                                    : user?.tokens ?? 0)).toFixed(3)}
                            </div>
                        )}
                        <TextField
                            id="standard-basic"
                            label={type1 == "horse" ? "shares" : "tokens"}
                            // variant='standard'
                            color="primary"
                            placeholder="0"
                            focused
                            value={num1}
                            type="number"
                            onChange={(event) => {
                                setnum1(parseInt(event.target.value));
                                setnum2((parseInt(event.target.value) * price1) / price2);
                            }}
                            sx={{ input: { color: text } }}
                            style={{ marginBottom: "5px", maxWidth: "100%" }}
                        />
                        <ButtonGroup
                            variant="text"
                            aria-label="outlined button group"
                            color="inherit"
                            style={{ color: text, paddingLeft: "5%", height: "20px", marginBottom: "20px" }}
                        >
                            <Button
                                onClick={() => {
                                    if (type1 == "tokens") {
                                        setnum1(user?.tokens ?? 0);
                                        setnum2(((user?.tokens ?? 0) * price1) / price2);
                                    } else if (type1 == "horse") {
                                        setnum1(
                                            user?.ownerships.find((ownership) => ownership.horse_id == id1)
                                                ?.totalShares ?? -1
                                        );
                                        setnum2(
                                            ((user?.ownerships.find((ownership) => ownership.horse_id == id1)
                                                ?.totalShares ?? -1) *
                                                price1) /
                                                price2
                                        );
                                    }
                                }}
                                color="inherit"
                                style={{ color: text }}
                            >
                                MAX
                            </Button>
                            <Button
                                onClick={() => {
                                    if (type1 == "tokens") {
                                        setnum1((1 / 2) * (user?.tokens ?? 0));
                                        setnum2(((1 / 2) * (user?.tokens ?? 0) * price1) / price2);
                                    } else if (type1 == "horse") {
                                        setnum1(
                                            (1 / 2) *
                                                (user?.ownerships.find((ownership) => ownership.horse_id == id1)
                                                    ?.totalShares ?? 0)
                                        );
                                        setnum2(
                                            ((1 / 2) *
                                                (user?.ownerships.find((ownership) => ownership.horse_id == id1)
                                                    ?.totalShares ?? -1) *
                                                price1) /
                                                price2
                                        );
                                    }
                                }}
                                color="inherit"
                                style={{ color: text }}
                            >
                                1/2
                            </Button>
                            <Button
                                onClick={() => {
                                    if (type1 == "tokens") {
                                        setnum1((1 / 4) * (user?.tokens ?? 0));
                                        setnum2(((1 / 4) * (user?.tokens ?? 0) * price1) / price2);
                                    } else if (type1 == "horse") {
                                        setnum1(
                                            (1 / 4) *
                                                (user?.ownerships.find((ownership) => ownership.horse_id == id1)
                                                    ?.totalShares ?? 0)
                                        );
                                        setnum2(
                                            ((1 / 4) *
                                                (user?.ownerships.find((ownership) => ownership.horse_id == id1)
                                                    ?.totalShares ?? -1) *
                                                price1) /
                                                price2
                                        );
                                    }
                                }}
                                color="inherit"
                            >
                                1/4
                            </Button>
                        </ButtonGroup>
                        <div style={{ color: text }}>Value: ${((num1 ?? 0) * (price1 ?? 0)).toPrecision(6)}</div>
                    </ThemeProvider>
                </Column>
                <AnimatedSwaphori
                    color="inherit"
                    style={{ color: logo}}
                    sx={{ fontSize: "7em", alignSelf: "center"}}
                />
                <Column>
                    <CenteredText style={{ fontSize: "20px", marginBottom: "20px" }} text={logo}>
                        Buy
                    </CenteredText>
                    <ThemeProvider theme={theme}>
                        <FormControl fullWidth>
                            {/* <InputLabel id="type1-select">Type</InputLabel> */}
                            <Select
                                labelId="type2-select"
                                id="type2--select"
                                value={type2}
                                label="Type"
                                onChange={(event) => {
                                    setType2(event.target.value as "horse" | "tokens");
                                    if (event.target.value == "tokens") {
                                        setType1("horse");
                                        setHorse1(horses[0].id);
                                        setprice2(1);
                                    } else {
                                        setHorse2(horses[0].id);
                                    }
                                }}
                                input={<BootstrapInput />}
                                style={{ width: "100%", marginBottom: "30px" }}
                            >
                                <MenuItem value={"horse"}>Horse</MenuItem>
                                {Object.keys(user?.ownerships ?? []).length > 0 && (
                                    <MenuItem value={"tokens"}>Tokens</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                        {type2 == "horse" && (
                            <FormControl fullWidth>
                                {/* <InputLabel id="type1-select">Type</InputLabel> */}
                                <Select
                                    labelId="horse2-select"
                                    id="horse2--select"
                                    value={id2}
                                    label="Horse"
                                    onChange={(event) => setHorse2(event.target.value as number)}
                                    input={<BootstrapInput />}
                                    style={{ marginBottom: "30px", width: "100%" }}
                                >
                                    {horses
                                        .filter((horse) => horse.id != id1)
                                        .map((horse) => (
                                            <MenuItem key={horse.id} value={horse.id}>
                                                {horse.name}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        )}
                        <TextField
                            id="standard-basic"
                            label={type2 == "horse" ? "shares" : "tokens"}
                            // variant='standard'
                            color="primary"
                            focused
                            value={num2}
                            type="number"
                            onChange={(event) => {
                                setnum1((parseInt(event.target.value) * price2) / price1);
                                setnum2(parseInt(event.target.value));
                            }}
                            sx={{ input: { color: text } }}
                            style={{ marginBottom: "30px", width: "100%" }}
                            placeholder="0"
                        />
                        <div style={{ color: text , textAlign:'center'}}>Value: ${((num2 ?? 0) * (price2 ?? 0)).toPrecision(7)}</div>
                    </ThemeProvider>
                </Column>
            </Column>
            <CenteredText2 style={{ fontSize: "20px" }} text={text}>
                {transactionResponse}
            </CenteredText2>
            <CenteredText style={{ fontSize: "20px", marginBottom: "20px", marginTop: "20px" }} text={"red"}>
                {error}
            </CenteredText>
            <ModalMakeOrderButton
                    style={{ alignSelf: "center", marginBottom: "10px" }}
                    onClick={submit}
                    disabled={!user ? true : error ? true : false}
                >
                    Make Order
            </ModalMakeOrderButton>

            <CenteredText2 style={{ fontSize: "10px", marginBottom: "20px", marginTop: "5px" }} text={text}>
                Orders with equal value will be completed immediately.
            </CenteredText2>
        </Column>
    );

};

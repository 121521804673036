import React, { useState } from "react";
// redux
import { useSelectFromRedux } from "../../utils/_hooks";
import { useDispatch } from "react-redux";

// components
import { Button, createTheme } from "@material-ui/core";
import { ContentActions } from "../../state/content";
import { SpacedCenteredText, FormGroup, FormField, FormLabel, ModalMakeOrderButton, QuickLink, FooterLayoutBrowser, FooterLayoutTablet, FooterLayoutMobile, VerticallyCenteredRow } from "../_shared";
import api from "../../api";
import { ToggleButton, ThemeProvider } from "@mui/material";
import { colorActions } from "../../state/color";
import { BrowserView, TabletView, MobileView } from "react-device-detect";



export default (_props: any) => {

    const [
        logo
    ] = useSelectFromRedux((state) => [
        state.color.scheme.logo
    ])

    const [dropDown, setDropDown] = React.useState<Boolean>(false)

    const [name, setName] = useState<string>();
    const [email, setEmail] = useState<string>();
    const [enrolled, setEnrolled] = useState<Boolean>(false);
    const [bademail, setbademail] = useState<Boolean>(false);

    const expressInterest = async () => {
        setbademail(false);
        const response = await api.post("/public/expressInterest", { name: name, email: email });
        if (response.data == "success") setEnrolled(true);
        else setbademail(true);
    };

    const Logo = 'https://tctrading.s3.amazonaws.com/logos/LogoL.png';
    const Verticallogo = "https://tctrading.s3.amazonaws.com/utils/logo_text_blue.png";

    const dispatch = useDispatch();

    const theme = createTheme({
        palette: {
            primary: {
                main: '#000000',
            },
            secondary: {
                main: '#FFFFFF',
            },
            warning: {
                main: '#203764',
            },
            error: {
                main: '#42464E',
            },
        }
    })

    const setContent = async (c : "home" | "explore" | "trade" | "newsfeed" | "upcoming events" | "my stable" | "account" | "sign in" | 'sign up' | 'admin' | 'privacy policy' | 'terms of service' | 'community') => {
        window.scrollTo(0,0);
        setDropDown(false);
        dispatch(ContentActions.setContent(c));
        await api.post('/public/addActivity', {name: c});
    }


    return (
        <>
            <BrowserView style={{ zIndex: 3, position: 'relative' }}>
                <div style={{ backgroundColor: '#F2F2F2', padding: '4em 0 2em 0' }}>
                    <FooterLayoutBrowser>
                        <div style={{ textAlign: 'center', justifyContent: 'center'}}>
                            <img src={Logo} alt="mainlogo" style={{ width: "40%" }} /> 
                            <div style={{ fontFamily: 'Cinzel', fontSize: '2em' }}>
                                TRIPLE CROWN TRADING
                            </div>
                        </div>
                        <div>
                            <div style={{ fontFamily: 'IBM Plex Mono', height: '3em'}}>
                                <Button style={{ fontFamily: 'IBM Plex Mono', height: '50%', fontSize: '15px'}} onClick={() => setContent('explore')} >
                                    <QuickLink>
                                        Dashboard
                                    </QuickLink>
                                </Button>
                            </div>
                            <div style={{ fontFamily: 'IBM Plex Mono', height: '3em'}}>
                                <Button style={{ fontFamily: 'IBM Plex Mono', height: '50%', fontSize: '15px'}} onClick={() => setContent('explore')} >
                                    <QuickLink>
                                        Offerings
                                    </QuickLink>
                                </Button>
                            </div>
                            <div style={{ fontFamily: 'IBM Plex Mono', height: '3em'}}>
                                <Button style={{ fontFamily: 'IBM Plex Mono', height: '50%', fontSize: '15px'}} onClick={() => setContent('newsfeed')} >
                                    <QuickLink>
                                        Newsfeed  
                                    </QuickLink>
                                </Button>
                            </div>
                            <div style={{ fontFamily: 'IBM Plex Mono', height: '3em'}}>
                                <Button style={{ fontFamily: 'IBM Plex Mono', height: '50%', fontSize: '15px'}} onClick={() => setContent('account')} >
                                    <QuickLink>
                                        Account
                                    </QuickLink>
                                </Button>
                            </div>
                        </div>
                        <div>
                            <div style={{ fontFamily: 'IBM Plex Mono', height: '3em'}}>
                                <Button style={{ fontFamily: 'IBM Plex Mono', height: '50%', fontSize: '15px' }} onClick={() => setContent('privacy policy')} >
                                    <QuickLink>
                                        Privacy Policy
                                    </QuickLink>
                                </Button>
                            </div>
                            <div style={{ fontFamily: 'IBM Plex Mono', height: '3em'}}>
                                <Button style={{ fontFamily: 'IBM Plex Mono', height: '50%', fontSize: '15px' }} onClick={() => setContent('terms of service')} >
                                    <QuickLink>
                                        Terms of Service
                                    </QuickLink>
                                </Button>
                            </div>
                        </div>
                        <div>
                            <div style={{ fontFamily: 'IBM Plex Mono', height: '3em', fontSize: '15px', paddingTop: '3px'}}>
                                JOIN THE WAITLIST
                            </div>
                            <div>
                                <FormGroup style={{ display: 'inline-block' }}>
                                    <FormField
                                        type="input"
                                        placeholder="EMAIL ADDRESS"
                                        id="email"
                                        required
                                        value={email}
                                        onChange={(event) => setEmail(event.target.value)}
                                        />
                                </FormGroup>
                                <ModalMakeOrderButton
                                            style={{ fontSize: '1em', color: 'white', fontFamily: "IBM Plex Mono", borderRadius: '20px', display: 'inline-block' }}
                                            onClick={expressInterest}
                                            variant='contained'
                                    >
                                        Submit
                                </ModalMakeOrderButton>
                            </div>
                            {enrolled && <p >Thanks! You'll hear from us soon.</p>}
                            {bademail && <p style={{color: 'red'}}>Please enter a valid email address.</p>}
                        </div>
                    </FooterLayoutBrowser>
                    <div style={{ fontFamily: 'Abhaya Libre', textAlign: 'center', paddingTop: '4em'}}>
                        &copy; Triple Crown Trading 2023
                    </div>
                </div>
            </BrowserView>
            {/* <TabletView style={{ zIndex: 3, position: 'relative' }}>
                <div style={{ backgroundColor: '#F2F2F2', padding: '2em 0 2em 0' }}>
                    <VerticallyCenteredRow style={{ padding: '0 0 2em 3em'}}>
                        <img src={Logo} alt="mainlogo" style={{ width: "80px", display: 'inline-block', padding: '0 1em 0 20px' }} /> 
                        <div style={{ fontFamily: 'Cinzel', fontSize: '1em', display: 'inline-block' }}>
                            TRIPLE CROWN TRADING
                        </div>
                    </VerticallyCenteredRow>
                    <FooterLayoutTablet>
                        <div style={{ paddingLeft: '63px'}}>
                            <div style={{ fontFamily: 'IBM Plex Mono', height: '3em'}}>
                                <Button style={{ fontFamily: 'IBM Plex Mono', height: '50%', fontSize: '15px'}} onClick={() => setContent('explore')} >
                                    <QuickLink>
                                        Dashboard
                                    </QuickLink>
                                </Button>
                            </div>
                            <div style={{ fontFamily: 'IBM Plex Mono', height: '3em'}}>
                                <Button style={{ fontFamily: 'IBM Plex Mono', height: '50%', fontSize: '15px'}} >
                                    <QuickLink>
                                        Offerings
                                    </QuickLink>
                                </Button>
                            </div>
                            <div style={{ fontFamily: 'IBM Plex Mono', height: '3em'}}>
                                <Button style={{ fontFamily: 'IBM Plex Mono', height: '50%', fontSize: '15px'}} onClick={() => setContent('newsfeed')} >
                                    <QuickLink>
                                        Newsfeed  
                                    </QuickLink>
                                </Button>
                            </div>
                            <div style={{ fontFamily: 'IBM Plex Mono', height: '3em'}}>
                                <Button style={{ fontFamily: 'IBM Plex Mono', height: '50%', fontSize: '15px'}} onClick={() => setContent('account')} >
                                    <QuickLink>
                                        Account
                                    </QuickLink>
                                </Button>
                            </div>
                        </div>
                        <div>
                            <div style={{ fontFamily: 'IBM Plex Mono', height: '3em'}}>
                                <Button style={{ fontFamily: 'IBM Plex Mono', height: '50%', fontSize: '15px' }} onClick={() => setContent('privacy policy')} >
                                    <QuickLink>
                                        Privacy Policy
                                    </QuickLink>
                                </Button>
                            </div>
                            <div style={{ fontFamily: 'IBM Plex Mono', height: '3em'}}>
                                <Button style={{ fontFamily: 'IBM Plex Mono', height: '50%', fontSize: '15px' }} onClick={() => setContent('terms of service')} >
                                    <QuickLink>
                                        Terms of Service
                                    </QuickLink>
                                </Button>
                            </div>
                        </div>
                        <div style={{ marginTop: '-65px'}}>
                            <div style={{ fontFamily: 'IBM Plex Mono', height: '3em', fontSize: '15px', paddingTop: '3px'}}>
                                JOIN THE WAITLIST
                            </div>
                            <div>
                                <FormGroup style={{ width: '60%', paddingBottom: '1em'}}>
                                    <FormField
                                        type="input"
                                        placeholder="EMAIL ADDRESS"
                                        id="email"
                                        required
                                        value={email}
                                        onChange={(event) => setEmail(event.target.value)}
                                        />
                                </FormGroup>
                                <ModalMakeOrderButton
                                            style={{ fontSize: '1em', color: 'white', fontFamily: "IBM Plex Mono", borderRadius: '20px', display: 'inline-block', width: '69%' }}
                                            onClick={expressInterest}
                                            variant='contained'
                                    >
                                        Submit
                                </ModalMakeOrderButton>
                            </div>
                            {enrolled && <p >Thanks! You'll hear from us soon.</p>}
                            {bademail && <p style={{color: 'red'}}>Please enter a valid email address.</p>}
                        </div>
                    </FooterLayoutTablet>
                </div>
            </TabletView> */}
            <MobileView style={{ zIndex: 3, position: 'relative' }}>
                <div style={{ backgroundColor: '#F2F2F2', padding: '2em 0 2em 0' }}>
                    <VerticallyCenteredRow style={{ padding: '0 0 2em 3em', justifyContent: 'center', marginLeft: '-4em' }}>
                        <img src={Logo} alt="mainlogo" style={{ width: "80px", display: 'inline-block', paddingRight: '1em' }} /> 
                        <div style={{ fontFamily: 'Cinzel', fontSize: '1em', display: 'inline-block' }}>
                            TRIPLE CROWN TRADING
                        </div>
                    </VerticallyCenteredRow>
                    <FooterLayoutMobile>
                        <div style={{ paddingLeft: '30px' }}>
                            <div style={{ fontFamily: 'IBM Plex Mono', height: '3em'}}>
                                <Button style={{ fontFamily: 'IBM Plex Mono', height: '50%', fontSize: '15px'}} onClick={() => setContent('explore')} >
                                    <QuickLink>
                                        Dashboard
                                    </QuickLink>
                                </Button>
                            </div>
                            <div style={{ fontFamily: 'IBM Plex Mono', height: '3em'}}>
                                <Button style={{ fontFamily: 'IBM Plex Mono', height: '50%', fontSize: '15px'}} onClick={() => setContent('explore')} >
                                    <QuickLink>
                                        Offerings
                                    </QuickLink>
                                </Button>
                            </div>
                            <div style={{ fontFamily: 'IBM Plex Mono', height: '3em'}}>
                                <Button style={{ fontFamily: 'IBM Plex Mono', height: '50%', fontSize: '15px'}} onClick={() => setContent('newsfeed')} >
                                    <QuickLink>
                                        Newsfeed  
                                    </QuickLink>
                                </Button>
                            </div>
                            <div style={{ fontFamily: 'IBM Plex Mono', height: '3em'}}>
                                <Button style={{ fontFamily: 'IBM Plex Mono', height: '50%', fontSize: '15px'}} onClick={() => setContent('account')} >
                                    <QuickLink>
                                        Account
                                    </QuickLink>
                                </Button>
                            </div>
                        </div>
                        <div>
                            <div style={{ fontFamily: 'IBM Plex Mono', height: '3em'}}>
                                <Button style={{ fontFamily: 'IBM Plex Mono', height: '50%', fontSize: '15px' }} onClick={() => setContent('privacy policy')} >
                                    <QuickLink>
                                        Privacy Policy
                                    </QuickLink>
                                </Button>
                            </div>
                            <div style={{ fontFamily: 'IBM Plex Mono', height: '3em'}}>
                                <Button style={{ fontFamily: 'IBM Plex Mono', height: '50%', fontSize: '15px' }} onClick={() => setContent('terms of service')} >
                                    <QuickLink>
                                        Terms of Service
                                    </QuickLink>
                                </Button>
                            </div>
                        </div>
                    </FooterLayoutMobile>
                    <div style={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center', alignContent: 'center' }}>
                        <div style={{ fontFamily: 'IBM Plex Mono', height: '3em', fontSize: '15px', paddingTop: '3px'}}>
                            JOIN THE WAITLIST
                        </div>
                        <FormGroup style={{ width: '60%', paddingBottom: '1em', transform: 'translateX(22%)' }}>
                            <FormField
                                type="input"
                                placeholder="EMAIL ADDRESS"
                                id="email"
                                required
                                value={email}
                                onChange={(event) => setEmail(event.target.value)}
                                />
                        </FormGroup>
                        <ModalMakeOrderButton
                                    style={{ fontSize: '1em', color: 'white', fontFamily: "IBM Plex Mono", borderRadius: '20px', display: 'inline-block', width: '69%' }}
                                    onClick={expressInterest}
                                    variant='contained'
                            >
                                Submit
                        </ModalMakeOrderButton>
                        {enrolled && <p >Thanks! You'll hear from us soon.</p>}
                        {bademail && <p style={{color: 'red'}}>Please enter a valid email address.</p>}
                    </div>
                </div>
            </MobileView>
        </>
    )
}
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useSelectFromRedux } from "../../utils/_hooks";
import { FilterContainer, MarketFilterContainer, ModalHeader, Row, Column, CenteredText, SpacedCenteredText, ModalContainer, LineDivider } from ".";
import { Button, Slider, Box, FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { Horse, NewsItem, RaceEvent } from "../../types";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { debounce } from "lodash";
import { isMobileOnly } from "react-device-detect";


export default (props: {article: RaceEvent | NewsItem, setModal: Dispatch<SetStateAction<NewsItem| RaceEvent| null>>, modalOn: boolean, mini: boolean}) => {

    const art = props.article;
    const setModal = props.setModal;
    const modalOn = props.modalOn;
    const mini = props.mini;

    const [
        logo, 
        textColor, 
        background,
        myAssetData,
        horses,
        user,
        colorMode
    ] = useSelectFromRedux((state) => [
        state.color.scheme.logo, 
        state.color.scheme.text,
        state.color.scheme.background,
        state.user.myAssetData,
        state.publicData.horses,
        state.user.user,
        state.color.mode
    ]);

    // const [fullArticle, showFullArticle] = React.useState<Boolean>(false);
    const [windowSize, setWindowSize] = useState({
        windowWidth: window.outerWidth,
        windowHeight: window.outerHeight,
      });
    const [resized, setResized] = useState<Boolean>(window.outerWidth < 990)
    
      useEffect(() => {
        const handleResize = debounce(() => {
          setWindowSize({
            windowWidth: window.outerWidth,
            windowHeight: window.outerHeight,
          });
          setResized(window.outerWidth < 990);

        }, 250); // Adjust the debounce time (in milliseconds) as needed
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          handleResize.cancel(); // Cancel any pending debounced calls
          window.removeEventListener('resize', handleResize);
        };
      }, []);


    return (
        <Row style={{color: textColor, marginBottom: isMobileOnly? '10px':'20px', alignItems: 'center', flexDirection: !resized ? 'row' : 'column', gap:'10px', padding: '5px', boxShadow: colorMode=='light' ? `0px 2px 4px gray`: undefined}} onClick={() => setModal(art)} key={art.title}>
            <img src={art.photo} style={{maxHeight: mini? '175px': '200px', maxWidth: mini? '250px': isMobileOnly? '300px': '350px', borderRadius: '4px', paddingLeft:'10px'}}/>
            <Column style={{ alignItems:'center'}}>
                <SpacedCenteredText text={logo} style={{fontSize: mini? '20px':'30px', zIndex: 'inherit'}}>
                    {art.title}
                </SpacedCenteredText>
                {'location' in art &&
                <SpacedCenteredText text={textColor} style={{marginBottom: '10px', zIndex:'inherit'}}>
                    {art.location} | {art.date}
                </SpacedCenteredText>}
                {/* {!fullArticle ? <> */}
                    <div style={{width: '95%', zIndex: 'inherit', fontSize: mini? '12px':'16px'}}>
                        {art.text.substring(0, mini? 200 : 500)}...
                    </div>
                    <Button 
                        onClick={() => setModal(art)} 
                        color='inherit' 
                        style={{color: logo, marginTop: '10px', zIndex: 3, visibility: 'visible'}}
                    >Read More</Button>
            </Column>
        </Row>
    )
}
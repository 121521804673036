import React, { useEffect, useRef, useState } from "react";

// redux
import { useSelectFromRedux, useDispatchPromise } from "../../utils/_hooks";
import Paper from "@mui/material/Paper";



//components
import {
    Chart,
    ArgumentAxis,
    ValueAxis,
    LineSeries,
    Title,
    Tooltip,
    ZoomAndPan
  } from '@devexpress/dx-react-chart-material-ui';
import { styled } from "@mui/material/styles";
import { Animation, ValueScale } from '@devexpress/dx-react-chart';
import moment from "moment";
import { Horse, HorseValueData} from "../../types";
import { Button, ButtonGroup, Divider } from "@material-ui/core";
import { line, curveStep } from 'd3-shape';
import { EventTracker, HoverState } from '@devexpress/dx-react-chart';
import { graphTooltip, Row } from ".";
import {Column, StyChart} from "../_shared";
import { isMobileOnly } from "react-device-detect";
import { scaleLinear } from 'd3-scale';


export default (props: {currentData: Horse, currentName: string, height: number, width: number}) => {

    let currentData : Horse = props.currentData;
    const width = props.width;

    const [
        logo, 
        textColor, 
        background,
        text, 
        colorMode
    ] = useSelectFromRedux((state) => [
        state.color.scheme.logo, 
        state.color.scheme.text,
        state.color.scheme.background,
        state.color.scheme.text,
        state.color.mode
    ]);


    const [filterRange, setFilterRange] = useState<'day' | 'week' | 'month' | '3month' | '6month' | 'year' | 'all'>('all')

    const filterData = () : void => {
        let earlyDate = moment()
        setNoData(false)
        if (filterRange == 'day') {
            earlyDate.subtract(1, 'day')
        } else if (filterRange == 'week') {
            earlyDate.subtract(1, 'week')
        } else if (filterRange == 'month') {
            earlyDate.subtract(1, 'month')
        } else if (filterRange == '3month') {
            earlyDate.subtract(3, 'months')
        } else if (filterRange == '6month') {
            earlyDate.subtract(6, 'months')
        } else if (filterRange == 'year') {
            earlyDate.subtract(1, 'year')
        } else if (filterRange == 'all') {
            earlyDate = moment(0)
        }
        let newData = currentData.historical_price.filter((horse : any) => moment(horse.date).isAfter(earlyDate))
        if(newData.length==1){            
            newData = newData.map((data : any) => ({...data, date: new Date(data.date).getTime()}))
            .sort((a : any, b: any) => a.date - b.date);
            let temp : HorseValueData = {date: (newData[0].date as unknown as number +1)as unknown as Date, price: newData[0].price}
            newData.push(temp);
        } else if (newData.length==0){
            setNoData(true);
        } else {
            newData = newData.map((data : any) => ({...data, date: new Date(data.date).getTime()}))
            .sort((a : any, b: any) => a.date - b.date)
        }
        setCurrentFilteredData(newData);
        }
        

    const [animate, setAnimation] = React.useState<Boolean>()
    const [currentFilteredData, setCurrentFilteredData] = useState<Horse['historical_price']>(currentData.historical_price);
    const [noData, setNoData] = useState<Boolean>(currentFilteredData.length==0)
    
    let j = 0;
    let k = 0;

    useEffect(() => {
        setAnimation(false)
        j=0;
        k=0;
        filterData()
    }, [filterRange, currentData]);

    useEffect(()=> {
        setAnimation(true)
    }, [currentFilteredData])

    const ValueLabel = (props: any) => {
        const { text, x, y } = props;
        return (
          <ValueAxis.Label
            {...props}
            text={`$${text}`}
            style={{fill: textColor, fontFamily: "IBM Plex Mono"}}
          />
        );
      };

    const updateGraph = (time: 'day' | 'week' | 'month' | '3month' | '6month' | 'year' | 'all') => {
        setAnimation(false)
        j = 0;
        k = 0;
        setFilterRange(time)
    }
    
    
    const ArgLabel = (props: any) => {
        const { text } = props;
        const date = moment(new Date(+(text.replaceAll(',', '')))).format(filterRange === '3month' || filterRange === 'month' || filterRange === 'week' || filterRange === 'day' ? 'MM/DD' : 'MM/YY');
        let tempFontSize: number = 0;
        let maxNumLabels: number = (width - 67)/50;
        let argumentLabelInterval: number = 2;
        if((filterRange === '6month' || filterRange === 'year' || filterRange === 'all') && width<450 ){
            argumentLabelInterval = 3;
        } 
        j+=1
        if(j%argumentLabelInterval==0){
            tempFontSize=12;
        }

        return (
            <ArgumentAxis.Label
                {...props}
                text={date}
                style={{fill: textColor, fontSize: tempFontSize, fontFamily: "IBM Plex Mono"}}
            />
        );
    }
    
    const TitleText = (props: any) => (
        <Title.Text {...props} sx={{ whiteSpace: 'pre', color: textColor }} />

      );

    const PREFIX = 'Demo';

    const classes = {
    chart: `${PREFIX}-chart`,
    };

    const [hover, setHover] = React.useState<any>()



    const hoverText = (props: Tooltip.ContentProps): any => {
        let date: Date = currentFilteredData[props.targetItem.point].date;
        const dateTxt = moment(date).format('MMM DD, YYYY');
        return (
            <Column style={{alignItems: 'center', padding: '5px', borderColor: logo, borderWidth:'2px', borderRadius: '3px', zIndex: 100, background: background}}>
                <div style={{textAlign: 'center', color: logo}}>${currentFilteredData[props.targetItem.point].price}</div>
                <div style={{textAlign: 'center', fontSize: '12px', color: text}}>{dateTxt}</div>
            </Column>
        )
    }

    
    

    return (
        <Paper style={{ borderRadius: '10px', paddingRight: '20px'}} elevation={0}> 
            {animate && <StyChart
                data={currentFilteredData}
                height={props.height}
                width={props.width}
                
            >
                <ValueScale 
                    name="price"
                    factory={scaleLinear}
                />
                <ArgumentAxis 
                    labelComponent={ArgLabel}
                    // tickComponent={ArgTick}
                />
                 <ValueAxis
                    labelComponent={ValueLabel}
                    showLine={false}
                    showGrid={true}
                    scaleName="price"
                />
                <LineSeries
                    name="Line"
                    valueField='price'
                    argumentField='date'
                    color={noData ? (colorMode=='blue' ? '#1a2c50': colorMode=='light' ? 'white': 'black'): logo}
                    scaleName="price"
                />
                {noData && <Title
                    text={"No Data For This Period"}
                    textComponent={TitleText}
                />}
                <EventTracker />
                <Animation/>

                <Tooltip  sheetComponent={graphTooltip} contentComponent={hoverText} targetItem={hover} onTargetItemChange={setHover}/>
                {/* <ZoomAndPan/> */}
            </StyChart>}
            <Row style={{color: text, justifyContent: 'space-between', width: props.width, marginTop: '15px'}}>
                    <Button 
                        onClick={() => updateGraph('day')}
                        color='inherit' style={{color: filterRange == 'day' ? logo : text, borderStyle: 'solid', borderColor: '#D9D9D9', borderWidth: '1px', borderRadius: '20px', fontFamily: 'IBM Plex Mono', height: '20px', width: '80px', fontSize: '13px'}}
                    >Day</Button>
                    <Button 
                        onClick={() => updateGraph('week')}
                        color='inherit' style={{color: filterRange == 'week' ? logo : text, borderStyle: 'solid', borderColor: '#D9D9D9', borderWidth: '1px', borderRadius: '20px', fontFamily: 'IBM Plex Mono', height: '20px', width: '80px', fontSize: '13px'}}
                    >Week</Button>
                    <Button 
                        onClick={() => updateGraph('month')}
                        color='inherit' style={{color: filterRange == 'month' ? logo : text, borderStyle: 'solid', borderColor: '#D9D9D9', borderWidth: '1px', borderRadius: '20px', fontFamily: 'IBM Plex Mono', height: '20px', width: '80px', fontSize: '13px'}}
                    >Month</Button>
                    <Button 
                        onClick={() => updateGraph('year')}
                        color='inherit' style={{color: filterRange == 'year' ? logo : text, borderStyle: 'solid', borderColor: '#D9D9D9', borderWidth: '1px', borderRadius: '20px', fontFamily: 'IBM Plex Mono', height: '20px', width: '80px', fontSize: '13px'}}
                    >Year</Button>
                    <Button 
                        onClick={() => updateGraph('all')}
                        color='inherit' style={{color: filterRange == 'all' ? logo : text, borderStyle: 'solid', borderColor: '#D9D9D9', borderWidth: '1px', borderRadius: '20px', fontFamily: 'IBM Plex Mono', height: '20px', width: '100px', fontSize: '10px', whiteSpace: 'nowrap'}}
                    >All Time</Button>
            </Row>
        </Paper>
    );
};
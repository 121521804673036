import React, { Dispatch, SetStateAction, useEffect } from "react";
import { useSelectFromRedux } from "../../utils/_hooks";
import { FilterContainer, MarketFilterContainer, ModalHeader } from "../_shared";
import { Button, Slider, Box, FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { Horse } from "../../types";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { isMobileOnly } from "react-device-detect";


export default (props: {setSlider: Dispatch<SetStateAction<[string, Horse[]]>> | null, filterMode: Boolean, setFilter: Dispatch<SetStateAction<Boolean>>, setHorseOfferings: Dispatch<SetStateAction<Horse[]>> | null}) => {

    const {setSlider, filterMode, setFilter, setHorseOfferings} = props;

    const [
        text,
        background,
        logo,
        horses
    ] = useSelectFromRedux((state) => [
        state.color.scheme.text,
        state.color.scheme.background,
        state.color.scheme.logo,
        state.publicData.horses
    ]);

    const [filterPrice, setFilterPrice] = React.useState<number[]>([0, 30000]);
    const handlePriceChange = (event: Event, newPrice: number | number[]) => {
        setFilterPrice(newPrice as number[]);
    };
    const checkPrice = (horse: Horse) : boolean =>{
        return horse.price >= filterPrice[0] && horse.price <= filterPrice[1]
    };

    const [filterSex, setFilterSex] = React.useState<boolean[]>([true, true, true]); //[Mare, Colt, Filly] ***setFilterSex is unused (I made handleSexChange which modifies state directly)***
    const handleSexChange = (event: any, checked: boolean) => {
        
        if(event.target.id == "Colt"){
            setFilterSex([!filterSex[0], filterSex[1], filterSex[2]]);
        } else if (event.target.id == "Mare"){
            setFilterSex([filterSex[0], !filterSex[1], filterSex[2]]);
        } else setFilterSex([filterSex[0], filterSex[1], !filterSex[2]]);
    };
    const checkSex = (horse: Horse) : boolean =>{
        return horse.sex == 'Colt' && filterSex[0] || horse.sex == 'Mare' && filterSex[1] || horse.sex == 'Filly' && filterSex[2]
    };


    const [reset, setReset] = React.useState<number>(0);

    // useEffect(() => {
    //     filterHorses();
    // }, [horses, reset])

    const filterHorses = () => {
        let filtered: Horse[] = [];
        for(let horse of horses) {
            if(checkPrice(horse) && checkSex(horse)){
                filtered.push(horse);
            }
        }
        if(setSlider!=null) {setSlider(['Filtered Horses',filtered]);}
    }

    const confirmedFilterHorses = () => {
        let filtered: Horse[] = [];
        for(let horse of horses) {
            if(checkPrice(horse) && checkSex(horse)){
                filtered.push(horse);
            }
        }
        setFilter(false);
        if(setSlider!=null) {setSlider(['Filtered Horses',filtered]);}
        else if(setHorseOfferings!=null) {setHorseOfferings(filtered)}
    }

    const toggleEdit = () => {
        setFilter(!filterMode);
    };


    const resetFilter = () => {
        setFilter(false);
        
        setFilterPrice([0, 30000]);
        setFilterSex([true, true, true]);

        setFilter(true); 
        setReset(reset + 1);

    }

    // if (!filterMode) {
    //     return (<>
    //     <FilterAltIcon
    //         color='inherit' 
    //         style={{width: '30px', height: '30px', right: '35.5px', top: '120px', position: 'fixed', color: text, cursor: 'pointer', zIndex: 4, background: background}}
    //         onClick={toggleEdit} 
    //         />
    //     <h1 style = {{fontSize: '13px', height: '90px', right: '35px', top: '140px', position: 'fixed', color: text}}  > Filter </h1>
    //     </>)
    // }

    return (
        <>
        {filterMode && 
        <MarketFilterContainer color={text} background={background} style={{ top: '100px', borderColor: logo, borderWidth: '5px', borderStyle: 'solid', position: 'fixed',minWidth:'320px', left: isMobileOnly?'25px': ''}} >
                <CloseIcon style={{paddingLeft:'90%', cursor: 'pointer'}} onClick={() => setFilter(false)}/>
                <ModalHeader color={logo} style={{minHeight: '20px'}}>
                    Filter Options
                </ModalHeader>
                <Box style={{paddingBottom: '10px', width: '80%'}}
                >
                    <div> Price Range: </div>
                    <Slider
                        style={{color: logo}}
                        max={30000}
                        value={filterPrice}
                        onChange={handlePriceChange}
                        valueLabelDisplay="auto"
                        
                    />
                </Box>
                <Box style={{paddingBottom: '10px', width: '80%'}} 
                >
                    <div> Sex: </div>
                    <FormGroup row={true}>
                        <FormControlLabel control={<Checkbox checked={filterSex[0]} onChange={handleSexChange} style={{color: logo}} id="Colt" />} label="Colt" />
                        <FormControlLabel control={<Checkbox checked={filterSex[1]} onChange={handleSexChange} style={{color: logo}} id="Mare"/>} label="Mare" />
                        <FormControlLabel control={<Checkbox checked={filterSex[2]} onChange={handleSexChange} style={{color: logo}} id="Filly"/>} label="Filly" />
                    </FormGroup>
                </Box>
                <div style={{paddingTop:'50px', display: 'flex', flexDirection: 'row', gap: '15%'}}>
                    <Button 
                        onClick={confirmedFilterHorses} 
                        variant="contained"
                        color='primary'
                        style={{color: text, marginBottom: '5%', marginTop: '-50px'}}
                        > 
                        Confirm 
                    </Button>
                    <Button 
                        onClick={resetFilter} 
                        variant="contained"
                        color='inherit'
                        style={{color: background, marginBottom: '5%', marginTop: '-50px', background: logo}}
                        > 
                        Reset
                    </Button>
                </div>
                    
                
            </MarketFilterContainer>
        }
        </>
    )
}
import React, { useEffect, useRef, useState } from "react";

// redux
import { useSelectFromRedux, useDispatchPromise } from "../../utils/_hooks";
import Paper from "@mui/material/Paper";

//components
import {
    Chart,
    ArgumentAxis,
    ValueAxis,
    LineSeries,
    Title,
    Tooltip
  } from '@devexpress/dx-react-chart-material-ui';
import { styled } from "@mui/material/styles";
import { Animation } from '@devexpress/dx-react-chart';
import moment from "moment";
import { Horse, UserAccount, TransactionInfo, HorseValueData, Ownership, HorseValueDataWithInvestment} from "../../types";
import { Button, ButtonGroup } from "@material-ui/core";
import { line, curveStep } from 'd3-shape';
import { isMobileOnly } from "react-device-detect";
import { Column, graphTooltip, StyChart } from ".";
import { EventTracker, HoverState } from '@devexpress/dx-react-chart';

export default (props: {height: number, width: number}) => {

    const [
        logo, 
        textColor, 
        background,
        text,
        horses,
        user,
        myAssetData
    ] = useSelectFromRedux((state) => [
        state.color.scheme.logo, 
        state.color.scheme.text,
        state.color.scheme.background,
        state.color.scheme.text,
        state.publicData.horses,
        state.user.user,
        state.user.myAssetData
    ]);

    const [investmentValueChangeData, setInvestmentValueChangeData] = useState<HorseValueDataWithInvestment[]>(myAssetData);
    const [animate, setAnimation] = React.useState<Boolean>(false)
    const [filterRange, setFilterRange] = useState<'day' | 'week' | 'month' | '3month' | '6month' | 'year' | 'all'>('all')
    const [noData, setNoData] = useState<Boolean>(investmentValueChangeData.length==0)

    useEffect(() => {
        setAnimation(false)
        filterData()
    }, [filterRange, myAssetData]);

    useEffect(()=> {
        
        setAnimation(true)
    }, [investmentValueChangeData])


    const filterData = () : void => { 
        let earlyDate = moment()
        setNoData(false)
        if (filterRange == 'day') {
            earlyDate.subtract(1, 'day')
        } else if (filterRange == 'week') {
            earlyDate.subtract(1, 'week')
        } else if (filterRange == 'month') {
            earlyDate.subtract(1, 'month')
        } else if (filterRange == '3month') {
            earlyDate.subtract(3, 'months')
        } else if (filterRange == '6month') {
            earlyDate.subtract(6, 'months')
        } else if (filterRange == 'year') {
            earlyDate.subtract(1, 'year')
        } else if (filterRange == 'all') {
            earlyDate = moment(0)
        }
        let newData = myAssetData?.filter((InvestmentValueChange : any) => moment(InvestmentValueChange.date).diff(earlyDate) > 0);
        if(newData.length==1){            
            newData = newData.map((data : any) => ({...data, date: new Date(data.date).getTime()}))
            .sort((a : any, b: any) => a.date - b.date);
            let temp : HorseValueData = {date: (newData[0].date as unknown as number +1)as unknown as Date, price: newData[0].price}
            newData.push(temp);
        } else if (newData.length==0){
            setNoData(true);
        } else {
            newData = newData.map((data : any) => ({...data, date: new Date(data.date).getTime()}))
            .sort((a : any, b: any) => a.date - b.date)
        }
        setInvestmentValueChangeData(newData);
    }

    const ValueLabel = (props: any) => {
        const { text } = props;
        return (
          <ValueAxis.Label
            {...props}
            text={`$${text}`}
            style={{fill: textColor}}
          />
        );
      };
    
      const ArgLabel = (props: any) => {
        const { text } = props;
        const date = moment(new Date(+(text.replaceAll(',', '')))).format(filterRange === '3month' || filterRange === 'month' || filterRange === 'week' || filterRange === 'day' ? 'MMM DD' : 'MMM YY');
        return (

            <ValueAxis.Label
                {...props}
                text={date}
                style={{fill: textColor}}

            />
        );
      }

    const PREFIX = 'Demo';

    const classes = {
    chart: `${PREFIX}-chart`,
    };

    const TitleText = (props: any) => (
        <Title.Text {...props} sx={{ whiteSpace: 'pre', color: textColor }} />

      );

    const Line = (props: any) => (
        <LineSeries.Path
          {...props}
          path={line()
            //@ts-ignore
            .x(({ arg }) => arg)
            //@ts-ignore
            .y(({ val }) => val)
            .curve(curveStep)}
        />
      );
      const [hover, setHover] = React.useState<any>()

    
      const hoverText = (props: Tooltip.ContentProps): any => {
        let date: Date = investmentValueChangeData[props.targetItem.point].date;
        const dateTxt = moment(date).format('MMM DD, YYYY');
        return (
            <Column style={{alignItems: 'center', padding: '5px', borderColor: logo, borderWidth:'2px', borderRadius: '3px', zIndex: 100, background: background}}>
                <div style={{textAlign: 'center', color: logo}}>${investmentValueChangeData[props.targetItem.point].price}</div>
                <div style={{textAlign: 'center', fontSize: '12px', color: text}}>{dateTxt}</div>
            </Column>
        )
    }

    

    return (
        <Paper style={{background: background, paddingRight: isMobileOnly? '0px':'20px'}} elevation={3}> 
            {animate && <StyChart
                data={investmentValueChangeData}
                width={props.width}
                height={props.height}
            >
                <ArgumentAxis 
                    labelComponent={ArgLabel}
                />
                 <ValueAxis
                    labelComponent={ValueLabel}
                />
                <LineSeries
                    // {...props}
                    name="Amount Invested"
                    valueField='investmentvalue'
                    argumentField='date'
                    color={logo}
                    // seriesComponent={Line}
                />
                <LineSeries
                    // {...props}
                    name="Investment Value"
                    valueField='price'
                    argumentField='date'
                    color= 'blue'
                    // seriesComponent={Line}
                /> 
                {noData && <Title
                    text={"No Data For This Period"}
                    textComponent={TitleText}
                />}
                <EventTracker />

                <Animation/>
                <Tooltip  sheetComponent={graphTooltip} contentComponent={hoverText} targetItem={hover} onTargetItemChange={setHover}/>

            </StyChart>}
            <ButtonGroup variant='text' aria-label ='outlined button group' color='inherit' style={{color: text, width:'100%', justifyContent:'center'}}>
                    {!isMobileOnly && <Button 
                        onClick={() => setFilterRange('day')}
                        color='inherit' style={{color: filterRange == 'day' ? logo : text}}
                    >1 Day</Button>}
                    {!isMobileOnly && <Button 
                        onClick={() => setFilterRange('week')}
                        color='inherit' style={{color: filterRange == 'week' ? logo : text}}
                    >1 Week</Button>}
                    <Button 
                        onClick={() => setFilterRange('month')}
                        color='inherit' style={{color: filterRange == 'month' ? logo : text}}
                    >1 Month</Button>
                    {!isMobileOnly && <Button 
                        onClick={() => setFilterRange('3month')}
                        color='inherit' style={{color: filterRange == '3month' ? logo : text}}
                    >3 Months</Button>}
                    <Button 
                        onClick={() => setFilterRange('6month')}
                        color='inherit' style={{color: filterRange == '6month' ? logo : text}}
                    >6 Months</Button>
                    <Button 
                        onClick={() => setFilterRange('year')}
                        color='inherit' style={{color: filterRange == 'year' ? logo : text}}
                    >1 Year</Button>
                    <Button 
                        onClick={() => setFilterRange('all')}
                        color='inherit' style={{color: filterRange == 'all' ? logo : text}}
                    >All</Button>
            </ButtonGroup>
        </Paper>
    );
};
// mapbox
import moment from "moment";
import { isMobile } from "react-device-detect";

// colors
export const boxShadow = "rgba(0, 0, 0, 0.1)";
export const buttonOrange = "#E37134";
export const gradientTopBlue = "#374151";
export const gradientBottomBlue = "#171F2E";
export const gradientTopRed = "#DB002A";
export const gradientBottomRed = "#B70022";
export const fadedRed = "#e85454";
export const lightFadedRed = "rgba(255, 147, 147, 0.37)";
export const activeSubmitButtonBackground = "#0f74d9";
export const activeAuthSubmitButtonBackground = "#de6321";

// SOS button
export const sosDashboardTopColor = "#dc0808";
export const sosDashboardBottomColor = "#ab0202";

// SOS cancel countdown duration (s)
export const SOSCountdownDuration = 5;

// Notifications
export const confirmationGreen = "#27A953";
export const backlogRed = "#b10000";
export const counterBorderRed = "#ff0000";
export const counterFontRed = "#dc0808";

export const borderBlueGrey = "#6E7E99";
export const activeGreen = "#44F9B8";
export const white = "#FFFFFF";
export const fieldBackgroundDarkBlue = "#1B2028";
export const gradientBottomGrey = "#C5C5C5";

// cuser panel
export const CuserPanelBackgroundGradient = "linear-gradient(#374151, #171f2e)";
export const CuserPanelTextColor = "#eeeeee";

// screen break-points
const size = {
    mobile: "576px",
    tablet: "992px"
};

export const device = {
    mobile: `(max-width: ${size.mobile})`,
    tablet: `(max-width: ${size.tablet})`
};

export let getDataSuccessful = false;

export const dataSuccessfullyGotten = () => {
    getDataSuccessful = true;
};

export const getDateTimeString = (date_time: Date): string => {
    const hoursSince = moment(new Date()).diff(date_time, "hours");
    if (hoursSince <= 3) {
        return moment(date_time).fromNow();
    } else {
        return moment(date_time).format("ddd MMM Do, YYYY HH:mm z");
    }
};

export const lightenDarkenColor = (color: string, amount: number) => {
    var usePound = false;

    if (color[0] === "#") {
        color = color.slice(1);
        usePound = true;
    }

    var num = parseInt(color, 16);

    var r = (num >> 16) + amount;

    if (r > 255) r = 255;
    else if (r < 0) r = 0;

    var b = ((num >> 8) & 0x00ff) + amount;

    if (b > 255) b = 255;
    else if (b < 0) b = 0;

    var g = (num & 0x0000ff) + amount;

    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
};

import { List } from "@material-ui/core";
import { ResetTvOutlined } from "@mui/icons-material";
import { produce } from "immer";
import moment from "moment";
import { payloadAction, actionFactory, ActionUnion } from "reductser";
import { Horse, HorseValueData, HorseValueDataWithInvestment, Ownership, UserAccount } from "../../types";

const actionMap = {
    signIn: payloadAction<{user: UserAccount, horses: Horse[]} | null>(),
    setUser: payloadAction<UserAccount | null>(),
    addOwnership: payloadAction<Ownership>(),
    setMyAssetData: payloadAction<Horse[]>(),
    setSignInError: payloadAction<string | null>(),
    setSignUpError: payloadAction<string | null>(),
    setUpdateError: payloadAction<string | null>(),
    setWrongPassword: payloadAction<boolean>(),
    setTransactionResponse: payloadAction<string | null>()
};

export const UserActions = actionFactory(actionMap, "USER");

export type UserAction = ActionUnion<typeof UserActions>;

export interface UserState {
    user: UserAccount | null,
    myAssetData: HorseValueDataWithInvestment[] | null | any,
    signInError: string | null,
    signUpError: string | null,
    updateError: string | null,
    wrongPassword: boolean,
    transactionResponse: string | null
}

const getDateIndexSum = (date: Date, horseList: HorseValueDataWithInvestment[][]) : number => {
    let result = 0
    for (var horse of horseList) {
        let index = 0
        while (index < horse.length && moment(horse[index].date).isSameOrBefore(moment(date))) {
            index += 1
        }
        if((index-1) > -1 && (index-1) < horse.length && horse[index-1]?.price) {
            result += horse[index-1]?.price
        }
    }
    return result
}

const getDateValueSum = (date: Date, horseList: HorseValueDataWithInvestment[][]) : number => {
    let result = 0
    for (var horse of horseList) {
        let index = 0
        while (index < horse.length && moment(horse[index].date).isSameOrBefore(moment(date))) {
            index += 1
        }
        if((index-1) > -1 && (index-1) < horse.length && horse[index-1]?.investmentvalue) {
            result += horse[index-1]?.investmentvalue
        }
    }
    return result
    
}

// old algorithm, didn't account for transaction times. Just sums up total ownership
// export const getAssetData = (user: UserAccount, data : Horse[]) => {
//     if(user.ownerships.length == 0) {
//         return null
//     }
//     const myOwnershipIDS = user.ownerships.map((ownership) => ownership.horse_id);
//     const myOwnerships = user.ownerships;
//     let myHorses : HorseValueData[][] = [];
//     for (var horse of data) {
//         if(myOwnershipIDS.includes(horse.id)) {
//             const filteredOwnerships = myOwnerships.filter((ownership) => ownership.horse_id == horse.id);
//             const thisOwnership = filteredOwnerships[0].totalShares;
//             myHorses.push(horse.historical_price.map((dataPoint) => ({date: dataPoint.date, price: thisOwnership*dataPoint.price})));
//         }
//     }
//     let result = myHorses.pop();
//     result = result?.map((dataPoint : HorseValueData, index) : HorseValueData => ({date: dataPoint.date, price: (dataPoint.price + getIndexSum(index, myHorses))}))
//     return result ?? null
// }

// accounts for transaction times
export const getAssetData = (user: UserAccount, data : Horse[]) => {
    if(Object.keys(user.ownerships).length === 0) {
        return data[0].historical_price.map((point) => ({date: new Date(point.date).getTime(), price: 0, investmentvalue: 0}));
    }
    const myOwnershipIDS = user.ownerships.map((ownership) => ownership.horse_id); // gets IDS of all ownerships
    const myOwnerships = user.ownerships;
    let myHorses : HorseValueDataWithInvestment[][] = []; // all horses values, to be combined later
    for (var horse of data) { // iterates through horses
        if(myOwnershipIDS.includes(horse.id)) { // only those we own
            const filteredOwnerships = myOwnerships.filter((ownership) => ownership.horse_id == horse.id); // gets the correct ownership
            let subresult = [] // will keep track of ownership of this specific horses (including shift in owned shares)
            for (var dataPoint of horse.historical_price) { // goes through each datapoint (horse value at given time)
                const date = moment(dataPoint.date); // gets the date of that value
                let totalShares = 0 // to keep track of total shares at that moment
                let totalInvestment = 0
                for (var transaction of filteredOwnerships[0].transactions) { // goes through transactions, add numbers of shares for ones before the dataPoint date
                    if(moment(transaction.date).isSameOrBefore(date)) { // check ^
                        totalShares += transaction.shares
                        totalInvestment += transaction.shares * transaction.price
                    }
                }
                subresult.push({date: dataPoint.date, price: totalShares*dataPoint.price, investmentvalue: totalInvestment}) // adds this value to the subresult (for this horse)
            }
            subresult.sort((a,b) => moment(a.date).diff(moment(b.date)))
            myHorses.push(subresult) // adds this horses value data to the overall array
        }
    }
    // let result = myHorses.pop();
    let result = [];
    let transactions = user.ownerships.map((ownership) => ownership.transactions.map((transaction) => transaction.date)).flat()
    for (var item1 of transactions) {
        result.push(item1)
    }
    for(var item of myHorses) {
        for(var dp of item) {
            result.push(dp.date)
        }
    }
    result.sort((a,b) => moment(a).diff(moment(b)))
    result = result?.map((date) => ({date: new Date(date).getTime(), price: (getDateIndexSum(date, myHorses)), investmentvalue: (getDateValueSum(date, myHorses))})) // finds aggregate sum of all ownerships at each time
    return result ?? null
}

export const getInitialState = (): UserState => ({
    user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') ?? '') : null,
    myAssetData: [],
    signInError: null,
    signUpError: null,
    updateError: null,
    wrongPassword: false,
    transactionResponse: null
});




const colorReducer = (state = getInitialState(), action: UserAction) =>
    produce(state, (draftState) => {
        if (action.reducer === "USER") {
            switch (action.type) {
                case "setUser":
                    draftState.user = action.payload
                    break;
                case 'addOwnership':
                    if(draftState.user) {
                        let newOwnerships = draftState.user.ownerships;
                        for(let ownership of newOwnerships){ // Added by Shane to integrate addOwnership() with updated Transaction/Ownership type structure
                            if(ownership.horse_id == action.payload.horse_id){
                                ownership.totalShares += action.payload.transactions[0].shares;
                                ownership.transactions.push(action.payload.transactions[0])
                                draftState.user.ownerships = newOwnerships;
                                return;
                            }
                        }
                        newOwnerships.push(action.payload);
                        draftState.user.ownerships = newOwnerships;
                        break;
                    }
                    break;
                case 'signIn':
                    draftState.user = action.payload?.user ?? null;
                    if(action.payload?.user && action.payload?.horses) {
                        draftState.myAssetData = getAssetData(action.payload.user, action.payload.horses)
                    }
                    break;
                case 'setSignInError':
                    draftState.signInError = action.payload;
                    break;
                case 'setSignUpError':
                    draftState.signUpError = action.payload;
                    break;
                case 'setUpdateError':
                    draftState.updateError = action.payload;
                    break;
                case 'setWrongPassword':
                    draftState.wrongPassword = action.payload;
                    break;
                case 'setMyAssetData':
                    if(draftState.user) {
                        draftState.myAssetData = getAssetData(state.user ?? draftState.user, action.payload)
                    }
                    break;
                case 'setTransactionResponse':
                    draftState.transactionResponse = action.payload;
                    break;
                default:
                    break;
            }
        }
    });

export default colorReducer;
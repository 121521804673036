import styled from "styled-components";
import { boxShadow, device } from "../../utils";
import { Badge } from "@material-ui/core";
import { Horse, HorseValueData, NewsItem, RaceEvent, UserAccount, TransactionInfo } from "../../types";
import { Chart } from "@devexpress/dx-react-chart-material-ui";
const HorseImage = "https://tctrading.s3.amazonaws.com/utils/horse.jpg";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import CloseIcon from '@mui/icons-material/Close';
import Button from "@mui/material/Button";

export const Row = styled.div`
    display: flex;
    flex-direction: row;
`;

export const VerticallyCenteredRow = styled(Row)`
    align-items: center;
`;

export const VerticallyCenteredRowFlexWrap = styled(Row)`
    align-items: center;
    justify-content: center;

    @media (max-width: 1000px) {
        flex-wrap: wrap;
    }
`;

export const Column = styled.div`
    display: flex;
    flex-direction: column;
`;

interface DividerProps {
    firstColor: string;
    secondColor: string;
    thirdColor: string;
}

export const LineDivider = styled.div`
    height: 2px;
    width: 100%;
    background: linear-gradient(
        to right,
        ${(props: DividerProps) => props.firstColor},
        ${(props: DividerProps) => props.secondColor},
        ${(props: DividerProps) => props.thirdColor}
    );
`;


export const VerticalLineDivider = styled.div`
    height: 100%;
    width: 2px;
    background: linear-gradient(
        to bottom,
        ${(props: DividerProps) => props.firstColor},
        ${(props: DividerProps) => props.secondColor},
        ${(props: DividerProps) => props.thirdColor}
    );
`;

export const LineVertDivider = styled.div`
    margin-left: 5px;
    margin-right: 5px;
    height: 50px;
    width: 2px;
    background: linear-gradient(
        to bottom,
        ${(props: DividerProps) => props.firstColor},
        ${(props: DividerProps) => props.secondColor},
        ${(props: DividerProps) => props.thirdColor}
    );
`;

export const RoundButton = styled.button`
    z-index: 20;
    position: fixed;
    border-radius: 50%;
    box-shadow: 0px 4px 4px ${boxShadow};
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    cursor: pointer;
    border: none;
`;

interface WrapperTitleProps {
    color: string;
    background: string;
}

export const WrapperTitle = styled.div`
    width: 100%; 
    text-align: center; 
    border-bottom: 1px solid ${(props: WrapperTitleProps) => (props.color === 'white') ? '#ffffff':'#000'}; 
    line-height: 0.1em;
    margin: 40px 0 10px;
    background: ${(props: WrapperTitleProps) => (props.color === 'white') ? '#ffffff':'#000'}; 
    font-size: 50px;

    span { 
        background: ${(props: WrapperTitleProps) => props.background};
        padding: 0 30px;
        
    }
`;

export const BuyGraphRow = styled(Row)`
    @media (max-width: 1100px) {
        flex-wrap: wrap;
    }
`;

export const ModalMakeOrderButton = styled(Button)`
    background-color: #0085FF;

    &:before {
        
    }

    &:hover::before {
        
    }

    &:after {

    }

    &:hover::after {

    }
`;

export const FooterLayoutBrowser = styled.div`
    justify-content: center;
    display: grid;
    grid-template-columns: 1.5fr 0.5fr 0.5fr 1.5fr;
    grid-gap: 0px;
`;

export const FooterLayoutTablet = styled.div`
    justify-content: center;
    display: grid;
    grid-template-columns: 0.9fr 0.9fr 1.2fr;
    grid-gap: 0px;
`;

export const FooterLayoutMobile = styled.div`
    justify-content: center;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0px;
`;

export const QuickLink = styled.p`
    background-image: linear-gradient(
        to right,
        #0085FF,
        #0085FF 50%,
        #000 50%
    );
    background-size: 200% 100%;
    background-position: -100%;
    display: inline-block;
    padding: 5px 0;
    position: relative;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: all 0.3s ease-in-out;

    &:before{
        content: '';
        background: #0085FF;
        display: block;
        position: absolute;
        bottom: -3px;
        left: 0;
        width: 0;
        height: 3px;
        transition: all 0.3s ease-in-out;
    }

    &:hover {
        background-position: 0;
    }

    &:hover::before{
        width: 100%;
    }
`;


export const FormGroup = styled.div`
    position: relative;
    width: 50%;
    padding-right: 3em
`;

// export const FormField = styled.input`
//     font-family: inherit;
//     width: 100%;
//     border: 0;
//     border-bottom: 2px solid #9b9b9b;
//     outline: 0;
//     font-size: 0.8rem;
//     color: #000;
//     padding: 7px 0;
//     background: transparent;
//     transition: border-color 0.2s;

//     &::placeholder {
//         color: transparent;
//     }

//     &:placeholder-shown ~ label {
//         font-size: 0.8rem;
//         cursor: text;
//         top: 20px;
//     }

//     &:focus {
//         ~ label {
//         position: absolute;
//         top: 0;
//         display: block;
//         transition: 0.2s;
//         font-size: 0.8rem;
//         color: rgb(113, 171, 221);
//         font-weight: 700;
//         }
//         padding-bottom: 6px;
//         font-weight: 700;
//         border-width: 3px;
//         border-image: linear-gradient(to right, rgb(113, 171, 221), rgb(78,83,156));
//         border-image-slice: 1;
//     }

//     /* reset input */
//     &:required,
//     &:invalid {
//         box-shadow: none;
//     }
// `;
export const FormField = styled.input`
    font-family: "IBM Plex Mono", Helvetica;
    width: 100%;
    border: 2px solid #9b9b9b;
    outline: 0;
    font-size: 1.0rem;
    color: #000;
    padding: 7px;
    padding-left: 20px;
    background: white;
    transition: border-color 0.3s;

    &::placeholder {
        margin-left: 20px;
    }

    &:placeholder-shown ~ label {
        font-size: 0.8rem;
        cursor: text;
    }

    &:focus {
        ~ label {
        position: absolute;
        top: 0;
        display: block;
        transition: 0.2s;
        font-size: 0.8rem;
        color: rgb(113, 171, 221);
        font-weight: 400;
        }
        padding: 6px;
        padding-left: 20px;
        font-weight: 300;
        border: 3px solid rgb(113, 171, 221);
        border-image-slice: 1;
    }

    /* reset input */
    &:required,
    &:invalid {
        box-shadow: none;
    }
`;

export const FormLabel = styled.label`
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 0.8rem;
    color: #9b9b9b;
`;


export const AnimatedSwaphori = styled(SwapHorizIcon)`
    animation: bounce 5s ease infinite;
    animation-delay: 2s;

    @keyframes bounce {
        0%   { transform: scale(1,1)    translateY(0); }
        3%  { transform: scale(1.1,.9) translateY(0); }
        10%  { transform: scale(.9,1.1) translateY(-10px); }
        18%  { transform: scale(1,1)    translateY(0); }
        36% { transform: scale(1,1)    translateY(0); }
        100% { transform: scale(1,1)    translateY(0); }
    }
`;

export const AnimatedCloseIcon = styled(CloseIcon)`
    transition: 0.3s;

    &:hover {
        transition: 0.3s;
        transform: rotate(90deg);
    }
    
`;

export const StatisticContainer = styled.div`
  padding: 0rem 4rem 1rem;
  margin: 0 auto;
  max-width: 100%;
  
  @media (min-width: 640px) {
    max-width: 640px;
  }
  
  @media (min-width: 768px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  
  @media (min-width: 1024px) {
    padding-left: 8rem;
    padding-right: 8rem;
  }
  
  @media (min-width: 1280px) {
    padding-left: 8rem;
    padding-right: 8rem;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
`;

export const StatisticGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  row-gap: 1rem;
  justify-content: center;
  
  @media (min-width: 768px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @media (min-width: 1000px) {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
`;

export const StatisticItem = styled.div`
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
  
  @media (min-width: 768px) {
    border-right: 1px solid #ffc000;
  }
`;

export const StatisticItem2 = styled.div`
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
`;

export const StatisticItem3 = styled.div`
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;

  @media (min-width: 1000px) {
    border-right: 1px solid #ffc000;
  }
`;

export const StatisticNumber = styled.h6`
    font-size: 1.5rem;
    font-weight: bold;

    @media (min-width: 1024px) {
    font-size: 1.5rem;
    }

    @media (min-width: 1280px) {
    font-size: 1.5rem;
    }
`;

export const StatisticLabel = styled.p`
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.075em;
  color: #ffc000;
    
  @media (min-width: 768px) {
    font-size: 1rem;
  }
`;

export const NewsWrapper = styled(Row)`
    display: flex;
    justify-content: center;
    align-items: center;
    
    @media (max-width: 1000px) {
        flex-wrap: wrap;
    }
`;

export const ModalImgContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 100%;
    max-height: 100%;
`;

interface SpinnerProps {
    background: string;
}

interface BottomGradientProps {
    background: string;
}

export const BottomGradient = styled.div`
    height: 90px;
    background: linear-gradient(180deg,
        transparent 0%, ${(props: BottomGradientProps) => props.background} 100%);
    };
    width: 100%;
    position: absolute;
    bottom: 18px;
    left: 0;
    @media ${device.mobile} {
        background: linear-gradient(180deg,
            transparent 0%, ${(props: BottomGradientProps) => props.background} 100%);
    }
`;

interface ModalHeaderProps {
    color: string;
}

interface ModalContainerProps {
    color: string;
    background: string;
}

export const ModalContainer = styled.div`
    z-index: 25;
    width: 70%;
    max-height: 70%;
    min-height: 100px;
    position: fixed;
    overflow: scroll;
    overflow-x: hidden;
    top: 150px;
    left: 14%;
    background: ${(props: ModalContainerProps) => props.background};
    border-radius: 14px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: 0.8s;
    color: ${(props: ModalContainerProps) => props.color};
    box-shadow: 0px 4px 6px ${boxShadow};
`;

export const MobileModalContainer = styled.div`
    z-index: 25;
    width: 89%;
    max-height: 70%;
    min-height: 100px;
    position: fixed;
    overflow: scroll;
    top: 150px;
    left: 5%;
    background: ${(props: ModalContainerProps) => props.background};
    border-radius: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: 0.8s;
    color: ${(props: ModalContainerProps) => props.color};
    box-shadow: 0px 4px 6px ${boxShadow};
`;

export const ModalHeader = styled.div`
    font-size: 40px;
    color: ${(props: ModalHeaderProps) => props.color};
    letter-spacing: 0.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 10px;
    padding-top: 10px;
`;

export const MobileModalHeader = styled.div`
    font-size: 26px;
    color: ${(props: ModalHeaderProps) => props.color};
    letter-spacing: 0.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 10px;
    text-align: center;
    margin-top: 10px;
`;

interface RadioGroupProps {
    selectedColor: string;
    selectedTextColor: string;
    textColor: string;
}

interface FormFieldContainerProps {
    background: string;
}

export const FormFieldContainer = styled.div`
    width: 100%;
    min-height: 37px;
    height: 40px;
    background: ${(props: FormFieldContainerProps) => props.background};
    border-radius: 8px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    overflow: hidden;
`;

interface FilterContainerProps {
    color: string;
    background: string;
}

export const FilterContainer = styled.div`
    z-index: 25;
    width: 35%;
    max-height: 60%;
    min-height: 300px;
    position: fixed;
    overflow: scroll;
    top: 120px;
    left: 62%;
    background: ${(props: FilterContainerProps) => props.background};
    border-radius: 7px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: 0.8s;
    color: ${(props: FilterContainerProps) => props.color};
    box-shadow: 5px 10px ${boxShadow};
`;

export const SortContainer = styled.div`
    z-index: 25;
    width: 16%;
    max-height: 60%;
    min-height: 310px;
    position: fixed;
    overflow: scroll;
    top: 130px;
    left: 81%;
    background: ${(props: FilterContainerProps) => props.background};
    border-radius: 12px;
    padding: 2px;
    border-thickness: 1px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: 0.8s;
    color: ${(props: FilterContainerProps) => props.color};
    box-shadow: 5px 10px ${boxShadow}
`;
export const MarketFilterContainer = styled.div`
    z-index: 25;
    width: 35%;
    height: '450px'
    position: fixed;
    overflow: scroll;
    top: 130px;
    left: 0%;
    background: ${(props: FilterContainerProps) => props.background};
    border-radius: 7px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: 0.8s;
    color: ${(props: FilterContainerProps) => props.color};
`;

export const MarketBuyContainer = styled.div`
    z-index: 25;
    width: 40%;
    height: '500px'
    position: fixed;
    overflow: scroll;
    top: 130px;
    background: ${(props: FilterContainerProps) => props.background};
    border-radius: 7px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: 0.8s;
    color: ${(props: FilterContainerProps) => props.color};
    box-shadow: 0px 4px 6px ${boxShadow};
`;

interface DotProps {
    background: string;
}

export const Dot = styled(Badge)`
    .MuiBadge-colorPrimary {
        background-color: ${(props: DotProps) => props.background};
    }
`;

export const CloseButton = styled.img`
    position: absolute;
    right: 17px;
    top: 17px;
    padding: 3px;
    cursor: pointer;
    height: 28px;
    transition: 0.3s;

    :active {
        opacity: 0.4;
    }
`;

export const EditButton = styled.img`
    position: absolute;
    right: 55px;
    top: 20px;
    cursor: pointer;
    height: 18px;
    transition: 0.3s;

    :active {
        opacity: 0.4;
    }
`;

export const mockValueData: HorseValueData[] = [
    { date: new Date(2019, 10, 15), price: 346 },
    { date: new Date(2019, 11, 17), price: 441 },
    { date: new Date(2020, 0, 16), price: 435 },
    { date: new Date(2020, 1, 21), price: 415 },
    { date: new Date(2020, 3, 25), price: 445 },
    { date: new Date(2020, 4, 29), price: 460 },
    { date: new Date(2020, 5, 20), price: 730 },
    { date: new Date(2020, 7, 5), price: 600 },
    { date: new Date(2020, 8, 14), price: 617 },
    { date: new Date(2020, 9, 30), price: 674 },
    { date: new Date(2020, 11, 21), price: 785 },
    { date: new Date(2021, 0, 8), price: 991 },
    { date: new Date(2021, 1, 9), price: 1028 },
    { date: new Date(2021, 2, 9), price: 1251 },
    { date: new Date(2021, 3, 6), price: 1103 },
    { date: new Date(2021, 4, 4), price: 1392 },
    { date: new Date(2021, 5, 11), price: 2747 },
    { date: new Date(2021, 6, 19), price: 2203 },
    { date: new Date(2021, 7, 18), price: 4114 },
    { date: new Date(2021, 8, 19), price: 3840 },
    { date: new Date(2021, 9, 19), price: 5591 },
    { date: new Date(2021, 10, 14), price: 6716 },
    { date: new Date(2021, 11, 22), price: 17905 },
    { date: new Date(2022, 0, 23), price: 11438 },
    { date: new Date(2022, 1, 10), price: 8125 },
    { date: new Date(2022, 2, 6), price: 11079 },
    { date: new Date(2022, 3, 7), price: 6913 },
    { date: new Date(2022, 4, 9), price: 9511 },
    { date: new Date(2022, 5, 6), price: 7553 },
    { date: new Date(2022, 6, 18), price: 7600 }
];

export const mockValueData2: HorseValueData[] = [
    { date: new Date(2019, 10, 15), price: 346 },
    { date: new Date(2019, 11, 17), price: 441 },
    { date: new Date(2020, 0, 16), price: 435 },
    { date: new Date(2020, 1, 21), price: 415 },
    { date: new Date(2020, 3, 25), price: 445 },
    { date: new Date(2020, 4, 29), price: 460 },
    { date: new Date(2020, 5, 20), price: 730 },
    { date: new Date(2020, 7, 5), price: 600 },
    { date: new Date(2020, 8, 14), price: 617 },
    { date: new Date(2020, 9, 30), price: 674 },
    { date: new Date(2020, 11, 21), price: 785 },
    { date: new Date(2021, 0, 8), price: 991 },
    { date: new Date(2021, 1, 9), price: 1028 },
    { date: new Date(2021, 2, 9), price: 1251 },
    { date: new Date(2021, 3, 6), price: 103 },
    { date: new Date(2021, 4, 4), price: 1392 },
    { date: new Date(2021, 5, 11), price: 2747 },
    { date: new Date(2021, 6, 19), price: 2203 },
    { date: new Date(2021, 7, 18), price: 114 },
    { date: new Date(2021, 8, 19), price: 3840 },
    { date: new Date(2021, 9, 19), price: 5591 },
    { date: new Date(2021, 10, 14), price: 6716 },
    { date: new Date(2021, 11, 22), price: 10905 },
    { date: new Date(2022, 0, 23), price: 11438 },
    { date: new Date(2022, 1, 10), price: 8125 },
    { date: new Date(2022, 2, 6), price: 11079 },
    { date: new Date(2022, 3, 7), price: 6913 },
    { date: new Date(2022, 4, 9), price: 9511 },
    { date: new Date(2022, 5, 6), price: 7553 },
    { date: new Date(2022, 6, 18), price: 6400 }
];

export const mockValueData3: HorseValueData[] = [
    { date: new Date(2019, 10, 15), price: 346 },
    { date: new Date(2019, 11, 17), price: 441 },
    { date: new Date(2020, 0, 16), price: 435 },
    { date: new Date(2020, 1, 21), price: 415 },
    { date: new Date(2020, 3, 25), price: 11945 },
    { date: new Date(2020, 4, 29), price: 460 },
    { date: new Date(2020, 5, 20), price: 730 },
    { date: new Date(2020, 7, 5), price: 600 },
    { date: new Date(2020, 8, 14), price: 617 },
    { date: new Date(2020, 9, 30), price: 674 },
    { date: new Date(2020, 11, 21), price: 785 },
    { date: new Date(2021, 0, 8), price: 991 },
    { date: new Date(2021, 1, 9), price: 1028 },
    { date: new Date(2021, 2, 9), price: 1951 },
    { date: new Date(2021, 3, 6), price: 11103 },
    { date: new Date(2021, 4, 4), price: 1392 },
    { date: new Date(2021, 5, 11), price: 2747 },
    { date: new Date(2021, 6, 19), price: 2203 },
    { date: new Date(2021, 7, 18), price: 4114 },
    { date: new Date(2021, 8, 19), price: 3840 },
    { date: new Date(2021, 9, 19), price: 5591 },
    { date: new Date(2021, 10, 14), price: 6716 },
    { date: new Date(2021, 11, 22), price: 17905 },
    { date: new Date(2022, 0, 23), price: 11438 },
    { date: new Date(2022, 1, 10), price: 8125 },
    { date: new Date(2022, 2, 6), price: 11079 },
    { date: new Date(2022, 3, 7), price: 913 },
    { date: new Date(2022, 4, 9), price: 9511 },
    { date: new Date(2022, 5, 6), price: 7553 },
    { date: new Date(2022, 6, 18), price: 9400 }
];

interface DivProps {
    text: string;
}

export const CenteredText = styled.div`
    width: 70%;
    flex-direction: column;
    text-align: center;
    display: flex;
    justify-content: center;
    color: ${(props: DivProps) => props.text};
    margin: 20;
    font-size: 100;
    font-family: Allerta;
    padding-left: 15%;
`;

export const CenteredText2 = styled.div`
    width: 100%;
    flex-direction: column;
    text-align: center;
    display: flex;
    justify-content: center;
    color: ${(props: DivProps) => props.text};
    margin: 20;
    font-size: 100;
    font-family: Allerta;
`


export const SpacedCenteredText = styled.div`
    width: 100%;
    flex-direction: column;
    text-align: center;
    display: flex;
    justify-content: center;
    color: ${(props: DivProps) => props.text};
    margin: 20;
    font-size: 100;
    font-family: Allerta;
    padding-left: 0%;
    padding-bottom: 10px;
`;
export const BasicListText = styled.div`
    font-family: Allerta;
    margin-bottom: 5px;
    margin-top: 5px;
    height: fit-content;
    z-index: inherit;
    width: fit-content;
    white-space: no-wrap;
`;
export const graphTooltip = styled.div`
    height: fit-content;
    width: fit-content;
    display: flex;
    flex-direction: column;
    z-index: 150;
`;

export const StyChart = styled(Chart)`
    width: 100%;
`;

interface MarketButtonProps {
    text: string;
    background: string;
}

export const MarketButton = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 66px;
    padding: 3px;
    height: fit-content;
    z-index: inherit;
    align-items: center;
    color: ${(props: MarketButtonProps) => props.text};
    background: ${(props: MarketButtonProps) => props.background};
    box-shadow: ${(props: MarketButtonProps) => props.background == "white" && `0px 1px 4px grey`};
    margin-top: -10px;
    border-radius: 4px;
    justify-content: center;
`;

const Nico = "https://tctrading.s3.amazonaws.com/utils/nico.png";

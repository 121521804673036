import React, { useEffect, useRef, useState } from "react";

// redux
import { useSelectFromRedux, useDispatchPromise } from "../../utils/_hooks";
import Paper from "@mui/material/Paper";



//components
import {
    Chart,
    ArgumentAxis,
    ValueAxis,
    LineSeries,
    Title,
  } from '@devexpress/dx-react-chart-material-ui';
import { styled } from "@mui/material/styles";
import { Animation, ArgumentScale } from '@devexpress/dx-react-chart';
import moment from "moment";
import { Horse,HorseValueData} from "../../types";
import { Button, ButtonGroup } from "@material-ui/core";
import { line, curveStep } from 'd3-shape';
import { StyChart } from ".";



export default (props: {currentData: Horse, currentName: string, height: number, width: number}) => {

    const currentData = props.currentData;
    const currentName = props.currentName;
    

    const [
        logo, 
        textColor, 
        background,
        text
    ] = useSelectFromRedux((state) => [
        state.color.scheme.logo, 
        state.color.scheme.text,
        state.color.scheme.background,
        state.color.scheme.text
    ]);


    const [filterRange, setFilterRange] = useState<'day' | 'week' | 'month' | 'year' | 'all'>('year')

    const filterData = () : void => {
        let earlyDate = moment()
        // setNoData(false)
        if (filterRange == 'day') {
            earlyDate.subtract(1, 'day')
        } else if (filterRange == 'week') {
            earlyDate.subtract(1, 'week')
        } else if (filterRange == 'month') {
            earlyDate.subtract(1, 'month')
        } else if (filterRange == 'year') {
            earlyDate.subtract(1, 'year')
        } else if (filterRange == 'all') {
            earlyDate = moment(0)
        }
        let newData = currentData.historical_price.filter((horse : any) => moment(horse.date).isAfter(earlyDate))
        if(newData.length==1){            
            newData = newData.map((data : any) => ({...data, date: new Date(data.date).getTime()}))
            .sort((a : any, b: any) => a.date - b.date);
            let temp : HorseValueData = {date: (newData[0].date as unknown as number +1)as unknown as Date, price: newData[0].price}
            newData.push(temp);
        } else if (newData.length==0){
            setNoData(true);
        } else {
            newData = newData.map((data : any) => ({...data, date: new Date(data.date).getTime()}))
            .sort((a : any, b: any) => a.date - b.date)
        }
        setCurrentFilteredData(newData);
    }

    const [currentFilteredData, setCurrentFilteredData] = useState<Horse['historical_price']>(currentData.historical_price);
    const [animate, setAnimation] = React.useState<Boolean>(false)
    const [noData, setNoData] = useState<Boolean>(currentFilteredData.length==0)

    useEffect(() => {
        setAnimation(false)

        filterData()
    }, [filterRange, currentData]);

    useEffect(()=> {
        setAnimation(true)
    }, [currentFilteredData])

    const ValueLabel = (props: any) => {
        const { text } = props;
        return (
          <ValueAxis.Label
            {...props}
            text={`$${text}`}
            style={{fill: textColor}}
          />
        );
      };
    
    const ArgLabel = (props: any) => {
        const { text } = props;
        const formattedText : string = text.replace(/,/g, '');
        const date = moment(Number.parseInt(formattedText)).startOf('month').fromNow();
        return (
            <ValueAxis.Label
                {...props}
                text={date}
                style={{fill: textColor}}
            />
        );
      }
    
    const TitleText = (props: any) => (
        <Title.Text {...props} sx={{ whiteSpace: 'pre', color: textColor }} />
      );

    const PREFIX = 'Demo';

    const classes = {
    chart: `${PREFIX}-chart`,
    };


    const Line = (props: any) => (
        <LineSeries.Path
          {...props}
          path={line()
            //@ts-ignore
            .x(({ arg }) => arg)
            //@ts-ignore
            .y(({ val }) => val)
            .curve(curveStep)}
        />
    );

    return (
        <Paper style={{background: background}}> 
            {animate && <StyChart
                data={currentFilteredData}
                height={props.height}
                width={props.width}
            >
                
                 <ValueAxis
                    labelComponent={ValueLabel}
                    position= 'right'
                />
                <LineSeries
                    name="Value"
                    valueField='price'
                    argumentField='date'
                    color={logo}
                    seriesComponent={Line}
                />
                {/* <Animation/> */}
            </StyChart>}
        </Paper>
    );
};

import { produce } from "immer";
import { payloadAction, actionFactory, ActionUnion } from "reductser";
import { BuyRequest, Horse, LeaderboardUser, NewsItem, SellRequest, Thread } from "../../types";

const actionMap = {
    setHorses: payloadAction<PublicDataState['horses']>(),
    // setNews: payloadAction<PublicDataState['news']>()
    setOrders: payloadAction<PublicDataState['orders']>(),
    setLeaderboard: payloadAction<PublicDataState['leaderboard']>(),
    setForum: payloadAction<PublicDataState['forum']>()
};

export const PublicDataActions = actionFactory(actionMap, "PublicData");

export type PublicDataAction = ActionUnion<typeof PublicDataActions>;

export interface PublicDataState {
    horses: Horse[],
    orders: {
        buy_requests: BuyRequest[],
        sell_requests: SellRequest[]
    },
    leaderboard: {
        board: LeaderboardUser[],
        place: number,
        value: number
    },
    forum: Thread[]
}

export const getInitialState = (): PublicDataState => ({
    horses: [],
    orders: {
        buy_requests: [],
        sell_requests: []
    },
    leaderboard: {
        board: [],
        place: -1,
        value: -1
    },
    forum: []
})

const colorReducer = (state = getInitialState(), action: PublicDataAction) =>
    produce(state, (draftState) => {
        if (action.reducer === "PublicData") {
            switch (action.type) {
                case "setHorses":
                    draftState.horses = action.payload;
                    break;
                case 'setOrders':
                    draftState.orders = action.payload;
                    break;
                case 'setLeaderboard':
                    draftState.leaderboard = action.payload;
                    break;
                case 'setForum':
                    draftState.forum = action.payload;
                    break;
                // case "setNews":
                //     draftState.news = action.payload;
                //     break;
                default:
                    break;
            }
        }
    });

export default colorReducer;
import React, { useEffect, useRef, useState } from "react";

// redux
import { useSelectFromRedux, useDispatchPromise } from "../../utils/_hooks";
import { useDispatch } from "react-redux";

//components
import { BasicListText, CenteredText, CenteredText2, Column, LineDivider, MobileModalContainer, MobileModalHeader, ModalContainer, ModalHeader, Row } from "../_shared";
import { Button } from "@material-ui/core";
import { NewsItem, RaceEvent } from "../../types";
import CloseIcon from '@mui/icons-material/Close';
import api from "../../api";
import { BrowserView, isMobileOnly, MobileView } from "react-device-detect";
import { debounce } from "lodash";
import ArticleThumbnail from "../_shared/articleThumbnail";
import ArticleModal from "../_shared/articleModal";
export default (_props: any) => {

    const [
        text,
        logo,
        background,
        horses
    ] = useSelectFromRedux((state) => [
        state.color.scheme.text,
        state.color.scheme.logo,
        state.color.scheme.background,
        state.publicData.horses
    ])

    const [data, setData] = useState<NewsItem[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            const response = await api.get('/public/getNews');
            setData(response.data);
        };

        fetchData().catch(console.error);
    }, [])


    const [modalContent, setModalContent] = useState<NewsItem | null>(null)


    const [windowSize, setWindowSize] = useState({
        windowWidth: window.outerWidth,
        windowHeight: window.outerHeight,
      });
    const [resized, setResized] = useState<Boolean>(window.outerWidth < 990)
    
      useEffect(() => {
        const handleResize = debounce(() => {
          setWindowSize({
            windowWidth: window.outerWidth,
            windowHeight: window.outerHeight,
          });
          setResized(window.outerWidth < 990);

        }, 250); // Adjust the debounce time (in milliseconds) as needed
        
        window.addEventListener('resize', handleResize);
        console.log(data);
        return () => {
          handleResize.cancel(); // Cancel any pending debounced calls
          window.removeEventListener('resize', handleResize);
        };
      }, []);

      const [articleContent, setArticleContent] = useState<RaceEvent | NewsItem | null>(null);


    return (
        <>
        {articleContent!=null && <ArticleModal article={articleContent} setModal={setArticleContent} modalOn={articleContent!=null}/>}
             <div style={{display: 'flex', flexDirection: 'column', filter: articleContent ? 'blur(5px)' : 'none', flexWrap:'wrap', zIndex: 4, alignItems:'center'}} onClick={() => {
                 if(articleContent) {
                     setArticleContent(null)
                 }
                 }}>
                 
                <Column style={{width:'80%', alignItems:'center', paddingTop: '90px'}}>                 
                {data.map((news, index) =>
                 <>
                 {index == 0 &&
                 <Column style={{zIndex: 4, background: 'transparent', width: '100%', flexWrap: 'wrap'}} key={news.id}>
                     <LineDivider 
                         firstColor={background} 
                         secondColor={logo} 
                         thirdColor={background} 
                         style={{marginBottom: '2%', width: '100%'}}
                     />
                    <ArticleThumbnail article={data[index]} setModal={setArticleContent} modalOn={articleContent!=null} mini={false}/>
                 </Column> }
                 {index != 0 && index % 2 == 1 && <>
                    <LineDivider 
                         firstColor={background} 
                         secondColor={logo} 
                         thirdColor={background} 
                         style={{ width: '100%', zIndex:3}}
                     />
                    <Row style={{zIndex: 4, background: 'transparent', flexWrap: 'nowrap', width: '100%', justifyContent:'space-evenly', paddingTop:'2%', flexDirection: isMobileOnly? 'column':'row'}} key={news.id}>
                    <>
                        <ArticleThumbnail article={data[index]} setModal={setArticleContent} modalOn={articleContent!=null} mini={true}/>
                        {isMobileOnly && 
                            <LineDivider 
                            firstColor={background} 
                            secondColor={logo} 
                            thirdColor={background} 
                            style={{ marginBottom:'10px',width: '100%', zIndex:3}}
                        />}
                        {data[index+1] && 
                        <ArticleThumbnail article={data[index+1]} setModal={setArticleContent} modalOn={articleContent!=null} mini={true}/>}

                    </>
                    </Row>
                 </>}
                 </>)}
                 </Column>
             </div> 
        </>
     )
};
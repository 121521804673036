import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useSelectFromRedux } from "../../utils/_hooks";
import { FilterContainer, MarketFilterContainer, ModalHeader, Row, Column, CenteredText, SpacedCenteredText, ModalContainer, LineDivider } from "../_shared";
import { Button, Slider, Box, FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { Horse, NewsItem, RaceEvent } from "../../types";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { debounce } from "lodash";
import { isMobileOnly } from "react-device-detect";


export default (props: {article: RaceEvent | NewsItem, setModal: Dispatch<SetStateAction<NewsItem| RaceEvent| null>>, modalOn: boolean}) => {

    const art = props.article;
    const setArticleContent = props.setModal;
    const modalOn = props.modalOn;

    const [
        logo, 
        textColor, 
        background,
        myAssetData,
        horses,
        user,
        colorMode
    ] = useSelectFromRedux((state) => [
        state.color.scheme.logo, 
        state.color.scheme.text,
        state.color.scheme.background,
        state.user.myAssetData,
        state.publicData.horses,
        state.user.user,
        state.color.mode
    ]);

    // const [fullArticle, showFullArticle] = React.useState<Boolean>(false);
    const [windowSize, setWindowSize] = useState({
        windowWidth: window.outerWidth,
        windowHeight: window.outerHeight,
      });
    const [resized, setResized] = useState<Boolean>(window.outerWidth < 990)
    
      useEffect(() => {
        const handleResize = debounce(() => {
          setWindowSize({
            windowWidth: window.outerWidth,
            windowHeight: window.outerHeight,
          });
          setResized(window.outerWidth < 990);

        }, 250); // Adjust the debounce time (in milliseconds) as needed
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          handleResize.cancel(); // Cancel any pending debounced calls
          window.removeEventListener('resize', handleResize);
        };
      }, []);

    //   const showArticle = (bool: boolean) => {
    //     showFullArticle(bool);
    //     if(bool){
    //         setArticleContent(art)
    //         console.log(bool, art, isMobileOnly)
    //     }
    //   }

    return (
        <ModalContainer color={textColor} background={background} style={{borderColor: logo, borderWidth: '5px', borderStyle: 'solid', width:'80%', left: isMobileOnly? '5%':'6%', padding: '4%', paddingBottom:'0px', paddingTop:'20px', zIndex:25, background: background}}>
            <CloseIcon style={{top: '15x', right: '8%', position: 'fixed', cursor: 'pointer'}} onClick={() => {
                setArticleContent(null)
                console.log("TEST")

                }}/>
            <ModalHeader color={logo} style={{width: '70%', textAlign: 'center',fontSize: art.title.length>45&&(isMobileOnly || resized)?'18px':''}}>
                {art.title}
            </ModalHeader>
            <Column style={{alignItems: 'center', width: '100%'}}>
                <LineDivider 
                    firstColor={background} 
                    secondColor={logo} 
                    thirdColor={background} 
                    style={{ width: '80%', marginBottom: '20px'}}
                />
                <div style={{width:'100%', display: windowSize.windowWidth>500 ? 'block':'flex', flexDirection:'column', alignItems:'center'}}>
                    <img src={art.photo} style={{maxHeight: '300px', marginBottom: '30px', maxWidth:isMobileOnly?'312px':resized? windowSize.windowWidth*.8 : '700px', float: windowSize.windowWidth>500 ? 'left': 'none', marginRight: windowSize.windowWidth>500 ? '10px':'0px'}}/>
                    {art.text.split('\n').map((line) => (
                        <div key={line} style={{ marginBottom: '10px'}}>
                            {line}
                            
                        </div>
                    ))}
                </div>
                <CenteredText text={logo} style={{paddingLeft: 0}}>
                    Horses Tagged
                </CenteredText>
                {horses.filter((horse) => Object.keys(art.horses).length > 0 && art.horses.includes(horse.id)).map((horse) => (
                        <CenteredText text={textColor} style={{paddingLeft: 0}} key={horse.name}>
                            {horse.name}
                        </CenteredText>
                ))}
            </Column>
        </ModalContainer>
    )
}
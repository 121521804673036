import React from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import { useSelectFromRedux } from "../../utils/_hooks";

export default (_props: any) => {
    const particlesInit = async (main: any) => {
        await loadFull(main);
    };

    //@ts-ignore
    const particlesLoaded = (container: any): Promise<void> => {};

    const [background, text, logo] = useSelectFromRedux((state) => [
        state.color.scheme.background,
        state.color.scheme.text,
        state.color.scheme.logo
    ]);

    return (
        <Particles
            style={{ zIndex: 0 }}
            id="tsparticles"
            init={particlesInit}
            loaded={particlesLoaded}
            options={{
                background: {
                    color: {
                        value: background
                    }
                },
                fpsLimit: 120,
                interactivity: {
                    events: {
                        // onClick: {
                        //     enable: true,
                        //     mode: 'push',
                        // },
                        onHover: {
                            enable: true,
                            mode: "repulse"
                        },
                        resize: true,
                        onDiv: {
                            selectors: "#repulse-div",
                            enable: true,
                            mode: "repulse"
                        }
                    },
                    modes: {
                        push: {
                            quantity: 4
                        },
                        repulse: {
                            distance: 100,
                            duration: 0.4
                        },
                        // attract: {
                        //     distance: 200,
                        //     duration: 0.4,
                        //     factor: 1,
                        //     maxSpeed: 3,
                        //     speed: 1
                        // },
                        bounce: {
                            distance: 200
                        },
                        bubble: {
                            distance: 400,
                            duration: 2,
                            mix: false,
                            opacity: 0.8,
                            size: 40,
                            divs: {
                                distance: 200,
                                duration: 0.4,
                                mix: false,
                                selectors: []
                            }
                        }
                    }
                },
                connect: {
                    distance: 80,
                    links: {
                        opacity: 0.5
                    },
                    radius: 60
                },
                grab: {
                    distance: 400,
                    links: {
                        blink: false,
                        consent: false,
                        opacity: 1
                    }
                },
                particles: {
                    // life: {
                    //     count: 1,
                    //     duration: {
                    //         random: true,
                    //         value: 200
                    //     },
                    //     delay: {
                    //         random: true,
                    //         value: 100
                    //     }
                    // },
                    color: {
                        value: logo
                    },
                    // stroke: {
                    //     width: 10,
                    //     color: "white"
                    // },
                    collisions: {
                        enable: false
                    },
                    links: {
                        blink: false,
                        color: {
                            value: logo
                        },
                        consent: false,
                        distance: 150,
                        enable: true,
                        frequency: 1,
                        opacity: 0.5,
                        shadow: {
                            blur: 5,
                            color: {
                                value: "#000"
                            },
                            enable: false
                        }
                    },
                    move: {
                        direction: "none",
                        enable: true,
                        outModes: {
                            default: "bounce"
                        },
                        random: true,
                        speed: 3,
                        straight: false,
                        warp: true,
                        // spin: {
                        //     // acceleration: 0.1,
                        //     enable: true
                        // }
                    },
                    number: {
                        density: {
                            enable: true,
                            area: 800
                        },
                        value: 80
                    },
                    opacity: {
                        value: 0.8
                    },
                    shape: {
                        // type: ['image', "character"],
                        // type: 'image',
                        // type: 'character',
                        type: 'line',
                        options: {
                            image: {
                                src: 'https://tctrading.s3.amazonaws.com/utils/logo.png'
                            },
                            character: {
                                value: ['t', 't', 'c'],
                                font: 'Allerta'
                            }
                        }
                    },
                    size: {
                        value: { min: 5, max: 10 }
                    }
                },
                detectRetina: true
            }}
        />
    );
};


import React, { useEffect, useRef, useState } from "react";
import {debounce} from 'lodash';

// redux
import { useSelectFromRedux, useDispatchPromise } from "../../utils/_hooks";

//components
import {
    CenteredText,
    VerticallyCenteredRow, 
    Column, 
    Row, 
    SpacedCenteredText, 
    ModalContainer, 
    ModalHeader, 
    WrapperTitle, 
    FilterContainer, 
    LineDivider, 
    StatisticContainer,
    StatisticGrid,
    StatisticItem,
    StatisticItem2,
    StatisticItem3,
    StatisticNumber,
    StatisticLabel,
    NewsWrapper,
    VerticallyCenteredRowFlexWrap,
    ModalImgContainer,
    AnimatedCloseIcon,
    BuyGraphRow,
    BasicListText, 
    CenteredText2, 
    MobileModalContainer,
    MobileModalHeader,
    ModalMakeOrderButton, 
 } from "../_shared";
import Graph from "../_shared/graph"
import { Horse, NewsItem, RaceEvent} from "../../types";
import { IconButton,  Tooltip, createTheme, InputAdornment } from "@material-ui/core";
import { Button, Slider, Box, FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import Buy from "../_shared/buy";
import Filter from "./filter";
import api from "../../api";
import { BrowserView, MobileView } from "react-device-detect";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ArticleModal from "../_shared/articleModal";
import ArticleThumbnail from "../_shared/articleThumbnail";
import { isMobileOnly } from "react-device-detect";
import OfferingSort from "./OfferingSort";
import moment from "moment";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';


  

export default (props: any) => {
    


    const [modalContent, setModalContent] = useState<Horse | null>(null);
    const [articleContent, setArticleContent] = useState<RaceEvent | NewsItem | null>(null);

    const [filterMode, setFilter] = useState<Boolean>(false);
    const [sortMode, setSort] = useState<Boolean>(false)

    const showModal = (horse: Horse) => {
        setFilter(false);
        setModalContent(horse)
    }

    const [marketData, setMarketData] = useState<Number | null>(null);
    const toggleMarketData = (horse:Horse) => {
        if(marketData == horse.id) {
            setMarketData(null);
        } else {
            setMarketData(horse.id);
        }
    }

    const [
        text,
        logo,
        background,
        horses,
        colorMode,
        orders
    ] = useSelectFromRedux((state) => [
        state.color.scheme.text,
        state.color.scheme.logo,
        state.color.scheme.background,
        state.publicData.horses,
        state.color.mode,
        state.publicData.orders
    ]);

    const toggleEdit = () => {
        setFilter(!filterMode);
    };
    

    useEffect(() => {
        setHorseOfferings(horses)
    }, [horses])

    const [horseOfferings, setHorseOfferings] = React.useState<Horse[]>(horses);

    if(!horseOfferings) {
        return (
            <>
                Loading...
            </>
        )
    }

    const [news, setNews] = useState<NewsItem[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            const response = await api.get('/public/getNews');
            setNews(response.data);
        };

        fetchData().catch(console.error);
    }, [])

    const [events, setEvents] = useState<RaceEvent[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            const response = await api.get('/public/getEvents');
            setEvents(response.data);
        };

        fetchData().catch(console.error);
    }, []);
    const [windowSize, setWindowSize] = useState({
        windowWidth: window.outerWidth,
        windowHeight: window.outerHeight,
      });
    const [resized, setResized] = useState<Boolean>(window.outerWidth < 990)
    
      useEffect(() => {
        const handleResize = debounce(() => {
          setWindowSize({
            windowWidth: window.outerWidth,
            windowHeight: window.outerHeight,
          });
          setResized(window.outerWidth < 990);

        }, 250); // Adjust the debounce time (in milliseconds) as needed
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          handleResize.cancel(); // Cancel any pending debounced calls
          window.removeEventListener('resize', handleResize);
        };
      }, []);

      const getAge = (input : string) =>  {
          return moment().diff(input, 'years');
      }


      const [showFilter, setShowFilter] = useState<boolean>(false);
      const [colt, setColt] = useState<boolean>(false);
      const [mare, setMare] = useState<boolean>(false);
      const [filly, setFilly] = useState<boolean>(false);
      const [ridgling, setRidgling] = useState<boolean>(false);
      const[sortType, setSortType] = useState<'capup' | 'capdown' | 'priceup' | 'pricedown' | null>(null);

      const CompareHorsesDown = (a : Horse, b: Horse) => {
          return a.price - b.price;
      }

      const CompareHorsesUp = (a : Horse, b: Horse) => {
        return b.price - a.price;
    }

      useEffect(() => {
        
        let newOfferings = horses;

        if(colt || mare || filly || ridgling) {
            if(!colt) {
                newOfferings = newOfferings.filter((horse) => horse.sex !== 'Colt')
            }
            if(!mare) {
                newOfferings = newOfferings.filter((horse) => horse.sex !== 'Mare')
            }
            if(!filly) {
                newOfferings = newOfferings.filter((horse) => horse.sex != 'Filly')
            }
            if(!ridgling) {
                newOfferings = newOfferings.filter((horse) => horse.sex != "Ridgling")
            }
        }

        let newOfferingsForSort = [...newOfferings]

        if(sortType == 'capup' || sortType == 'priceup') {
            newOfferingsForSort.sort(CompareHorsesUp);
        } else if(sortType == 'capdown' || sortType == 'pricedown') {
            newOfferingsForSort.sort(CompareHorsesDown);
        }

        

        setHorseOfferings(newOfferingsForSort);

      }, [colt, mare, filly, sortType])



    return (
       //Modal
       <>
         <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1094097634004455"
                    crossOrigin="anonymous"></script>
            {modalContent && 
            <>
            <BrowserView>
                <ModalContainer color={text} background={background} style={{borderColor: logo, borderWidth: '5px', borderStyle: 'solid'}} >
                    <AnimatedCloseIcon style={{top: '15x', right: '15px', position: 'absolute', cursor: 'pointer'}} onClick={() => setModalContent(null)}/>
                    <WrapperTitle color={text} background={background}>
                        <span>
                            {modalContent.name}
                        </span>
                    </WrapperTitle>         
                    <Column>
                    <ModalHeader color={logo} style={{fontSize:'1.2em', paddingBottom: '0px', flexWrap: "wrap"}}>
                        Value of a 0.1% Share: <div style={{marginLeft: '5px', fontSize:'1.4em', padding:"10px 10px 10px 10px"}}> ${modalContent.price.toFixed(2)}</div>
                    </ModalHeader>
                    <LineDivider 
                        firstColor={background} 
                        secondColor={logo} 
                        thirdColor={background} 
                        style={{marginBottom: '5%', width: '100%'}}
                    />
                    </Column>
                        
                    <BuyGraphRow style={{width: '100%', justifyContent:"center", gap:"40px"}}>
                        <Column>
                            <div style={{paddingTop: '50px'}}>
                                <Buy horse={modalContent} width={windowSize.windowWidth}/>
                            </div>
                        </Column>
                        
                        <Column >
                            <div style={{fontSize:'20px', alignItems: 'center', textAlign: 'center', paddingBottom: '10px', color:'#ffc000'}}> Historical Prices </div>
                            <div style={{zIndex: 0, maxWidth: "100%"}}>
                                <Graph currentData={modalContent} currentName={""} height={400} width={350}/>
                            </div>
                        </Column>
                        
                    </BuyGraphRow>
                    {orders.buy_requests.filter((order) => order.horse_id == modalContent.id).length > 0 &&
                    <ModalHeader color={logo} style={{fontSize:'20px', paddingTop:'20px', paddingBottom: '0px'}}>
                        Maximum Buy Request: {orders.buy_requests.filter((order) => order.horse_id == modalContent.id).sort((a,b) => b.price-a.price)[0].price}
                    </ModalHeader>}
                    {orders.sell_requests.filter((order) => order.horse_id == modalContent.id).length > 0 &&
                    <ModalHeader color={logo} style={{fontSize:'20px', paddingBottom: '20px'}}>
                        Minimum Sell Request: {orders.sell_requests.filter((order) => order.horse_id == modalContent.id).sort((a,b) => a.price-b.price)[0].price}
                    </ModalHeader>}
                    <Column style={{alignItems: 'center', width: '100%'}}>
                        <VerticallyCenteredRowFlexWrap style={{width: '100%'}}>
                            <ModalImgContainer style={{width: '60%'}}>
                                <img src={modalContent.photo} style={{marginBottom: '1em', marginTop: '0.8em'}}/>
                            </ModalImgContainer >
                            <Column style={{width: '50%', alignItems:'center'}}>
                                <h3 style={{fontSize: '100%'}}>
                                    <strong style={{display: 'block', color: logo, fontSize: '70%'}}>Sire</strong>
                                    {modalContent.sire}
                                    <strong style={{display: 'block', color: logo, fontSize: '70%', marginTop: '20px'}}>Dam</strong>
                                    {modalContent.dam}
                                    <strong style={{display: 'block', color: logo, fontSize: '70%', marginTop: '20px'}}>Dam Sire</strong>
                                    {modalContent.damsire}
                                    <strong style={{display: 'block', color: logo, fontSize: '70%', marginTop: '20px'}}>Trainer</strong>
                                    {modalContent.trainer}
                                    <strong style={{display: 'block', color: logo, fontSize: '70%', marginTop: '20px'}}>Jockey</strong>
                                    {modalContent.jockey}
                                </h3>
                            </Column>
                            <Column style={{width: '50%', alignItems:'center'}}>
                                <h3 style={{fontSize: '100%'}}>
                                    <strong style={{display: 'block', color: logo, fontSize: '70%'}}>Sex</strong>
                                    {modalContent.sex}
                                    <strong style={{display: 'block', color: logo, fontSize: '70%', marginTop: '20px'}}>Purchase Price</strong>
                                    {modalContent.purchaseprice}
                                    <strong style={{display: 'block', color: logo, fontSize: '70%', marginTop: '20px'}}>Career Earnings</strong>
                                    {modalContent.careerearnings}
                                    <strong style={{display: 'block', color: logo, fontSize: '70%', marginTop: '20px'}}>Foaled</strong>
                                    {modalContent.foaled}
                                </h3>
                            </Column>
                        </VerticallyCenteredRowFlexWrap>
                        <div style={{paddingBottom: '20px'}} >
                            {modalContent.description} 
                        </div>
                        <div style={{fontSize:'20px', alignItems: 'center'}}> Historical Prices </div>
                        {news.filter((news) => Object.keys(news.horses).length > 0 && news.horses.includes(modalContent.id)).length > 0 &&
                            <div style={{fontSize:'20px', alignItems: 'center', marginTop: '40px', marginBottom: '20px'}}> News </div>
                        }
                        <Column style={{width: '90%'}}>
                            {news.filter((news) => Object.keys(news.horses).length > 0 && news.horses.includes(modalContent.id)).map((news) => 
                            <Column style={{zIndex: 4, background: 'transparent'}} key={news.id}>
                                <LineDivider 
                                    firstColor={background} 
                                    secondColor={logo} 
                                    thirdColor={background} 
                                    style={{marginBottom: '5%', width: '100%'}}
                                />
                                <NewsWrapper style={{color: text, marginBottom: '40px', alignItems: 'center'}} key={news.title}>
                                    <ModalImgContainer style={{marginRight:'1em', marginBottom: '1em'}}>
                                        <img src={news.photo} style={{height: '100%', width: '100%'}}/>
                                    </ModalImgContainer>
                                    
                                    <Column style={{maxWidth: '500px'}}>
                                        <CenteredText text={logo} style={{fontSize: '1.5em'}}>
                                            {news.title}
                                        </CenteredText>
                                        <div>
                                            {news.text.substring(0,300)}...
                                        </div>
                                        <Button 
                                            onClick={() => setArticleContent(news)} 
                                            color='inherit' 
                                            style={{color: logo, marginTop: '10px'}}
                                        >Read More</Button>
                                    </Column>
                                </NewsWrapper>                              
                            </Column>
                            )}
                        </Column>
                        {events.filter((event) => Object.keys(event.horses).length > 0 && event.horses.includes(modalContent.id)).length > 0 &&
                            <div style={{fontSize:'20px', alignItems: 'center', marginTop: '20px', marginBottom: '20px'}}> Upcoming Events </div>
                        }
                        <Column style={{width: '90%'}}>
                        {events.filter((event) => Object.keys(event.horses).length > 0 && event.horses.includes(modalContent.id)).map((event) => 
                            <Column style={{zIndex: 4, background: 'transparent'}} key={event.id}>
                                <LineDivider 
                                    firstColor={background} 
                                    secondColor={logo} 
                                    thirdColor={background} 
                                    style={{marginBottom: '5%', width: '100%'}}
                                />
                                <Row style={{color: text, marginBottom: '40px', flexWrap: 'wrap', justifyContent: 'center'}} key={event.title}>
                                    <ModalImgContainer>
                                        <img src={event.photo} style={{marginBottom: '30px', maxHeight: '10em'}}/>
                                    </ModalImgContainer>
                                    
                                    <Column>
                                        <CenteredText text={logo} style={{fontSize: '30px'}}>
                                            {event.title}
                                        </CenteredText>
                                        <CenteredText text={text} style={{marginBottom: '10px'}}>
                                            {event.location} | {event.date}
                                        </CenteredText>
                                        <div>
                                            {event.text.substring(0,200)}...
                                        </div>
                                        <Button 
                                            onClick={() => setArticleContent(event)} 
                                            color='inherit' 
                                            style={{color: logo, marginTop: '10px'}}
                                        >Read More</Button>
                                    </Column>
                                </Row>
                            </Column>
                            )}
                        </Column>
                    </Column>
                </ModalContainer>
            </BrowserView>
            <MobileView>
                <MobileModalContainer color={text} background={background} style={{borderColor: logo, borderWidth: '2px', borderStyle: 'solid'}} >
                    <AnimatedCloseIcon style={{top: '20px', right: '15px', position: 'absolute', cursor: 'pointer'}} onClick={() => setModalContent(null)}/>
                    <MobileModalHeader color={logo} style={{marginTop: '20px', marginBottom: '10px'}}>
                        {modalContent.name}
                    </MobileModalHeader>
                    <MobileModalHeader color={logo} style={{fontSize:'20px', paddingBottom: '10px'}}>
                        Value of a 0.1% Share: <div style={{marginLeft: '5px', fontSize:'24px'}}> ${modalContent.price}</div>
                    </MobileModalHeader>
                    <Buy horse={modalContent} width={windowSize.windowWidth}/>
                    {orders.buy_requests.filter((order) => order.horse_id == modalContent.id).sort((a,b) => b.price-a.price).length > 0 &&
                    <MobileModalHeader color={logo} style={{fontSize:'20px', paddingBottom: '5px', textAlign:'center'}}>
                        Maximum Buy Request: {orders.buy_requests.filter((order) => order.horse_id == modalContent.id).sort((a,b) => b.price-a.price)[0].price}
                    </MobileModalHeader>}
                    {orders.sell_requests.filter((order) => order.horse_id == modalContent.id).sort((a,b) => a.price-b.price).length > 0 &&
                    <MobileModalHeader color={logo} style={{fontSize:'20px', paddingBottom: '5px', textAlign:'center'}}>
                        Minimum Sell Request: {orders.sell_requests.filter((order) => order.horse_id == modalContent.id).sort((a,b) => a.price-b.price)[0].price}
                    </MobileModalHeader>}
                    <img src={modalContent.photo} style={{maxHeight: '250px', maxWidth: '300px',marginBottom: '35px'}}/>
                    <Column style={{alignItems: 'center', width: '100%'}}>
                        <VerticallyCenteredRow style={{width: '90%', paddingBottom: '20px'}}>
                            <Column style={{width: '100%', alignItems:'center'}}>
                                <SpacedCenteredText text={text}>Sex: {modalContent.sex}</SpacedCenteredText>
                                <SpacedCenteredText text={text}>Sire: {modalContent.sire}</SpacedCenteredText>
                                <SpacedCenteredText text={text}>Dam: {modalContent.dam}</SpacedCenteredText>
                                <SpacedCenteredText text={text}>Dam Sire: {modalContent.damsire}</SpacedCenteredText>
                            </Column >
                            <Column style={{width: '100%', alignItems:'center'}}>
                                <SpacedCenteredText text={text}>Trainer: {modalContent.trainer}</SpacedCenteredText>
                                <SpacedCenteredText text={text}>Jockey: {modalContent.jockey}</SpacedCenteredText>
                                <SpacedCenteredText text={text}>Purchase Price: {modalContent.purchaseprice}</SpacedCenteredText>
                                <SpacedCenteredText text={text}>Career Earnings: {modalContent.careerearnings}</SpacedCenteredText>
                                <SpacedCenteredText text={text}>Foaled: {modalContent.foaled}</SpacedCenteredText>
                            </Column>
                        </VerticallyCenteredRow>
                        <div style={{width: '90%', paddingBottom: '20px'}} >
                            {modalContent.description} 
                        </div>
                        <div style={{fontSize:'20px', alignItems: 'center'}}> Historical Prices </div>                                
                                <Graph currentData={modalContent} currentName={""} height={250} width={300}/>
                        {news.filter((news) => Object.keys(news.horses).length > 0 && news.horses.includes(modalContent.id)).length > 0 &&
                            <div style={{fontSize:'20px', alignItems: 'center', marginTop: '40px', marginBottom: '20px'}}> News </div>
                        }
                        <Column style={{width: '90%', alignItems:'center'}}>
                            {news.filter((news) => Object.keys(news.horses).length > 0 && news.horses.includes(modalContent.id)).map((news) => 
                            <Column style={{zIndex: 4, background: 'transparent', alignItems: 'center'}} key={news.id}>
                                <LineDivider 
                                    firstColor={background} 
                                    secondColor={logo} 
                                    thirdColor={background} 
                                    style={{marginBottom: '5%', width: '100%'}}
                                />
                                <ArticleThumbnail article={news} setModal={setArticleContent} modalOn={articleContent!=null} mini={false}/>

                            </Column>
                            )}
                        </Column>
                        {events.filter((event) => Object.keys(event.horses).length > 0 && event.horses.includes(modalContent.id)).length > 0 &&
                            <div style={{fontSize:'20px', alignItems: 'center', marginTop: '20px', marginBottom: '20px'}}> Upcoming Events </div>
                        }
                        <Column style={{width: '90%', alignItems:'center'}}>
                        {events.filter((event) => Object.keys(event.horses).length > 0 && event.horses.includes(modalContent.id)).map((event) => 
                            <Column style={{zIndex: 4, background: 'transparent'}} key={event.id}>
                                <LineDivider 
                                    firstColor={background} 
                                    secondColor={logo} 
                                    thirdColor={background} 
                                    style={{marginBottom: '5%', width: '100%'}}
                                />
                                <ArticleThumbnail article={event} setModal={setArticleContent} modalOn={articleContent!=null} mini={false}/>
                            </Column>
                            )}
                        </Column>
                    </Column>
                </MobileModalContainer>
            </MobileView>
            </>}
            {articleContent!=null && <ArticleModal article={articleContent} setModal={setArticleContent} modalOn={articleContent!=null} />}

            {window.outerWidth > 1090 ?
            <>
            {/* <Filter setHorseOfferings={setHorseOfferings} filterMode={filterMode} setFilter={setFilter}/>
            <OfferingSort setHorseOfferings = {setHorseOfferings} sortMode={sortMode} setSort = {setSort}/> */}


            <div style={{filter: modalContent ? 'blur(5px)' : 'none'}} onClick={() => {
                if(modalContent) {
                    setModalContent(null)
                }else if(articleContent){
                    setArticleContent(null)
                }
                else if(filterMode) {
                    setFilter(false)
                }
                if(sortMode) {
                    setSort(false)
                }
                }}
                >
                
                <div style={{fontFamily: "Libre Caslon Text", color: text, top: '100px', left: '50px', position: 'relative', fontSize: '72px'}}>
                    Current Offerings
                </div>

                <Button 
                    onClick={() => setShowFilter(!showFilter)}
                    color='inherit' 
                    style={{
                        color: text, 
                        borderStyle: 'solid', 
                        borderColor: '#D9D9D9', 
                        borderWidth: '1px', 
                        borderRadius: '20px', 
                        fontFamily: 'IBM Plex Mono', 
                        height: '30px', 
                        width: '100px', 
                        fontSize: '14px', 
                        whiteSpace: 'nowrap',
                        position: 'absolute',
                        right: '50px',
                        top: '150px'
                    }}
                    endIcon={showFilter ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                >Filter</Button>
                {showFilter && <Row style={{
                    position: 'absolute',
                    top: '220px',
                    right: '50px',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '70%'
                }}>
                    <div style={{color: text, fontFamily: 'IBM Plex Mono'}}>
                        SEX:
                    </div>
                    <Button 
                        onClick={() => setColt(!colt)}
                        color='inherit' style={{color: colt ? logo : text, borderStyle: 'solid', borderColor: '#D9D9D9', borderWidth: '1px', borderRadius: '20px', fontFamily: 'IBM Plex Mono', height: '25px', width: '80px', fontSize: '12px', whiteSpace: 'nowrap'}}
                    >Colt</Button>
                    <Button 
                        onClick={() => setMare(!mare)}
                        color='inherit' style={{color: mare ? logo : text, borderStyle: 'solid', borderColor: '#D9D9D9', borderWidth: '1px', borderRadius: '20px', fontFamily: 'IBM Plex Mono', height: '25px', width: '80px', fontSize: '12px', whiteSpace: 'nowrap'}}
                    >Mare</Button>
                    <Button 
                        onClick={() => setFilly(!filly)}
                        color='inherit' style={{color: filly ? logo : text, borderStyle: 'solid', borderColor: '#D9D9D9', borderWidth: '1px', borderRadius: '20px', fontFamily: 'IBM Plex Mono', height: '25px', width: '80px', fontSize: '12px', whiteSpace: 'nowrap'}}
                    >Filly</Button>
                    <Button 
                        onClick={() => setRidgling(!ridgling)}
                        color='inherit' style={{color: ridgling ? logo : text, borderStyle: 'solid', borderColor: '#D9D9D9', borderWidth: '1px', borderRadius: '20px', fontFamily: 'IBM Plex Mono', height: '25px', width: '100px', fontSize: '12px', whiteSpace: 'nowrap'}}
                    >Ridgling</Button>
                    <div style={{color: text, fontFamily: 'IBM Plex Mono'}}>
                        SORT BY:
                    </div>
                    <Button 
                        onClick={() => sortType !== 'capup' ? setSortType('capup') : setSortType(null)}
                        color='inherit' style={{color: sortType == 'capup' ? logo : text, fontFamily: 'IBM Plex Mono', height: '25px', width: '130px', fontSize: '14px', whiteSpace: 'nowrap'}}
                    >Market Cap ↑</Button>
                    <Button 
                        onClick={() => sortType !== 'capdown' ? setSortType('capdown') : setSortType(null)}
                        color='inherit' style={{color: sortType == 'capdown' ? logo : text, fontFamily: 'IBM Plex Mono', height: '25px', width: '130px', fontSize: '14px', whiteSpace: 'nowrap'}}
                    >Market Cap ↓</Button>
                    <Button 
                        onClick={() => sortType !== 'priceup' ? setSortType('priceup') : setSortType(null)}
                        color='inherit' style={{color: sortType == 'priceup' ? logo : text, fontFamily: 'IBM Plex Mono', height: '25px', width: '80px', fontSize: '14px', whiteSpace: 'nowrap'}}
                    >Price ↑</Button>
                    <Button 
                        onClick={() => sortType !== 'pricedown' ? setSortType('pricedown') : setSortType(null)}
                        color='inherit' style={{color: sortType == 'pricedown' ? logo : text, fontFamily: 'IBM Plex Mono', height: '25px', width: '80px', fontSize: '14px', whiteSpace: 'nowrap'}}
                    >Price ↓</Button>
                </Row>}
                <div style={{paddingLeft: '10%', marginTop: showFilter ? '70px' : '30px', marginBottom: '50px', paddingRight: '10%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center'}} >
                {horseOfferings.length == 0 &&
                    <CenteredText text={logo} style={{marginTop: '100px', paddingLeft: 0, fontSize: '20px'}}>
                        No Results
                    </CenteredText>
                }
                <div style={{marginBottom: '100px'}}/>
                {horseOfferings.map((horse) => 
                    <Row key={horse.id} style={{zIndex: 0, background: 'transparent', width:'95%', marginTop: '30px', paddingBottom: '30px', borderStyle: 'solid', borderColor: '#D9D9D9', borderRadius: '20px'}}>
                        <Column style={{justifyContent: 'flex-start', height: '100%', width: '30%'}}>
                        
                            <div style={{fontFamily: "Libre Caslon Text", color: text, marginLeft: '40px', marginTop: '40px', position: 'relative', fontSize: '40px'}}>
                                {horse.name}
                            </div>
                            <Row style={{fontFamily: "Libre Caslon Text", color: text, marginLeft: '40px', marginTop: '30px', position: 'relative', fontSize: '40px'}}>
                                ${horse.price}
                                <div style={{fontFamily: 'IBM Plex Mono', fontSize: '16px', marginLeft: '10px', alignSelf: 'center', color: '#575757'}}> PER 0.01% SHARE </div>
                            </Row>
                        
                            <img src={horse.photo} style={{ marginLeft: '40px', marginTop: '70px', maxHeight: '300px', width: '90%'}}/>
                        </Column>
                        <Column style={{justifyContent: 'flex-start', height: 'inherit', marginLeft: '40px', width: '70%'}}>
                            <Row style={{marginTop: '40px', width: '400px', justifyContent: 'space-between'}}>
                                <Column>
                                    <div style={{fontFamily: 'IBM Plex Mono', fontSize: '16px', marginLeft: '10px', alignSelf: 'left', color: '#575757'}}> SIRE </div>
                                    <div style={{fontFamily: 'Libre Caslon Text', fontSize: '16px', marginLeft: '10px', marginTop: '10px', alignSelf: 'left'}}> {horse.sire} </div>
                                </Column>
                                <Column>
                                    <div style={{fontFamily: 'IBM Plex Mono', fontSize: '16px', marginLeft: '10px', alignSelf: 'left', color: '#575757'}}> TRAINER</div>
                                    <div style={{fontFamily: 'Libre Caslon Text', fontSize: '16px', marginLeft: '10px', marginTop: '10px', alignSelf: 'left'}}> {horse.trainer} </div>
                                </Column>
                                <Column>
                                    <div style={{fontFamily: 'IBM Plex Mono', fontSize: '16px', marginLeft: '10px', alignSelf: 'left', color: '#575757'}}> AGE/SEX</div>
                                    <div style={{fontFamily: 'Libre Caslon Text', fontSize: '16px', marginLeft: '10px', marginTop: '10px', alignSelf: 'left'}}> {getAge(horse.foaled)}yo {horse.sex} </div>
                                </Column>
                            </Row>

                            <div style={{fontFamily: 'Libre Caslon Text', fontSize: '16px', marginLeft: '10px', marginTop: '30px', marginRight: '40px', alignSelf: 'left', width: '90%'}}> {horse.description.substr(0,300)}... </div>


                            <Row style={{marginTop: '30px'}}>

                                <Graph currentData={horse} currentName={""} height={300} width={.9*.7*.7*window.innerWidth-250}/>

                                <Column style={{marginTop: '30px', marginLeft: '10px', height: '300px', justifyContent: 'space-between'}}>

                                    <Row style={{alignContent: 'center', justifyContent: 'space-between', width: '250px'}}>
                                        <div style={{fontFamily: 'IBM Plex Mono', fontSize: '16px', marginLeft: '10px', alignSelf: 'center', color: '#575757'}}> Day Change </div>
                                        <div style={{fontFamily: 'Libre Caslon Text', fontSize: '16px', marginLeft: '10px', alignSelf: 'center',  color: "#36BC06"}}> +$3.24 (+4.30%) </div>
                                    </Row>

                                    <Row style={{alignContent: 'center', justifyContent: 'space-between', width: '250px'}}>
                                        <div style={{fontFamily: 'IBM Plex Mono', fontSize: '16px', marginLeft: '10px', alignSelf: 'center', color: '#575757'}}> Market Cap </div>
                                        <div style={{fontFamily: 'Libre Caslon Text', fontSize: '16px', marginLeft: '10px', alignSelf: 'center'}}> ${horse.price*1000} </div>
                                    </Row>

                                    <Row style={{alignContent: 'center', justifyContent: 'space-between', width: '250px'}}>
                                        <div style={{fontFamily: 'IBM Plex Mono', fontSize: '16px', marginLeft: '10px', alignSelf: 'center', color: '#575757'}}> Earnings </div>
                                        <div style={{fontFamily: 'Libre Caslon Text', fontSize: '16px', marginLeft: '10px', alignSelf: 'center'}}> {horse.careerearnings} </div>
                                    </Row>

                                    <Row style={{alignContent: 'center', justifyContent: 'space-between', width: '250px'}}>
                                        <div style={{fontFamily: 'IBM Plex Mono', fontSize: '16px', marginLeft: '10px', alignSelf: 'center', color: '#575757'}}> Day Volume </div>
                                        <div style={{fontFamily: 'Libre Caslon Text', fontSize: '16px', marginLeft: '10px', alignSelf: 'center'}}> $50,129 </div>
                                    </Row>

                                    <Row style={{alignContent: 'center', justifyContent: 'space-between', width: '250px'}}>
                                        <div style={{fontFamily: 'IBM Plex Mono', fontSize: '16px', marginLeft: '10px', alignSelf: 'center', color: '#575757'}}> AVG Volume </div>
                                        <div style={{fontFamily: 'Libre Caslon Text', fontSize: '16px', marginLeft: '10px', alignSelf: 'center'}}> $35,493 </div>
                                    </Row>

                                    <ModalMakeOrderButton
                                            style={{ alignSelf: "center", width: '150px', fontSize: '1em', color: 'white', fontFamily: "IBM Plex Mono", borderRadius: '20px', height: '34px'}}
                                            variant='contained'
                                            onClick={() => showModal(horse)}
                                    >
                                            Learn More
                                    </ModalMakeOrderButton>

                                
                                </Column>

                            </Row>

                        </Column>
                        {/* <BasicListText
                            style={{ flexWrap: 'nowrap', alignItems: 'center', fontSize: '50px', cursor: 'pointer', color: text, marginBottom:'-10px', marginTop:'-10px'}} 
                            onClick={() => showModal(horse)} 
                        >{horse.name}</BasicListText>
                        <BasicListText 
                            style={{ alignItems: 'center', fontSize: '30px', cursor: 'pointer', color: text}}
                            onClick={() => showModal(horse)}
                        >${horse.price.toFixed(2)}</BasicListText>
                        <VerticallyCenteredRow style={{gap: '0%',  color: text, flexDirection: resized ? 'column': 'row', alignItems:'center',zIndex: 'inherit',}}>
                            <Column style={{width: resized ? '100%':'450px', alignItems: 'center', paddingBottom: '10%',  flexDirection: 'column', paddingTop: '30px'}}>
                                        <VerticallyCenteredRow style={{width: '100%',  color: text, paddingTop:'10px', paddingBottom: '10px'}}>
                                        <Column style={{width: '100%', alignItems:'center'}}>
                                            <SpacedCenteredText text={text}>Sex: {horse.sex}</SpacedCenteredText>
                                            <SpacedCenteredText text={text}>Sire: {horse.sire}</SpacedCenteredText>
                                            <SpacedCenteredText text={text}>Dam: {horse.dam}</SpacedCenteredText>
                                            <SpacedCenteredText text={text}>Dam Sire: {horse.damsire}</SpacedCenteredText>
                                        </Column >
                                        <Column style={{width: '100%', alignItems:'center'}}>
                                            <SpacedCenteredText text={text}>Trainer: {horse.trainer}</SpacedCenteredText>
                                            <SpacedCenteredText text={text}>Jockey: {horse.jockey}</SpacedCenteredText>
                                            <SpacedCenteredText text={text}>Purchase Price: {horse.purchaseprice}</SpacedCenteredText>
                                            <SpacedCenteredText text={text}>Career Earnings: {horse.careerearnings}</SpacedCenteredText>
                                            <SpacedCenteredText text={text}>Foaled: {horse.foaled}</SpacedCenteredText>
                                        </Column>
                                        </VerticallyCenteredRow>
                                    
                                    <img src={horse.photo} style={{width: resized ? '380px':"380px", borderRadius: '10px', boxShadow: colorMode == 'light' ? `5px 5px 20px 10px #bebebe` : colorMode=='dark' ? `5px 5px 20px 10px black` : `0px 0px 0px gray`}}/>
                            </Column>
                            
                            <div style={{zIndex: 'inherit', marginBottom: '20px'}}>
                                <Graph currentData={horse} currentName={""} height={windowSize.windowWidth > 700 ? 450:280} width={windowSize.windowWidth > 700 ?500:400}/>
                            </div>
                        </VerticallyCenteredRow>
                        <Button 
                            onClick={() => showModal(horse)} 
                            color='inherit' 
                            style={{color: logo, marginBottom: '20px', marginTop:'20px'}}
                        >More info</Button> */}
                    </Row>
                )}
                </div>
                
            </div>
            </> : 
            <>
            <div style={{filter: modalContent ? 'blur(5px)' : 'none'}} onClick={() => {
                if(modalContent) {
                    setModalContent(null)
                }else if(articleContent){
                    setArticleContent(null)
                }
                else if(filterMode) {
                    setFilter(false)
                }
                if(sortMode) {
                    setSort(false)
                }
                }}
                >
                
                <div style={{fontFamily: "Libre Caslon Text", color: text, top: '110px', width: '100%', textAlign: 'center', position: 'relative', fontSize: '40px'}}>
                    Current Offerings
                </div>

                <Button 
                    onClick={() => setShowFilter(!showFilter)}
                    color='inherit' 
                    style={{
                        color: text, 
                        borderStyle: 'solid', 
                        borderColor: '#D9D9D9', 
                        borderWidth: '1px', 
                        borderRadius: '20px', 
                        fontFamily: 'IBM Plex Mono', 
                        height: '30px', 
                        width: '100px', 
                        fontSize: '14px', 
                        whiteSpace: 'nowrap',
                        position: 'absolute',
                        right: 'calc(50% - 50px)',
                        top: '180px'
                    }}
                    endIcon={showFilter ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                >Filter</Button>
                {showFilter && <Column style={{
                    position: 'absolute',
                    top: '220px',
                    justifyContent: 'space-between',
                    alignItems: 'left',
                    width: '100%',
                    paddingLeft: '20px',
                    paddingRight: '20px'
                }}>
                    <Row style={{justifyContent: 'space-between', width: '90%', marginBottom: '10px'}}>
                        <div style={{color: text, fontFamily: 'IBM Plex Mono'}}>
                            SEX:
                        </div>
                        <Button 
                            onClick={() => setColt(!colt)}
                            color='inherit' style={{color: colt ? logo : text, borderStyle: 'solid', borderColor: '#D9D9D9', borderWidth: '1px', borderRadius: '20px', fontFamily: 'IBM Plex Mono', height: '25px', width: '80px', fontSize: '12px', whiteSpace: 'nowrap'}}
                        >Colt</Button>
                        <Button 
                            onClick={() => setMare(!mare)}
                            color='inherit' style={{color: mare ? logo : text, borderStyle: 'solid', borderColor: '#D9D9D9', borderWidth: '1px', borderRadius: '20px', fontFamily: 'IBM Plex Mono', height: '25px', width: '80px', fontSize: '12px', whiteSpace: 'nowrap'}}
                        >Mare</Button>
                        <Button 
                            onClick={() => setFilly(!filly)}
                            color='inherit' style={{color: filly ? logo : text, borderStyle: 'solid', borderColor: '#D9D9D9', borderWidth: '1px', borderRadius: '20px', fontFamily: 'IBM Plex Mono', height: '25px', width: '80px', fontSize: '12px', whiteSpace: 'nowrap'}}
                        >Filly</Button>
                        <Button 
                            onClick={() => setRidgling(!ridgling)}
                            color='inherit' style={{color: ridgling ? logo : text, borderStyle: 'solid', borderColor: '#D9D9D9', borderWidth: '1px', borderRadius: '20px', fontFamily: 'IBM Plex Mono', height: '25px', width: '100px', fontSize: '12px', whiteSpace: 'nowrap'}}
                        >Ridgling</Button>
                    </Row>
                    <Row style={{justifyContent: 'space-between', width: '90%', marginBottom: '10px'}}>
                        <div style={{color: text, fontFamily: 'IBM Plex Mono', width: '120px'}}>
                            SORT BY:
                        </div>
                        <Row style={{flexWrap: 'wrap', justifyContent: 'space-evenly'}}>
                            <Button 
                                onClick={() => sortType !== 'capup' ? setSortType('capup') : setSortType(null)}
                                color='inherit' style={{color: sortType == 'capup' ? logo : text, fontFamily: 'IBM Plex Mono', height: '25px', width: '130px', fontSize: '14px', whiteSpace: 'nowrap'}}
                            >Market Cap ↑</Button>
                            <Button 
                                onClick={() => sortType !== 'capdown' ? setSortType('capdown') : setSortType(null)}
                                color='inherit' style={{color: sortType == 'capdown' ? logo : text, fontFamily: 'IBM Plex Mono', height: '25px', width: '130px', fontSize: '14px', whiteSpace: 'nowrap'}}
                            >Market Cap ↓</Button>
                            <Button 
                                onClick={() => sortType !== 'priceup' ? setSortType('priceup') : setSortType(null)}
                                color='inherit' style={{color: sortType == 'priceup' ? logo : text, fontFamily: 'IBM Plex Mono', height: '25px', width: '80px', fontSize: '14px', whiteSpace: 'nowrap'}}
                            >Price ↑</Button>
                            <Button 
                                onClick={() => sortType !== 'pricedown' ? setSortType('pricedown') : setSortType(null)}
                                color='inherit' style={{color: sortType == 'pricedown' ? logo : text, fontFamily: 'IBM Plex Mono', height: '25px', width: '80px', fontSize: '14px', whiteSpace: 'nowrap'}}
                            >Price ↓</Button>
                        </Row>
                    </Row>
                </Column>}
                <div style={{paddingLeft: '5%', marginTop: showFilter ? '150px' : '60px', marginBottom: '50px', paddingRight: '5%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center'}} >
                {horseOfferings.length == 0 &&
                    <CenteredText text={logo} style={{marginTop: '100px', paddingLeft: 0, fontSize: '20px'}}>
                        No Results
                    </CenteredText>
                }
                <div style={{marginBottom: '50px', marginTop: '50px'}}/>
                {horseOfferings.map((horse) => 
                    <Column key={horse.id} style={{zIndex: 0, background: 'transparent', width:'98%', marginTop: '30px', paddingBottom: '30px', borderStyle: 'solid', borderColor: '#D9D9D9', borderRadius: '20px'}}>
                        <Row style={{justifyContent: 'space-between', height: '100%', width: '95%'}}>
                        
                            <Column style={{width: '50%'}}>
                                <div style={{fontFamily: "Libre Caslon Text", color: text, marginLeft: '20px', marginTop: '25px', position: 'relative', fontSize: '28px'}}>
                                    {horse.name}
                                </div>
                                <div style={{fontFamily: "Libre Caslon Text", color: text, marginLeft: '20px', marginTop: '10px', position: 'relative', fontSize: '24px'}}>
                                    ${horse.price}
                                </div>
                                <div style={{fontFamily: 'IBM Plex Mono', marginLeft: '20px', fontSize: '12px', marginTop: '10px', color: '#575757'}}> PER 0.01% SHARE </div>
                            </Column>
                        
                            <img src={horse.photo} style={{ marginTop: '25px', height: 'auto', width: '45%'}}/>
                        </Row>
                        <Row style={{marginTop: '20px', width: '90%', justifyContent: 'space-between', paddingLeft: '20px', paddingRight: '20px'}}>
                            <Column>
                                <div style={{fontFamily: 'IBM Plex Mono', fontSize: '16px', alignSelf: 'left', color: '#575757'}}> SIRE </div>
                                <div style={{fontFamily: 'Libre Caslon Text', fontSize: '16px',  marginTop: '10px', alignSelf: 'left'}}> {horse.sire} </div>
                            </Column>
                            <Column>
                                <div style={{fontFamily: 'IBM Plex Mono', fontSize: '16px', alignSelf: 'left', color: '#575757'}}> TRAINER</div>
                                <div style={{fontFamily: 'Libre Caslon Text', fontSize: '16px', marginTop: '10px', alignSelf: 'left'}}> {horse.trainer} </div>
                            </Column>
                            <Column>
                                <div style={{fontFamily: 'IBM Plex Mono', fontSize: '16px', alignSelf: 'right', color: '#575757'}}> AGE/SEX</div>
                                <div style={{fontFamily: 'Libre Caslon Text', fontSize: '16px',  marginTop: '10px', alignSelf: 'right'}}> {getAge(horse.foaled)}yo {horse.sex} </div>
                            </Column>
                        </Row>

                        <div style={{fontFamily: 'Libre Caslon Text', fontSize: '16px', marginLeft: '20px', marginTop: '30px', marginRight: '40px', alignSelf: 'left', width: '90%'}}> {horse.description.substr(0,300)}... </div>

                        <ModalMakeOrderButton
                                style={{ alignSelf: "center", width: '150px', fontSize: '1em', color: 'white', fontFamily: "IBM Plex Mono", borderRadius: '20px', height: '34px', marginTop: '20px'}}
                                variant='contained'
                                onClick={() => showModal(horse)}
                        >
                                Learn More
                        </ModalMakeOrderButton>

                        <Button 
                            onClick={() => toggleMarketData(horse)}
                            color='inherit' 
                            style={{
                                color: text,  
                                fontFamily: 'IBM Plex Mono', 
                                height: '30px', 
                                width: '100px',
                                left: 'calc(50% - 50px)', 
                                marginTop: '20px',
                                fontSize: '14px', 
                                whiteSpace: 'nowrap',
                            }}
                            endIcon={marketData == horse.id ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                        >Show Market Data</Button>


                        {marketData == horse.id &&

                        <Column style={{marginTop: '10px', marginLeft: '20px'}}>

                            <Graph currentData={horse} currentName={""} height={300} width={.8*window.innerWidth}/>

                            <Column style={{marginTop: '30px', height: '200px', justifyContent: 'space-between', marginRight: '20px'}}>

                                <Row style={{alignContent: 'center', justifyContent: 'space-between', width: '100%'}}>
                                    <div style={{fontFamily: 'IBM Plex Mono', fontSize: '16px', marginLeft: '10px', alignSelf: 'center', color: '#575757'}}> Day Change </div>
                                    <div style={{fontFamily: 'Libre Caslon Text', fontSize: '16px', marginLeft: '10px', alignSelf: 'center',  color: "#36BC06"}}> +$3.24 (+4.30%) </div>
                                </Row>

                                <Row style={{alignContent: 'center', justifyContent: 'space-between', width: '100%'}}>
                                    <div style={{fontFamily: 'IBM Plex Mono', fontSize: '16px', marginLeft: '10px', alignSelf: 'center', color: '#575757'}}> Market Cap </div>
                                    <div style={{fontFamily: 'Libre Caslon Text', fontSize: '16px', marginLeft: '10px', alignSelf: 'center'}}> ${horse.price*1000} </div>
                                </Row>

                                <Row style={{alignContent: 'center', justifyContent: 'space-between', width: '100%'}}>
                                    <div style={{fontFamily: 'IBM Plex Mono', fontSize: '16px', marginLeft: '10px', alignSelf: 'center', color: '#575757'}}> Earnings </div>
                                    <div style={{fontFamily: 'Libre Caslon Text', fontSize: '16px', marginLeft: '10px', alignSelf: 'center'}}> {horse.careerearnings} </div>
                                </Row>

                                <Row style={{alignContent: 'center', justifyContent: 'space-between', width: '100%'}}>
                                    <div style={{fontFamily: 'IBM Plex Mono', fontSize: '16px', marginLeft: '10px', alignSelf: 'center', color: '#575757'}}> Day Volume </div>
                                    <div style={{fontFamily: 'Libre Caslon Text', fontSize: '16px', marginLeft: '10px', alignSelf: 'center'}}> $50,129 </div>
                                </Row>

                                <Row style={{alignContent: 'center', justifyContent: 'space-between', width: '100%'}}>
                                    <div style={{fontFamily: 'IBM Plex Mono', fontSize: '16px', marginLeft: '10px', alignSelf: 'center', color: '#575757'}}> AVG Volume </div>
                                    <div style={{fontFamily: 'Libre Caslon Text', fontSize: '16px', marginLeft: '10px', alignSelf: 'center'}}> $35,493 </div>
                                </Row>

                            
                            </Column>

                        </Column>}

                    </Column>
                )}
                </div>
                
            </div>
            </>}
       
       </>
    )
};


import React, { useEffect, useRef, useState } from "react";

// redux
import { useSelectFromRedux, useDispatchPromise } from "../../utils/_hooks";
import { useDispatch } from "react-redux";

//components

import { CenteredText, VerticallyCenteredRow, Column, Row, SpacedCenteredText, ModalContainer, ModalHeader, FilterContainer, LineDivider, MobileModalContainer, MobileModalHeader, CenteredText2} from "../_shared";
import Graph from "../_shared/graph";
import Minigraph from "../_shared/minigraph";
import InvestmentValueGraph from "../_shared/investmentValueGraph"
import { Horse, UserAccount, Ownership, HorseValueData, NewsItem, RaceEvent, BuyRequest, SellRequest} from "../../types";
import { Button, Slider, Box, FormGroup, FormControlLabel, Checkbox, styled, Switch, SwitchProps } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import Buy from "../_shared/buy";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import api from "../../api";
import { debounce } from "lodash";
import { BrowserView, MobileView, isMobileOnly } from "react-device-detect";
import Article from "../_shared/articleThumbnail";
import MarketFilter from "../trade/marketFilter";
import ArticleModal from "../_shared/articleModal";
import ArticleThumbnail from "../_shared/articleThumbnail";
import HorseCalendar from "../_shared/calendar";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

export default (_props: any) => {

    const [
        text,
        logo,
        background,
        horses,
        user,
        myAssetData,
        orders,
        bgSecondary,
        colorMode,
        accent
    ] = useSelectFromRedux((state) => [
        state.color.scheme.text,
        state.color.scheme.logo,
        state.color.scheme.background,
        state.publicData.horses,
        state.user.user,
        state.user.myAssetData,
        state.publicData.orders,
        state.color.scheme.bgSecondary,
        state.color.mode,
        state.color.scheme.accent
    ]);
    
    if(!user) {
        return(
            <></>
        )
    }
    if(!horses) {
        return(
            <></>
        )
    }

    const [news, setNews] = useState<NewsItem[]>([]);

    const [events, setEvents] = useState<RaceEvent[]>([]);

    const [myOrders, setMyOrders] = useState<{buy_requests: BuyRequest[], sell_requests: SellRequest[]}>({buy_requests: [], sell_requests: []});

    const [showBuyCompleted, setShowBuyCompleted] = useState<boolean>(false);
    const [showSellCompleted, setShowSellCompleted] = useState<boolean>(false);
    

    useEffect(() => {
        const fetchData = async () => {
            const response1 = await api.get('/public/getEvents');
            setEvents(response1.data);
            const response2 = await api.get('/public/getNews');
            setNews(response2.data);
            const response3 = await api.post('/user/getMyOrders', {id: user.id});
            setMyOrders(response3.data.m);
        };

        fetchData().catch(console.error);
    }, [])


    const getMyHorses = (user: UserAccount): Horse[] => {
        if(Object.keys(user.ownerships).length === 0) {
            return []
        }
        const myOwnerships = user.ownerships;
        const myOwnershipIDS = myOwnerships.map((ownership) => ownership.horse_id);
        let myHorses: Horse[] = [];
        for (var horse of horses) {
            if(myOwnershipIDS.includes(horse.id)) {
                myHorses.push(horse);            
            }
        }
        return myHorses
    }


    const myAssets = getMyHorses(user);
    
    const [modalContent, setModalContent] = useState<Horse | null>(null);
    // const [newsModalContent, setNewsModalContent] = useState<RaceEvent | NewsItem | null>(null);
    // const [eventModalContent, setEventModalContent] = React.useState<NewsItem | RaceEvent | null>(null);
    const [articleContent, setArticleContent] = useState<RaceEvent | NewsItem | null>(null);

    const showModal = (horse: Horse) => {
        setFilter(false);
        setModalContent(horse)
    }

    const [filterMode, setFilter] = useState<Boolean>(false);

    const toggleEdit = () => {
        setFilter(!filterMode);
    };
    
    const [filterPrice, setFilterPrice] = React.useState<number[]>([0, 30000]);
    const handlePriceChange = (event: Event, newPrice: number | number[]) => {
        setFilterPrice(newPrice as number[]);
    };
    const checkPrice = (horse: Horse) : boolean =>{
        return horse.price >= filterPrice[0] && horse.price <= filterPrice[1]
    };

    const [filterSex, setFilterSex] = React.useState<boolean[]>([true, true, true]); //[Mare, Colt, Filly] ***setFilterSex is unused (I made handleSexChange which modifies state directly)***
    const handleSexChange = (event: any, checked: boolean) => {
        
        if(event.target.id == "Colt"){
            setFilterSex([!filterSex[0], filterSex[1], filterSex[2]])
        } else if (event.target.id == "Mare"){
            setFilterSex([filterSex[0], !filterSex[1], filterSex[2]])
        } else {
            setFilterSex([filterSex[0], filterSex[1], !filterSex[2]])
            };
    };
    const checkSex = (horse: Horse) : boolean =>{
        return horse.sex == 'Colt' && filterSex[0] || horse.sex == 'Mare' && filterSex[1] || horse.sex == 'Filly' && filterSex[2]
    };

    const [horseOfferings, setHorseOfferings] = React.useState<Horse[]>(myAssets);

    useEffect(() => {
        setHorseOfferings(myAssets)

    }, [horses]);

    useEffect(()=>{
        setOkayToRender(true)

    }, [myAssetData])

    const filterHorses = () => {
        let filtered: Horse[] = [];
        for(let horse of myAssets) {
            if(checkPrice(horse) && checkSex(horse)){
                filtered.push(horse);
            }
        }
        setHorseOfferings(filtered);
    }

    const resetFilter = () => {
        setFilter(false);
        
        setFilterPrice([0, 30000]);
        setFilterSex([true, true, true]);

        setFilter(true); 
        filterHorses();
    }

    const getOwnership = (horse: Horse): Ownership => {
        if(user != null){
            for(let ownership of user.ownerships){
                if(ownership.horse_id == horse.id) {
                    return ownership
                }
            }
        }
        return user.ownerships[0]
    }

    const getAmountInvested = (ownership: Ownership) : number => {
        let amtPaid: number = 0;
        for(let transaction of ownership.transactions){
            amtPaid += transaction.shares * transaction.price;
        }
        return amtPaid;
    }

    const getTotalAmountInvested = (): number => {
        let amount: number = 0;
        if(user != null && Object.keys(user.ownerships).length > 0){
            for(let horseOwnership of user.ownerships){
                amount += getAmountInvested(horseOwnership)
            }
        }
        return amount;
    }

    const getHorse = (horseID: number) : Horse | undefined => {
        if(myAssets) {
            for(let horse of myAssets){
                if(horse.id == horseID){
                    return horse
                }
            }
        }
        return undefined
    }

    const getTotalInvestmentValue = (): number => {
        let amount: number = 0;
        if(user != null && Object.keys(user.ownerships).length > 0){
            for(let horseOwnership of user.ownerships){
                amount += (getTotalShares(getHorse(horseOwnership.horse_id)) ?? 0) * (getHorse(horseOwnership.horse_id)?.price ?? 0)
            }
        }
        return amount;
    }

    

    const generalDelta = (initial: number, current: number) => { // copied and modified from ./trade's delta function
        const percent = ((current-initial)/initial*100).toFixed(0);
        if(current == initial) {
            return(
            <div style={{color: text}}>
                0%
            </div>)
        } else if (current > initial) {
            return (
                <Row>
                    <ArrowDropUp color='success'/>
                    <div style={{color: text}}>
                        {percent}%
                    </div>
                </Row>
            )
        } else {
            return (
                <Row >
                    <ArrowDropDown color='warning'/>
                    <div style={{color: text}}>
                        {percent}%
                    </div>
                </Row>
            )
        }
    }

    const CancelOrder = async (id: number, type : 'buy' | 'sell') => {
        const response = await api.post('/user/cancelOrder', {id: id, type: type});
        console.log(response)
        const response3 = await api.post('/user/getMyOrders', {id: user.id});
        setMyOrders(response3.data.m);
    }

    const getTotalShares = (horse: Horse | undefined): number | undefined => {
        if (!horse) {
            return undefined
        }
        let shareCount: number = 0;
        for(let transaction of getOwnership(horse).transactions){
            shareCount += transaction.shares;
        }
        return shareCount;
    }

    const [windowSize, setWindowSize] = useState({
        windowWidth: window.outerWidth,
        windowHeight: window.outerHeight,
      });
    const [resized, setResized] = useState<Boolean>(window.outerWidth < 990)
    const [cal, setCalendar] = useState<Boolean>(false);

    useEffect(() => {
    const handleResize = debounce(() => {
        setWindowSize({
        windowWidth: window.outerWidth,
        windowHeight: window.outerHeight,
        });
        setResized(window.outerWidth < 990);

    }, 250); // Adjust the debounce time (in milliseconds) as needed

    window.addEventListener('resize', handleResize);

    return () => {
        handleResize.cancel(); // Cancel any pending debounced calls
        window.removeEventListener('resize', handleResize);
    };
    }, []);
    const ref = useRef(null);

    const [okayToRender, setOkayToRender] = useState<boolean>(false);



    return (
       
       //Modal
       <>
       
       <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1094097634004455"
                    crossOrigin="anonymous"></script>
            <MarketFilter setSlider={null} filterMode={filterMode} setFilter={setFilter} setHorseOfferings={setHorseOfferings}/>
            {modalContent && <>
            <BrowserView>
            <ModalContainer color={text} background={background} style={{borderColor: logo, borderWidth: '5px', borderStyle: 'solid', width:'80%', left:'6%', padding: '4%', paddingBottom:'0px', paddingTop:'20px'}} >
                <CloseIcon style={{top: '15x', right: '8%', position: 'fixed', cursor: 'pointer'}} onClick={() => setModalContent(null)}/>
                <ModalHeader color={logo} style={{minHeight: '20px'}}>
                    {modalContent.name}
                </ModalHeader>
                <ModalHeader color={logo} style={{fontSize:'20px', paddingBottom: '0px'}}>
                    Value of a 0.1% Share: <div style={{marginLeft: '5px', fontSize:'30px'}}> ${modalContent.price}</div>
                </ModalHeader>
                <div style={{width: '40%'}}>
                    <Buy horse={modalContent} width={windowSize.windowWidth}/>
                </div>
                {orders.buy_requests.filter((order) => order.horse_id == modalContent.id).sort((a,b) => b.price-a.price).length > 0 &&
                <ModalHeader color={logo} style={{fontSize:'20px', paddingBottom: '0px', textAlign:'center'}}>
                    Maximum Buy Request: {orders.buy_requests.filter((order) => order.horse_id == modalContent.id).sort((a,b) => b.price-a.price)[0].price}
                </ModalHeader>}
                {orders.sell_requests.filter((order) => order.horse_id == modalContent.id).sort((a,b) => a.price-b.price).length > 0 &&
                <ModalHeader color={logo} style={{fontSize:'20px', paddingBottom: '0px', textAlign:'center'}}>
                    Minimum Sell Request: {orders.sell_requests.filter((order) => order.horse_id == modalContent.id).sort((a,b) => a.price-b.price)[0].price}
                </ModalHeader>}
                <img src={modalContent.photo} style={{height: '300px', marginBottom: '35px'}}/>
                <Column style={{alignItems: 'center', width: '100%'}}>
                    <VerticallyCenteredRow style={{width: '100%', paddingBottom: '20px'}}>
                    <Column style={{width: '100%', alignItems:'center'}}>
                                <SpacedCenteredText text={text}>Sire: {modalContent.sire}</SpacedCenteredText>
                                <SpacedCenteredText text={text}>Dam: {modalContent.dam}</SpacedCenteredText>
                                <SpacedCenteredText text={text}>Dam Sire: {modalContent.damsire}</SpacedCenteredText>
                            </Column >
                            <Column style={{width: '100%', alignItems:'center'}}>
                                <SpacedCenteredText text={text}>Sex: {modalContent.sex}</SpacedCenteredText>
                                <SpacedCenteredText text={text}>Trainer: {modalContent.trainer}</SpacedCenteredText>
                                <SpacedCenteredText text={text}>Jockey: {modalContent.jockey}</SpacedCenteredText>
                            </Column>
                            <Column style={{width: '100%', alignItems:'center'}}>
                                <SpacedCenteredText text={text}>Purchase Price: {modalContent.purchaseprice}</SpacedCenteredText>
                                <SpacedCenteredText text={text}>Career Earnings: {modalContent.careerearnings}</SpacedCenteredText>
                                <SpacedCenteredText text={text}>Foaled: {modalContent.foaled}</SpacedCenteredText>
                            </Column>
                    </VerticallyCenteredRow>
                    <div style={{paddingBottom: '20px'}} >
                        {modalContent.description} 
                    </div>
                    <div style={{fontSize:'20px', alignItems: 'center'}}> Historical Prices </div>
                    {/* <div style={{width: '500px', zIndex: 0, }}> */}
                            
                    <Graph currentData={modalContent} currentName={""} height={!resized ? 550 : windowSize.windowWidth * .65} width={!resized ? 600 : windowSize.windowWidth * .65}/>
                    {/* </div> */}
                    {news.filter((news) => Object.keys(news.horses).length > 0 && news.horses.includes(news.id)).length > 0 &&
                        <SpacedCenteredText text={text} style={{width:'fit-content', fontSize: '45px', background: background, zIndex:'inherit'}}>
                            News
                        </SpacedCenteredText>
                    }
                    <Column ref={ref} style={{width: '90%', zIndex: 0, alignItems:'center', marginBottom: '50px'}}>
                        {news.filter((news) => Object.keys(news.horses).length > 0 && news.horses.includes(news.id)).map((news) => 
                        <Column style={{background: background, alignItems:'center'}} key={news.id}>
                            <LineDivider 
                                firstColor={background} 
                                secondColor={logo} 
                                thirdColor={background} 
                                style={{marginBottom: '15px', width: '100%', zIndex: 'inherit'}}
                            />
                            <ArticleThumbnail article={news} setModal={setArticleContent} modalOn={articleContent!=null} mini={false}/>
                        </Column>)}
                    </Column>
                    {events.filter((event) => Object.keys(event.horses).length > 0 && event.horses.includes(event.id)).length > 0 &&
                        <SpacedCenteredText text={text} style={{width:'fit-content', fontSize: '45px', background: background}}>
                            Upcoming Events
                        </SpacedCenteredText>
                    }
                    <Column style={{width: '90%', alignItems:'center'}}>
                        {events.filter((event) => Object.keys(event.horses).length > 0 && event.horses.includes(event.id)).map((event) => 
                        <Column style={{background: background, alignItems:'center'}} key={event.id}>
                            <LineDivider 
                                firstColor={background} 
                                secondColor={logo} 
                                thirdColor={background} 
                                style={{marginBottom: '15px', width: '100%', zIndex: 'inherit'}}
                            />
                            <ArticleThumbnail article={event} setModal={setArticleContent} modalOn={articleContent!=null} mini={false}/>
                        </Column>)}
                    </Column>
                </Column>
            </ModalContainer>
            </BrowserView>
            <MobileView>
                <MobileModalContainer color={text} background={background} style={{borderColor: logo, borderWidth: '2px', borderStyle: 'solid'}} >
                    <CloseIcon style={{top: '20px', right: '15px', position: 'absolute', cursor: 'pointer'}} onClick={() => setModalContent(null)}/>
                    <MobileModalHeader color={logo} style={{marginTop: '20px', marginBottom: '10px'}}>
                        {modalContent.name}
                    </MobileModalHeader>
                    <MobileModalHeader color={logo} style={{fontSize:'20px', paddingBottom: '10px'}}>
                        Value of a 0.1% Share: <div style={{marginLeft: '5px', fontSize:'24px'}}> ${modalContent.price.toFixed(2)}</div>
                    </MobileModalHeader>
                    {/* <div style={{width: '40%'}}> */}
                        <Buy horse={modalContent} width={windowSize.windowWidth}/>
                    {/* </div> */}
                    {orders.buy_requests.filter((order) => order.horse_id == modalContent.id).sort((a,b) => b.price-a.price).length > 0 &&
                    <MobileModalHeader color={logo} style={{fontSize:'20px', paddingBottom: '5px', textAlign:'center'}}>
                        Maximum Buy Request: {orders.buy_requests.filter((order) => order.horse_id == modalContent.id).sort((a,b) => b.price-a.price)[0].price}
                    </MobileModalHeader>}
                    {orders.sell_requests.filter((order) => order.horse_id == modalContent.id).sort((a,b) => a.price-b.price).length > 0 &&
                    <MobileModalHeader color={logo} style={{fontSize:'20px', paddingBottom: '5px', textAlign:'center'}}>
                        Minimum Sell Request: {orders.sell_requests.filter((order) => order.horse_id == modalContent.id).sort((a,b) => a.price-b.price)[0].price}
                    </MobileModalHeader>}
                    <img src={modalContent.photo} style={{maxHeight: '250px', maxWidth: '300px',marginBottom: '35px'}}/>
                    <Column style={{alignItems: 'center', width: '100%'}}>
                        <VerticallyCenteredRow style={{width: '90%', paddingBottom: '20px'}}>
                            <Column style={{width: '100%', alignItems:'center'}}>
                                <SpacedCenteredText text={text}>Sex: {modalContent.sex}</SpacedCenteredText>
                                <SpacedCenteredText text={text}>Sire: {modalContent.sire}</SpacedCenteredText>
                                <SpacedCenteredText text={text}>Dam: {modalContent.dam}</SpacedCenteredText>
                                <SpacedCenteredText text={text}>Dam Sire: {modalContent.damsire}</SpacedCenteredText>
                            </Column >
                            <Column style={{width: '100%', alignItems:'center'}}>
                                <SpacedCenteredText text={text}>Trainer: {modalContent.trainer}</SpacedCenteredText>
                                <SpacedCenteredText text={text}>Jockey: {modalContent.jockey}</SpacedCenteredText>
                                <SpacedCenteredText text={text}>Purchase Price: {modalContent.purchaseprice}</SpacedCenteredText>
                                <SpacedCenteredText text={text}>Career Earnings: {modalContent.careerearnings}</SpacedCenteredText>
                                <SpacedCenteredText text={text}>Foaled: {modalContent.foaled}</SpacedCenteredText>
                            </Column>
                        </VerticallyCenteredRow>
                        <div style={{width: '90%', paddingBottom: '20px'}} >
                            {modalContent.description} 
                        </div>
                        <div style={{fontSize:'20px', alignItems: 'center'}}> Historical Prices </div>                                
                                <Graph currentData={modalContent} currentName={""} height={250} width={300}/>
                        {news.filter((news) => Object.keys(news.horses).length > 0 && news.horses.includes(modalContent.id)).length > 0 &&
                            <div style={{fontSize:'20px', alignItems: 'center', marginTop: '40px', marginBottom: '20px'}}> News </div>
                        }
                        <Column style={{width: '90%', alignItems:'center'}}>
                            {news.filter((news) => Object.keys(news.horses).length > 0 && news.horses.includes(modalContent.id)).map((news) => 
                            <Column style={{zIndex: 4, background: background, alignItems: 'center'}} key={news.id}>
                                <LineDivider 
                                    firstColor={background} 
                                    secondColor={logo} 
                                    thirdColor={background} 
                                    style={{marginBottom: '5%', width: '100%'}}
                                />
                                <ArticleThumbnail article={news} setModal={setArticleContent} modalOn={articleContent!=null} mini={false}/>

                            </Column>
                            )}
                        </Column>
                        {events.filter((event) => Object.keys(event.horses).length > 0 && event.horses.includes(modalContent.id)).length > 0 &&
                            <div style={{fontSize:'20px', alignItems: 'center', marginTop: '20px', marginBottom: '20px'}}> Upcoming Events </div>
                        }
                        <Column style={{width: '90%', alignItems:'center'}}>
                        {events.filter((event) => Object.keys(event.horses).length > 0 && event.horses.includes(modalContent.id)).map((event) => 
                            <Column style={{zIndex: 4, background: background}} key={event.id}>
                                <LineDivider 
                                    firstColor={background} 
                                    secondColor={logo} 
                                    thirdColor={background} 
                                    style={{marginBottom: '5%', width: '100%'}}
                                />
                                <ArticleThumbnail article={event} setModal={setArticleContent} modalOn={articleContent!=null} mini={false}/>

                            </Column>
                            )}
                        </Column>
                    </Column>
                </MobileModalContainer>
            </MobileView>
            </>}
            {articleContent!=null && <ArticleModal article={articleContent} setModal={setArticleContent} modalOn={articleContent!=null}/>}
            <div style={{display: 'flex', flexDirection: 'column', width: '90%', alignItems: 'center', paddingLeft:'5%', filter: (modalContent || articleContent) ? 'blur(5px)' : 'none', zIndex: 4, paddingBottom: '30px', paddingTop:'110px', paddingRight:'5%'}} onClick={() => {
            if(modalContent) {
                setModalContent(null)
            }else if(articleContent){
                setArticleContent(null)
            }}}>
                <FilterAltIcon
                    color='inherit' 
                    style={{width: '30px', height: '30px', left: '30px', top: '120px', position: isMobileOnly? 'absolute':'fixed', color: text, cursor: 'pointer', zIndex: 'inherit', background: background}}
                    onClick={toggleEdit} 
                />
                {!isMobileOnly && <><CalendarMonthIcon
                    style={{width: '30px', height: '30px', right: '30px', top: '120px', position: isMobileOnly? 'absolute':'fixed', color: text, cursor: 'pointer', zIndex: 'inherit', background: background}}
                    onClick={() => setCalendar(!cal)} 
                />
                {cal && <HorseCalendar setModal={setArticleContent} calendarOn={cal}/>}
                </>}
                <LineDivider 
                    firstColor={background} 
                    secondColor={logo} 
                    thirdColor={background} 
                    style={{ margin:'20px', width: '100%', alignItems: 'center', zIndex: 'inherit'}}
                />
                <VerticallyCenteredRow style={{display: 'flex', width: '70%', justifyContent: 'center', gap:'50px', background: background, zIndex: 'inherit'}}>
                    <div style={{width:'30%', display: 'flex', flexDirection: 'column', alignItems: 'center', zIndex: 'inherit'}}>
                        <div style={{ fontSize: '20px', color: text, zIndex: 'inherit', textAlign: 'center'}}>Total Investment Value:</div>
                        <div style={{fontSize: '20px', color: text, zIndex: 'inherit', textAlign: 'center'}}>${Math.round(getTotalInvestmentValue() * 100)/100}</div>
                    </div>
                    <div style={{width:'30%',display: 'flex', flexDirection: 'column', alignItems: 'center', zIndex: 'inherit'}}>
                        <div style={{fontSize: '20px', color: text, zIndex: 'inherit', textAlign: 'center'}}>Total Amount Invested:</div>
                        <div style={{fontSize: '20px', color: text, zIndex: 'inherit', textAlign: 'center'}}>${Math.round(getTotalAmountInvested() * 100)/100}</div>
                    </div>
                    <div style={{width:'30%',display: 'flex', flexDirection: 'column', alignItems: 'center', zIndex: 'inherit'}}>
                        <div style={{ fontSize: '20px', color: text, zIndex: 'inherit', textAlign: 'center'}}>Net Return on Investment:</div> 
                        <div style={{fontSize: '20px', color: text, zIndex: 'inherit', textAlign: 'center'}}>{generalDelta(getTotalAmountInvested(), Math.round(getTotalInvestmentValue() * 100)/100)}</div>
                    </div>
                </VerticallyCenteredRow>
                <LineDivider 
                            firstColor={background} 
                            secondColor={logo} 
                            thirdColor={background} 
                            style={{ margin:'20px', width: '100%', alignItems: 'center', zIndex: 'inherit'}}
                />
                <VerticallyCenteredRow style={{minWidth: '337px',paddingTop: '5px', width: '30%', justifyContent: 'space-evenly', marginBottom: '30px', zIndex: 'inherit', background: colorMode == 'light' ? background: bgSecondary, borderRadius: '4px', gap: '5%'}}> 
                    <Column style={{alignItems: 'center', zIndex: 'inherit'}}>
                        <CenteredText style={{paddingLeft: '0px', width: '100%', zIndex: 'inherit'}} text={text}> Investment Value </CenteredText>
                        <svg fill={'blue'} width="15" height="15" >
                            <rect x={0} y={0} width={10} height={10}/>
                        </svg>
                    </Column>
                    <Column style={{alignItems: 'center', zIndex: 'inherit'}}>
                        <CenteredText style={{paddingLeft: '0px', width: '100%', zIndex: 'inherit'}} text={text}> Amount Invested </CenteredText>
                        <svg fill={logo} width="15" height="15">
                            <rect x={0} y={0} width={10} height={10} />
                        </svg>
                    </Column>
                    
                </VerticallyCenteredRow>
                    {/* {(myAssetData ?? []).length > 0 && */}
                    {/* <div style={{background: background, zIndex: 4,  justifyContent: 'center'}}> */}
                        {okayToRender && <InvestmentValueGraph height={windowSize.windowWidth>700? 500: windowSize.windowWidth * .7} width={windowSize.windowWidth>900 ? 793 : .8*windowSize.windowWidth}/>}
                    {/* </div> */}
                    
                
                
                <LineDivider 
                            firstColor={background} 
                            secondColor={logo} 
                            thirdColor={background} 
                            style={{ marginTop: '30px', width: '100%', alignItems: 'center'}}
                />
                {horseOfferings.filter((horse) => (getTotalShares(horse) ?? 0) > 0).length === 0 &&
                    <CenteredText 
                        style={{ paddingTop: '15px', flexWrap: 'nowrap', alignItems: 'center', fontSize: '20px'}} 
                        text={logo}
                        >
                        You don't own any horses! Invest on the Offerings and Trade pages.
                    </CenteredText>
                }
                <Row style={{justifyContent: 'center', alignItems: 'center', flex: '3', flexWrap: 'wrap', gap: '1%', paddingTop: '30px', paddingBottom: '30px'}}>
                {horseOfferings.filter((horse) => (getTotalShares(horse) ?? 0) > 0).map((horse) => 
                    <Column key={horse.id} style={{zIndex: 4, background: background, minWidth: isMobileOnly? '350px': '603px', marginBottom: '30px', borderColor: logo, borderRadius: '10px', borderStyle: 'solid', alignItems: 'center', padding:'15px'}}>
                        <CenteredText 
                            style={{ paddingLeft: '0%', paddingTop: '0px', flexWrap: 'nowrap', alignItems: 'center', fontSize: '50px', cursor: 'pointer'}} 
                            text={text} 
                            onClick={() => showModal(horse)} 
                            >
                            {horse.name}
                        </CenteredText>
                        <LineDivider 
                            firstColor={background} 
                            secondColor={logo} 
                            thirdColor={background} 
                            style={{ width: '40%'}}
                        />
                        <VerticallyCenteredRow style={{width: '100%',  color: text,  gap: '0px', flexDirection: isMobileOnly? 'column':'row'}}>
                            <Column style={{width: '100%', alignItems: 'center', paddingLeft: '10px'}}>
                                <Row style={{width: '90%'}}>
                                    <Column style={{width: '140%', alignItems: 'start', paddingLeft: '7px', gap: '7px', paddingTop: '10px'}}>
                                        <div style={{fontSize: '18px', paddingBottom: '10px'}} >Number of Shares:</div>
                                        <div style={{fontSize: '18px', paddingBottom: '10px'}} >Current Share Price:</div>
                                        <div style={{fontSize: '18px', paddingBottom: '10px'}} >Investment Value: </div>
                                        <div style={{fontSize: '18px', paddingBottom: '10px', display: 'flex', flexDirection: 'row'}} >Amount Invested:</div>
                                        <div style={{fontSize: '18px'}} >Return on Investment:</div>
                                    </Column>
                                    <Column style={{width: '60%', alignItems: 'end', gap: '7px', paddingTop: '10px' }}>
                                        <div style={{fontSize: '18px', paddingBottom: '10px'}} >{Math.round((getTotalShares(horse) ?? 0) * 100)/100}</div>
                                        <div style={{fontSize: '18px', paddingBottom: '10px'}} >${Math.round(horse.price * 100)/100}</div>
                                        <div style={{fontSize: '18px', paddingBottom: '10px'}} >${Math.round(horse.price * (getTotalShares(horse) ?? 0) * 100)/100}</div>
                                        <div style={{fontSize: '18px', paddingBottom: '10px'}} >${Math.round(getAmountInvested(getOwnership(horse)) * 100)/100}</div> 
                                        <div style={{fontSize: '18px'}} >{generalDelta(getAmountInvested(getOwnership(horse)), Math.round(horse.price * (getTotalShares(horse) ?? 0) * 100)/100)}</div>
                                    </Column>
                                </Row>
                                
                            </Column >
                            
                            <Column style={{width: '85%', height: '110%',  zIndex: 0, alignItems: 'center', paddingTop:'10px'}}>
                                <div style={{fontSize:'18px'}}> Price Trend over Past Year</div>
                                <Minigraph currentData={horse} currentName={""} height={175} width={265}/>
                            
                            </Column>
                            
                        </VerticallyCenteredRow>
                        {/* <VerticallyCenteredRow style={{width: '100%', justifyContent: 'space-evenly', paddingBottom: '15px', paddingTop: '10px'}}>
                            <Button 
                                variant="contained"
                                color='inherit'
                                style={{color: background, background: logo, alignItems: 'center', width: '40%', height:'50px'}}
                                >
                                Prior Purchases
                            </Button>
                            <Button 
                                variant="contained"
                                color='inherit'
                                style={{color: background, background: logo, alignItems: 'center', width: '40%', height:'50px'}}
                                >
                                Past Events
                            </Button> 
                        </VerticallyCenteredRow> */}
                    </Column>
                )}
                </Row>
                {myOrders.buy_requests.length > 0 && <>
                    <VerticallyCenteredRow style={{background: background, width: '75%', justifyContent: 'space-evenly', marginTop:'20px', flexDirection:isMobileOnly?'column':'row'}}>
                        <CenteredText text={logo} style={{fontSize:'20px', alignItems: 'center', width:'fit-content', paddingLeft:'0px', zIndex:4}}> My Buy Requests </CenteredText>
                        {myOrders.buy_requests.length > 0 && <>
                        <FormControlLabel control={<Checkbox checked={showBuyCompleted} sx={{
                            color: logo,
                            '&.Mui-checked': {
                            color: logo,
                            
                            },
                        }}/>} label="Show Completed" style={{color: logo, minWidth: '200px', marginLeft: '',justifyContent:'center', zIndex:4}} onClick={() => setShowBuyCompleted(!showBuyCompleted)}/></>}
                    </VerticallyCenteredRow>
                    <LineDivider 
                        firstColor={background} 
                        secondColor={logo} 
                        thirdColor={background} 
                        style={{ width: '90%', marginLeft: '5%', marginBottom: '20px', zIndex:3}}
                    />
                </>}
                <Column style={{alignItems: 'center', background: colorMode=='blue' ? '#1a2c50' : background, padding: '10px', height: 'fit-content', marginBottom: '20px', borderRadius: '5px', zIndex: 4}}>
                {myOrders.buy_requests.filter((request) => showBuyCompleted || !request.complete).map((request) => (
                    <Row key={request.id} style={{marginBottom: '10px'}}>
                        <div style={{color: text}}>
                            {request.shares.toLocaleString(undefined, {maximumFractionDigits: 4})} share(s) of {horses.filter((horse) => horse.id == request.horse_id)[0].name} at ${request.price.toLocaleString(undefined, {minimumFractionDigits: 2})}
                        </div>
                        {request.complete && <div style={{color: 'green', marginLeft: '10px'}}>
                            Complete
                        </div>}
                        {!request.complete && <div style={{color: 'orange', marginLeft: '10px'}}>
                            Pending
                        </div>}
                        {!request.complete && <Button 
                            onClick={() => CancelOrder(request.id, 'buy')} 
                            color='inherit' 
                            style={{color: 'red', height: '20px', marginTop: '2px'}}
                        >Cancel Order</Button>}
                    </Row>

                ))}
                {myOrders.buy_requests.filter((request) => (showBuyCompleted || !request.complete)).length==0 && <>
                                <div style={{color: text}}>
                                    No Buy Requests are pending
                                </div>
                            </>}
                </Column>
                {myOrders.sell_requests.length > 0 && <>
                    <VerticallyCenteredRow style={{background: background, width: '75%', justifyContent: 'space-evenly', flexDirection:isMobileOnly?'column':'row'}}>
                        <CenteredText text={logo} style={{fontSize:'20px', alignItems: 'center', width:'fit-content', paddingLeft: '0px', zIndex:4}}> My Sell Requests </CenteredText>
                        {myOrders.sell_requests.length > 0 && <>
                        <FormControlLabel control={<Checkbox checked={showSellCompleted} sx={{
                            color: logo,
                            '&.Mui-checked': {
                            color: logo,
                            },
                        }}/>} label="Show Completed" style={{color: logo, minWidth: '200px',justifyContent:'center', zIndex:4}} onClick={() => setShowSellCompleted(!showSellCompleted)}/></>}
                    </VerticallyCenteredRow>
                    <LineDivider 
                        firstColor={background} 
                        secondColor={logo} 
                        thirdColor={background} 
                        style={{ width: '90%', marginLeft: '5%', marginBottom: '20px', zIndex:3}}
                    />
                    </>}
                    <Column style={{alignItems: 'center', background: colorMode=='blue' ? '#1a2c50' : background, height: 'fit-content', padding: '10px', borderRadius: '5px', zIndex: 4}}>
                    {myOrders.sell_requests.filter((request) => (showSellCompleted || !request.complete)).map((request) => (
                        <Row key={request.id} style={{marginBottom: '10px', height: 'fit-content', alignItems: 'center'}}>
                            <div style={{color: text}}>
                                {request.shares.toLocaleString(undefined, {maximumFractionDigits: 4})} share(s) of {horses.filter((horse) => horse.id == request.horse_id)[0].name} at ${request.price.toLocaleString(undefined, {minimumFractionDigits: 2})}
                            </div>
                            {request.complete && <div style={{color: 'green', marginLeft: '10px'}}>
                                Complete
                            </div>}
                            {!request.complete && <div style={{color: 'orange', marginLeft: '10px'}}>
                                Pending
                            </div>}
                            {request.buy_req && <div style={{color: text, marginLeft: '10px'}}>
                                Attached Buy Request for {request.buy_req.shares.toLocaleString(undefined, {maximumFractionDigits: 4})} share(s) of {horses.filter((horse) => horse.id == request.buy_req?.horse_id)[0].name} at ${request.buy_req?.price.toLocaleString(undefined, {minimumFractionDigits: 2})}
                            </div>}
                            {!request.complete && <Button 
                                onClick={() => CancelOrder(request.id, 'sell')} 
                                color='inherit' 
                                style={{color: 'red', height: '20px', marginTop: '2px'}}
                            >Cancel Order</Button>}
                        </Row>

                    ))}
                    {myOrders.sell_requests.filter((request) =>  (showSellCompleted || !request.complete)).length==0 && <>
                        <div style={{color: text}}>
                            No Sell Requests are pending
                        </div>
                    </>}
                    <div style={{color: text}}>
                        Note: for horse to horse exchanges, buy orders are sent after the sell order is complete
                    </div>
                    </Column>
                {Object.keys(user.ownerships).length > 0 && news.filter((news) => Object.keys(news.horses).length > 0 && news.horses.some((id) => user.ownerships.map((ownership) => ownership.horse_id).includes(id))).length > 0 &&
                    <SpacedCenteredText text={text} style={{width:'fit-content', fontSize: '45px', background: background, zIndex:'inherit', marginTop:'20px'}}>
                        News
                    </SpacedCenteredText>
                }
                <Column ref={ref} style={{width: '90%', zIndex: articleContent!=null? 4: 5, alignItems:'center', marginBottom: '50px'}}>
                {Object.keys(user.ownerships).length > 0 && news.filter((news) => Object.keys(news.horses).length > 0 && news.horses.some((id) => user.ownerships.map((ownership) => ownership.horse_id).includes(id))).map((news) => 
                    <Column style={{background: background, alignItems:'center'}} key={news.id}>
                        <LineDivider 
                            firstColor={background} 
                            secondColor={logo} 
                            thirdColor={background} 
                            style={{marginBottom: '15px', width: '100%', zIndex: 'inherit'}}
                        />
                        <ArticleThumbnail article={news} setModal={setArticleContent} modalOn={articleContent!=null} mini={false}/>
                    </Column>)}
                </Column>
                {Object.keys(user.ownerships).length > 0 && events.filter((event) => Object.keys(event.horses).length > 0 && event.horses.some((id) => user.ownerships.map((ownership) => ownership.horse_id).includes(id))).length > 0 &&
                    <SpacedCenteredText text={text} style={{width:'fit-content', fontSize: '45px', background: background, zIndex:4}}>
                        Upcoming Events
                    </SpacedCenteredText>
                }
                <Column ref={ref} style={{width: '90%', zIndex: articleContent!=null? 4: 5, alignItems:'center', marginBottom: '50px'}}>
                    {Object.keys(user.ownerships).length > 0 && events.filter((event) => Object.keys(event.horses).length > 0 && event.horses.some((id) => user.ownerships.map((ownership) => ownership.horse_id).includes(id))).map((event) => 
                    <Column style={{background: background, alignItems:'center'}} key={event.id}>
                        <LineDivider 
                            firstColor={background} 
                            secondColor={logo} 
                            thirdColor={background} 
                            style={{marginBottom: '15px', width: '100%', zIndex: 'inherit'}}
                        />
                        <ArticleThumbnail article={event} setModal={setArticleContent} modalOn={articleContent!=null} mini={false}/>
                    </Column>)}
                </Column>
            </div>
       </>
    )
};



import React, { KeyboardEventHandler, useEffect, useRef, useState } from "react";
import {debounce, update} from 'lodash';

// redux
import { useSelectFromRedux } from "../../utils/_hooks";
import Graph from '../_shared/graph';

//components
import { CenteredText, Column, LineDivider, ModalContainer, ModalHeader, Row, SpacedCenteredText, VerticallyCenteredRow, BasicListText, MarketButton, LineVertDivider } from "../_shared";
import { BuyRequest, Horse, HorseValueData, NewsItem, Ownership, RaceEvent, SellRequest, UserAccount } from "../../types";
import api from "../../api";
import { useDispatch } from "react-redux";
import { UserActions } from "../../state/user";
import CloseIcon from '@mui/icons-material/Close';
import { Button, IconButton,   Tooltip, createTheme, InputAdornment,  } from "@material-ui/core";
import {ThemeProvider, TextField, FormControlLabel, Checkbox } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { addWatchListServer, removeWatchListServer } from "../../state/user/operations";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowUpwardSharpIcon from '@mui/icons-material/ArrowUpwardSharp';
import ArrowDownwardSharpIcon from '@mui/icons-material/ArrowDownwardSharp';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import MarketFilter from "./marketFilter";
import MarketNewsArticle from "./marketNewsArticle";
import MarketEventArticle from "./marketEventArticle";
import GiteIcon from '@mui/icons-material/Gite';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import DescriptionIcon from '@mui/icons-material/Description';
import InfoIcon from '@mui/icons-material/Info';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { BrowserView, MobileView, isMobileOnly } from 'react-device-detect';
import Article from "../_shared/articleThumbnail";
import { percentDelta } from '../_shared/percentDelta';






//Swiper imports
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Pagination, Navigation, Autoplay, Mousewheel } from "swiper";
import MarketBuy from "./marketBuy";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import moment from "moment";
import ArticleThumbnail from "../_shared/articleThumbnail";
import ArticleModal from "../_shared/articleModal";
import transaction from "../transaction";

export default (_props: any) => {

    const [
        bgSecondary,
        logo, 
        textColor, 
        background,
        myAssetData,
        horses,
        user,
        colorMode,
        sliderBg,
        orders,
        lightHighlight,
        darkHighlight,
        lightaccent
    ] = useSelectFromRedux((state) => [
        state.color.scheme.bgSecondary,
        state.color.scheme.logo, 
        state.color.scheme.text,
        state.color.scheme.background,
        state.user.myAssetData,
        state.publicData.horses,
        state.user.user,
        state.color.mode,
        state.color.scheme.sliderBg,
        state.publicData.orders,
        state.color.general.lighthighlight,
        state.color.general.darkhighlight,
        state.color.general.lightaccent
    ]);
    const swiperTextColor = '#050402';
    const dispatch = useDispatch();
    const [news, setNews] = useState<NewsItem[]>([]);
    const [events, setEvents] = useState<RaceEvent[]>([]);

    const getWatchList =  () : Horse[] => {
        let temp: Horse[] = [];
            for(let horse of horses) {
                if(Object.keys(user?.watch_list ?? []).length > 0 && user?.watch_list.includes(horse.id)){
                    temp.push(horse)
                }
            }
        return temp;
    }

    const theme = createTheme({
        palette: {
            primary: {
                main: logo
            },
            secondary: {
                main: '#000000'
            }
        }
    })
    
    const [slider, setSlider] = React.useState<[string, Horse[]]>(['Watchlist', getWatchList()]);
    const [pageContent, setPageContent] = React.useState<Horse>(horses[0]);
    const [filterMode, setFilterMode] = React.useState<Boolean>(false);
    const [sortMode, setSortMode] = React.useState<Boolean>(false)
    const [buyComponent, showBuyComponent] = React.useState<Boolean>(false);
    const [articleContent, setArticleContent] = useState<RaceEvent | NewsItem | null>(null);

    const [dropDown, setDropDown] = React.useState<"info" | "orders" | "trade" | "analytics" | "description">("info");
    
    const [myOrders, setMyOrders] = useState<{buy_requests: BuyRequest[], sell_requests: SellRequest[]}>({buy_requests: [], sell_requests: []});
    const [showBuyCompleted, setShowBuyCompleted] = useState<boolean>(false);
    const [showSellCompleted, setShowSellCompleted] = useState<boolean>(false);
    const [horseOrders, setHorseOrders] = useState<{buy_requests: BuyRequest[], sell_requests: SellRequest[]}>({buy_requests: [], sell_requests: []});
    const [okayToRender, setOkayToRender] = useState<boolean>(false);


        useEffect(() => {
            const fetchData = async () => {
                const response1 = await api.get('/public/getEvents');
                setEvents(response1.data);
                const response2 = await api.get('/public/getNews');
                setNews(response2.data);
                if(user) {
                    const response3 = await api.post('/user/getMyOrders', {id: user?.id});
                    setMyOrders(response3.data.m);
                }
                const response4 = await api.get('/public/getAllCompleteOrders');
                setHorseOrders(response4.data.m);
        };
        fetchData().catch(console.error);

        const handleResize = debounce(() => {
            setWindowSize({
              windowWidth: window.outerWidth,
              windowHeight: window.outerHeight,
            });
            setResized(window.outerWidth < 990);
  
          }, 250); // Adjust the debounce time (in milliseconds) as needed
      
          window.addEventListener('resize', handleResize);
      
          return () => {
            handleResize.cancel(); // Cancel any pending debounced calls
            window.removeEventListener('resize', handleResize);
          };
    }, [])


    const getMyHorses = (user: UserAccount): Horse[] => {
        if(Object.keys(user.ownerships).length === 0) {
            return []
        }
        const myOwnerships = user.ownerships;
        const myOwnershipIDS = myOwnerships.map((ownership) => ownership.horse_id);
        let myHorses: Horse[] = [];
        for (var horse of horses) {
            if(myOwnershipIDS.includes(horse.id)) {
                myHorses.push(horse);            
            }
        }
        return myHorses
    }

    const myAssets = user ? getMyHorses(user): [];

    useEffect(() => {
        if(user && Object.keys(user?.watch_list ?? []).length > 0){
            setSlider(['Watchlist', getWatchList()])
            
        } else {
            setSlider(['Top Offerings', getTopOfferings()])
        }
    }, [horses])

    useEffect(()=>{

        updatePageContent(slider[1][0])
    }, [slider])

    useEffect(()=>{

        setOkayToRender(true)
    }, [pageContent])

    const updatePageContent = (horse: Horse) => {
        setOkayToRender(false);
        if(horse!=pageContent) {
            setPageContent(horse)
        } else {
            setOkayToRender(true)
        }
        // while(pageContent!=horse) {}
    }

    const removeDamSire = (dam: string): string => {
        let tempArray: string[] = dam.split('(');
        return tempArray[0];
    }

    const getDamSire = (dam: string): string => {
        let tempArray: string[] = dam.split('(');
        if(tempArray.length <2){
            tempArray = [tempArray[0], 'None'];
        };
        let tempString: string = tempArray[1].substring(0, tempArray[1].length-1)
        return tempString;
    }

    const getTopOfferings = (): Horse[] => {
        let topOfferings: Horse[] = horses.map((x) => x);
        topOfferings.sort((a: Horse, b: Horse)=> {
            return b.price - a.price;
        });
        return topOfferings;
    }

    const searchAlgorithm = (search: string): Horse[] => {
        search = search.toLowerCase();
        let temp: Horse[] =[];
        for(let horse of horses){
            if(horse.name.toLowerCase().includes(search)){
                temp.push(horse)
            }
        }
        return temp;
    }

    
    const [searchInput, setSearchInput] = React.useState<string>();

    const callToSetSlider = (title: string, sheep: Horse[]) => {
        // setOkayToRender(false);
        setFilterMode(false);
        setSlider([title, sheep]);
    }

    const handleSearch = (key: any) => {
        if(key?.key == 'Enter'){
            setSlider(['Search Results', searchInput==null ? horses : searchAlgorithm(searchInput)])
        }
    };

    const removeFromWatchlist = (userID: number | undefined, horseID: number) => {
        dispatch(removeWatchListServer({id: userID, horse_id: horseID}))
        if(slider[0]=='Watchlist'){
            let temp: Horse[] = []
            for(let horse of slider[1]){
                if(horse.id!=horseID){
                    temp.push(horse)
                }
            }
        setSlider(['Watchlist',temp])
        }
    }

    const [windowSize, setWindowSize] = useState({
        windowWidth: window.outerWidth,
        windowHeight: window.outerHeight,
      });
    const [resized, setResized] = useState<Boolean>(window.outerWidth < 990)

    const CancelOrder = async (id: number, type : 'buy' | 'sell') => {
        const response = await api.post('/user/cancelOrder', {id: id, type: type});
        console.log(response)
        const response3 = await api.post('/user/getMyOrders', {id: user?.id});
        setMyOrders(response3.data.m);
        const response4 = await api.get('/public/getAllCompleteOrders');
        setHorseOrders(response4.data.m);

    }

    const ref = useRef(null);

    const newsClick = () => {
        if(ref.current){
        window.scrollTo({
            top: dropDown == 'orders' && !resized ? 1350: dropDown == 'analytics' && !resized ? 1150 : !resized ? 1250: 2000, 
            behavior: 'smooth'
            /* you can also use 'auto' behaviour
                in place of 'smooth' */
            });
        }
    }

    const get52WeekHigh = (horse: Horse) : number => {
        let earlyDate = moment().subtract(1, 'year');
        const yearData = horse.historical_price.filter((horse : any) => moment(horse.date).isAfter(earlyDate));
        let temp: number = 0;
        for(let trade of yearData){
            if(trade.price > temp) temp = trade.price;
        }
        return temp;
    }
    const getDailyHigh = (horse: Horse) : number => {
        let earlyDate = moment().subtract(1, 'day');
        const yearData = horse.historical_price.filter((horse : any) => moment(horse.date).isAfter(earlyDate));
        let temp: number | undefined = 0;
        if(yearData.length ==0) {
            let horseData: HorseValueData[] | undefined;
            horseData = horses.find((horse: Horse) => (horse.id==pageContent.id))?.historical_price;
            if(!horseData) {
                
                temp = 0;
                return temp;
            }
            temp = horseData[horseData.length-1].price;
            return temp;
        }
        for(let trade of yearData){
            if(trade.price > temp) temp = trade.price;
        }
        return temp;
    }
    const get52WeekLow = (horse: Horse) : number => {
        let earlyDate = moment().subtract(1, 'year');
        const yearData = horse.historical_price.filter((horse : any) => moment(horse.date).isAfter(earlyDate));
        let temp: number = get52WeekHigh(horse);
        for(let trade of yearData){
            if(trade.price < temp) temp = trade.price;
        }
        return temp;
    }
    const getDailyLow = (horse: Horse) : number => {
        let earlyDate = moment().subtract(1, 'day');
        const yearData = horse.historical_price.filter((horse : any) => moment(horse.date).isAfter(earlyDate));
        let temp: number | undefined = getDailyHigh(horse);
        if(yearData.length ==0) {
            let horseData: HorseValueData[] | undefined;
            horseData = horses.find((horse: Horse) => (horse.id==pageContent.id))?.historical_price;
            if(!horseData) {
                
                temp = 0;
                return temp;
            }
            temp = horseData[horseData.length-1].price;
            return temp;
        }
        for(let trade of yearData){
            if(trade.price < temp) temp = trade.price;
        }
        return temp;
    }

    const statDiv = (stat: string, value: any) =>{
        return (                                    
        <Column style={{width:'100%', gap:'0px', alignItems: 'center', justifyContent: 'flex-start'}}>
        <BasicListText style={{background: background, color: logo, width:'45%', textAlign:'center', fontWeight:'bold'}}>{stat}</BasicListText>
        <BasicListText style={{background: background, textAlign: 'center', marginTop: '0px'}}>{value}</BasicListText>
        <LineDivider 
        firstColor={background} 
        secondColor={logo} 
        thirdColor={background} 
        style={{zIndex: 'inherit', marginBottom: '0px', width: '50%'}}
        />
    </Column>)
    }

    const infoCard = (width:any, label:any, value:any) => {
        
        return (
        <Column style={{width: width, alignItems: 'center', justifyContent: 'space-evenly', textAlign:'center'}}>
            <div style={{color: textColor, textAlign:'center'}}>
                {label}
            </div>
            <div style={{color: textColor, textAlign:'center'}}>
                ${value.toFixed(2)}
            </div>
        </Column>)
    };
    

    return (
        <>
        <MarketFilter setHorseOfferings={null} setSlider={setSlider} filterMode={filterMode} setFilter={setFilterMode}/>
        {articleContent!=null && <ArticleModal article={articleContent} setModal={setArticleContent} modalOn={articleContent!=null}/>}
        <div style={{
            // boxShadow: colorMode == 'light' ? `5px 5px 20px 10px #bebebe` : `5px 5px 20px 10px black`, 
        background: background, zIndex: 4, height:'100%', paddingTop: '100px', paddingBottom: '5%', filter: buyComponent ? 'blur(5px)' : articleContent ? 'blur(5px)': 'none'}}onClick={() => {
                if(buyComponent) {
                    showBuyComponent(false)
                }
                if(articleContent) {
                    setArticleContent(null)
                }
                if(filterMode) {
                    setFilterMode(false)
                }
                }}>
            <Column style={{width: '100%', height: '100%', alignItems:'center' , zIndex:0, overflow: buyComponent ? 'hidden':'scroll'}}>
                <Row style={{width:!resized ? '90%':'100%', marginLeft: '0%', paddingTop: '10px', paddingBottom:'5px', zIndex: 'inherit', alignItems: 'center', justifyContent: windowSize.windowWidth>791?'space-evenly':'space-evenly', flexDirection: windowSize.windowWidth>791 ? 'row':'column'}}>
                    <Row style={{minWidth: !resized ? '25%':'20%', width: windowSize.windowWidth >791? '':'90%', justifyContent: windowSize.windowWidth >791? 'left':'center', gap: !resized ? '15px': '15px', alignItems: 'center', marginTop: windowSize.windowWidth <792? '15px':''}}>
                        <div style={{ flexGrow:3, maxWidth:'200px', minWidth: '70px', background: background, zIndex: 'inherit'}}>    
                            <ThemeProvider theme={theme}>               
                            <TextField 
                                label="Search field"
                                type="search"
                                variant="outlined"
                                color='primary'
                                id="input-with-icon-textfield"
                                focused
                                onChange={(event) => setSearchInput(event.target.value)}
                                onKeyDown={(event) => handleSearch(event)}
                                sx={{input: {color: textColor}, background: background}}
                                style={{color: logo, background: background}}
                                
                            />
                            </ThemeProvider>  
                        </div>


                        <MarketButton text={logo} background={slider[0]=='Filtered Horses' ? bgSecondary : background} style={{cursor: 'pointer'}} onClick={() => setFilterMode(true)} >
                            <IconButton color='inherit' style={{padding: '0px'}}>
                                <FilterAltIcon
                                    color='inherit' 
                                    style={{width: '40px', height: '40px',  color: 'inherit', cursor: 'pointer', background: slider[0]=='Filtered Horses' ? bgSecondary : background, zIndex: 'inherit', borderRadius: '4px'}}
                                    />
                            </IconButton>
                            <div style = {{fontSize: '13px', color: 'inherit', textAlign:'center', zIndex: 'inherit', width:'fit-content', height: 'fit-content', background: slider[0]=='Filtered Horses' ? bgSecondary : background}}  > Filter </div>
                        </MarketButton>
                        

                        <MarketButton text={logo} background={slider[0]=='Top Offerings' ? bgSecondary : background } style={{cursor: 'pointer', zIndex:20}} onClick={() => callToSetSlider('Top Offerings', getTopOfferings())} >
                            
                            <IconButton color='inherit' style={{padding: '0px'}}>
                                <ThumbUpAltIcon
                                color='inherit' 
                                style={{width: '40px', height: '40px',  color: 'inherit', cursor: 'pointer', background: slider[0]=='Top Offerings' ? bgSecondary : background, zIndex: 'inherit', borderRadius: '4px'}}
                                />
                            </IconButton>
                            <div style = {{fontSize: '13px', color: 'inherit', textAlign:'center', zIndex: 'inherit', width:'fit-content', height: 'fit-content', background: slider[0]=='Top Offerings' ? bgSecondary : background}}  > Top Rated </div>
                        </MarketButton>
                    </Row>

                    {/* <Column style={{alignItems: 'center',zIndex: '10',flexGrow:4, justifySelf:'center', minWidth: !resized ? '300px': '80px', order: windowSize.windowWidth > 791 ? 0: -1}}>
                        <SpacedCenteredText text={logo} style={{lineHeight: '60px', fontSize:windowSize.windowWidth>791? '5vw':isMobileOnly?'50px':'40px', fontWeight: 'bold', width:'fit-content', height: 'fit-content', background: background, zIndex: 'inherit', paddingBottom: '0px'}}>
                            The Market
                        </SpacedCenteredText>
                        <SpacedCenteredText text={logo} style={{marginTop: isMobileOnly?'0px':'5px', fontSize:'14px', fontWeight: 'lighter', zIndex: 'inherit', width:'fit-content', height: 'fit-content', background: background}}>
                            *Prices are for 0.1% share*
                        </SpacedCenteredText> 
                    </Column> */}


                    <Swiper
                        slidesPerView={Math.round(windowSize.windowWidth*.6/180)}
                        spaceBetween={10}
                        direction='horizontal'
                        style={{height: '100px', minWidth: .6*windowSize.windowWidth, marginRight: '30px', marginLeft: '20px'}}

                        autoplay={{ 
                            delay: 3000,
                            disableOnInteraction: slider[0]=='Top Offerings' ? false : true,
                            pauseOnMouseEnter: true

                        
                        }}

                        cssMode={true}
                        mousewheel={true}
                        modules={[Navigation, Pagination, Mousewheel, Autoplay]}
                        className="mySwiper"
                    >
                        
                        {slider[1].map((horse) => (
                        <SwiperSlide key={horse.id}>
                            {/* add to styling for pictures: backgroundImage: `url(${horse.photo})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', cursor: 'pointer', textShadow: '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black' */}
                            <Column style={{borderStyle: 'solid', borderColor: logo, borderRadius: '5px', borderWidth:'3px', width: '170px', height: '85%', alignItems:'center', justifyContent:'space-evenly'}} 
                            onClick={()=> updatePageContent(horse)}
                            >
                                <BasicListText style={{marginBottom: '0px', marginTop: '3px', textAlign:'center', color: logo, fontWeight: 'bold', fontSize: '18px', height: 'min-content', backdropFilter: 'blur(2px)'}}>
                                    {horse.name}
                                </BasicListText>

                                        <div style={{backdropFilter: 'blur(2px)'}}>{percentDelta(horse.historical_price, 16, 'row')}</div>
                                        <div style={{color: textColor, lineHeight: '16px', marginBottom: '3px', background: 'transparent', backdropFilter: 'blur(2px)'}}>
                                            ${horse.price.toLocaleString(undefined, {minimumFractionDigits: 2})}
                                        </div>

                            </Column>
                        </SwiperSlide>
                        ))}

                    </Swiper>



                    

                    <Row style={{width: windowSize.windowWidth >791? '':'90%', alignItems: 'center', justifyContent: windowSize.windowWidth >791? 'flex-end':'center', gap: windowSize.windowWidth>791?'15px':'30px', marginTop: windowSize.windowWidth>791? '':'20px', marginBottom: windowSize.windowWidth>791? '':'10px'}}>
                        <Tooltip title='Must Log in or Sign up first' 
                            style={{alignSelf: 'center', color: logo, height: '30px'}}
                            arrow
                            disableHoverListener = {!user? false:true}
                            enterTouchDelay={0}
                        >
                        <MarketButton text={logo} background={slider[0]=='Watchlist' ? bgSecondary : background} style={{cursor: 'pointer',}} onClick={() => user && callToSetSlider('Watchlist', getWatchList())}>
                            <IconButton color='inherit' style={{padding: '0px'}}>
                                    <VisibilityIcon
                                        color= {user ? 'inherit' : 'disabled'}
                                        style={{width: '40px', height: '40px',  color: user ? 'inherit' : 'disabled', cursor: 'pointer', background: slider[0]=='Watchlist' ? bgSecondary : background, zIndex: 'inherit', borderRadius: '4px'}}
                                        />
                            </IconButton>
                            <div style = {{fontSize: '13px', color: user ? 'inherit':'grey', textAlign:'center', zIndex: 'inherit', width:'fit-content', height: 'fit-content', background: slider[0]=='Watchlist' ? bgSecondary : background}}  > Watchlist </div>
                        </MarketButton>
                    </Tooltip>
                    <Tooltip title='Must Log in or Sign up first' 
                            style={{alignSelf: 'center', color: logo, height: '30px'}}
                            arrow
                            disableHoverListener = {!user? false:true}
                            enterTouchDelay={0}
                        >
                        <MarketButton text={logo} background={slider[0]=='My Assets' ? bgSecondary : background} style={{cursor: 'pointer',}} onClick={() => user && callToSetSlider('My Assets', myAssets)}>
                            
                            <IconButton color='inherit' style={{padding: '0px'}}>
                                <GiteIcon
                                color={user ? 'inherit' : 'disabled'} 
                                style={{width: '40px', height: '40px',  color: user ? 'inherit' : 'disabled',  background: slider[0]=='My Assets' ? bgSecondary : background, zIndex: 'inherit', borderRadius: '4px'}}
                                />
                            </IconButton>
                            <div style = {{fontSize: '13px', color: user ? 'inherit' : 'grey', textAlign:'center', zIndex: 'inherit', width:'fit-content', height: 'fit-content', background: slider[0]=='My Assets' ? bgSecondary : background}}  > My Assets </div>
                        </MarketButton>
                    </Tooltip>
                    
                    </Row>
                    

                </Row>
                

                

                


                {/* <LineDivider 
                    firstColor={logo} 
                    secondColor={logo} 
                    thirdColor={logo} 
                    style={{width: '100%', zIndex: 'inherit', marginBottom:'5px'}}
                /> */}
                
                  
                <LineDivider 
                    firstColor={background} 
                    secondColor={logo} 
                    thirdColor={background} 
                    style={{ width: '100%', marginTop: '5px', zIndex: 'inherit', marginBottom: '10px'}}
                />
                {pageContent && 
                <Column style={{width: '100%', alignItems:'center', zIndex: 'inherit'}}>
                    <Row style={{width: '100%', alignItems: !resized ? 'flex-start' : 'center', justifyContent: !resized ? 'space-evenly' : 'center', zIndex: 'inherit', marginBottom: '30px', flexDirection: !resized ? 'row' : 'column'}}>
                        <Column style={{width: !resized ? '36%' : '90%', alignItems:'center', gap: '0px', zIndex: 'inherit'}}>
                            <Row style={{width:'100%', alignItems:'center', justifyContent: 'space-around'}}>
                                {user && <div style={{width:'8%', color: background}}>
                                    
                                </div>}
                                <SpacedCenteredText text={textColor} style={{width:'fit-content', paddingBottom: '0px', fontSize: '50px', fontWeight: 'bold',  zIndex: 'inherit', background: background, height: 'fit-content' }}>
                                    {pageContent?.name}
                                </SpacedCenteredText>
                                {user && <Column style={{width: '8%', alignItems:'center', color: textColor,}}>
                                    {!getWatchList().includes(pageContent) ? 
                                    <>
                                        <Tooltip title='Add to Watch List' 
                                            onClick={() => dispatch(addWatchListServer({id: user?.id, horse_id: pageContent.id}))}
                                            style={{alignSelf: 'center', color: logo, height: '30px'}}
                                            arrow
                                        >
                                            <IconButton style={{background: background, color: logo, boxShadow: colorMode=='light' ? `0px 1px 4px gray`: undefined}}>
                                                <AddIcon /> 
                                            </IconButton>
                                        </Tooltip>
                                    </>: 
                                    <>
                                    <Tooltip title='Remove From Watch List' 
                                        onClick={() => removeFromWatchlist(user?.id, pageContent.id)}
                                        style={{alignSelf: 'center', color: logo, height: '30px'}}
                                        arrow
                                    >
                                        <IconButton style={{background: background, color: logo, boxShadow: colorMode=='light' ? `0px 1px 4px gray`: undefined}}>
                                            <DeleteIcon/> 
                                        </IconButton>
                                    </Tooltip>
                                    </>}
                                </Column>}

                            </Row>
                            
                            <LineDivider 
                                firstColor={background} 
                                secondColor={logo} 
                                thirdColor={background} 
                                style={{ width: '50%', marginBottom: '10px', marginTop: '5px'}}
                            />
                            <Row style={{alignItems:'center', justifyContent:'space-evenly', gap: '15px'}}>
                                <div style={{background: background, marginBottom: '10px', justifyContent: 'center', alignItems: 'center'}}>
                                    {percentDelta(pageContent?.historical_price, 22, 'column')}
                                </div>
                                <SpacedCenteredText text={textColor} style={{justifyContent: 'center', alignItems: 'center', fontSize: '36px',  letterSpacing: '2px', zIndex: 'inherit', width:'fit-content',  background: background}}>
                                    ${pageContent?.price.toLocaleString(undefined, {minimumFractionDigits: 2})}
                                </SpacedCenteredText>
                            </Row>
                        
                            <LineDivider 
                                firstColor={background} 
                                secondColor={logo} 
                                thirdColor={background} 
                                style={{ width: '90%', marginBottom: '7px', marginTop: '4px'}}
                            />
                            <VerticallyCenteredRow style={{width: '50%', justifyContent: 'space-around', gap:'3%'}}>
                                <Tooltip title='Basic Info' 
                                    onClick={() => setDropDown("info")}
                                    style={{alignSelf: 'center', color: logo, height: '30px'}}
                                    arrow
                                >
                                    <IconButton style={{background: dropDown == "info" ? bgSecondary : background, color: logo, boxShadow: colorMode=='light' ? `0px 1px 4px gray`: undefined}}>
                                        <InfoIcon /> 
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title='Analytics' 
                                    onClick={() => setDropDown("analytics")}
                                    style={{alignSelf: 'center', color: logo, height: '30px'}}
                                    arrow
                                >
                                    <IconButton style={{background: dropDown == "analytics" ? bgSecondary : background, color: logo, boxShadow: colorMode=='light' ? `0px 1px 4px gray`: undefined}}>
                                        <QueryStatsIcon /> 
                                    </IconButton>
                                </Tooltip>
                                <ThemeProvider theme={theme}>
                                    <Button variant="contained" color='secondary' style={{ color: '#000000', background: dropDown == "trade" ? bgSecondary : logo }} onClick={() => setDropDown("trade")}>
                                        Trade
                                    </Button>
                                </ThemeProvider>
                                <Tooltip title='Trade History' 
                                    onClick={() => setDropDown("orders")}
                                    style={{alignSelf: 'center', color: logo, height: '30px'}}
                                    arrow
                                >
                                    <IconButton style={{background: dropDown == "orders" ? bgSecondary : background, color: logo, boxShadow: colorMode=='light' ? `0px 1px 4px gray`: undefined}}>
                                        <ManageSearchIcon /> 
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title='Description' 
                                    onClick={() => setDropDown('description')}
                                    style={{alignSelf: 'center', color: logo, height: '30px'}}
                                    arrow
                                >
                                    <IconButton style={{background: dropDown == "description" ? bgSecondary : background, color: logo, boxShadow: colorMode=='light' ? `0px 1px 4px gray`: undefined}}>
                                        <DescriptionIcon /> 
                                    </IconButton>
                                </Tooltip>
                            </VerticallyCenteredRow>
                            <LineDivider 
                                firstColor={background} 
                                secondColor={logo} 
                                thirdColor={background} 
                                style={{ width: '90%', marginTop: '7px', marginBottom: '10px'}}
                            />
                            {dropDown == 'description' ? 
                            <div style={{width:'100%', lineHeight: '22px', fontSize: '16px', textAlign: 'center', color: textColor, zIndex: 'inherit', background: background}}>
                                {pageContent.description}
                            </div>
                            : dropDown == "info" ? 
                            <>
                            {<Column style={{width:'100%'}}>
                            <VerticallyCenteredRow style={{width: '100%', marginBottom: '15px', fontSize: '18px', color: textColor, justifyContent: !resized ? 'space-evenly' : 'center', zIndex: 'inherit', gap: '5px'}}>
                                <Column style={{maxWidth:'33%', minHeight: '200px', justifyContent:'space-between'}}>
                                    <h3 style={{fontSize: '100%'}}>
                                        <strong style={{display: 'block', color: logo, fontSize: '70%'}}>Sire</strong>
                                        {pageContent.sire}
                                        <strong style={{display: 'block', color: logo, fontSize: '70%', marginTop: '20px'}}>Sex</strong>
                                        {pageContent.sex}
                                        <strong style={{display: 'block', color: logo, fontSize: '70%', marginTop: '20px'}}>Purchase Price</strong>
                                        {pageContent.purchaseprice}
                                    </h3>
                                </Column>
                                <Column style={{maxWidth:'33%',minHeight: '200px', justifyContent:'space-between'}}>
                                    <h3 style={{fontSize: '100%'}}>
                                        <strong style={{display: 'block', color: logo, fontSize: '70%'}}>Dam</strong>
                                        {pageContent.dam}
                                        <strong style={{display: 'block', color: logo, fontSize: '70%', marginTop: '20px'}}>Trainer</strong>
                                        {pageContent.trainer}
                                        <strong style={{display: 'block', color: logo, fontSize: '70%', marginTop: '20px'}}>Career Earnings</strong>
                                        {pageContent.careerearnings}
                                    </h3>
                                </Column>
                                <Column style={{maxWidth:'33%',minHeight: '200px', justifyContent:'space-between'}}>
                                    <h3 style={{fontSize: '100%'}}>
                                        <strong style={{display: 'block', color: logo, fontSize: '70%'}}>Dam Sire</strong>
                                        {pageContent.damsire}
                                        <strong style={{display: 'block', color: logo, fontSize: '70%', marginTop: '20px'}}>Jockey</strong>
                                        {pageContent.jockey}
                                        <strong style={{display: 'block', color: logo, fontSize: '70%', marginTop: '20px'}}>Foaled</strong>
                                        {pageContent.foaled}
                                    </h3>
                                </Column>
                            </VerticallyCenteredRow>
                            </Column> 
                                }
                            </>: 
                            dropDown == "trade" ? 
                            <>
                            {okayToRender&& <MarketBuy horse={pageContent} buyMode={true} setBuyMode={showBuyComponent}/>}
                            </> : dropDown == 'analytics' ?
                            <>
                            <Column style={{width: '100%"', alignItems: 'center', justifyContent: 'space-evenly', gap: '25px', marginBottom: '15px', marginTop: '15px'}}>
                            {!isMobileOnly? <>
                            <VerticallyCenteredRow style={{width: '100%', justifyContent: 'space-around'}}>
                                    {infoCard('23%','52 Week High',get52WeekHigh(pageContent))}
                                    <LineVertDivider 
                                        firstColor={background} 
                                        secondColor={logo} 
                                        thirdColor={background} 
                                        style={{zIndex: 'inherit', marginBottom: '10px'}}
                                    />
                                    {infoCard('23%','52 Week Low',get52WeekLow(pageContent))}

                                    <LineVertDivider 
                                        firstColor={background} 
                                        secondColor={logo} 
                                        thirdColor={background} 
                                        style={{zIndex: 'inherit', marginBottom: '10px'}}
                                    />
                                    {infoCard('23%','Daily High',getDailyHigh(pageContent))}
                                    <LineVertDivider 
                                        firstColor={background} 
                                        secondColor={logo} 
                                        thirdColor={background} 
                                        style={{zIndex: 'inherit', marginBottom: '10px'}}
                                    />
                                    {infoCard('23%','Daily Low',getDailyLow(pageContent))}
                                </VerticallyCenteredRow>
                                <VerticallyCenteredRow style={{width: '100%', justifyContent: 'space-around'}}>
                                    {infoCard('23%','Market Cap',1000000)}
                                    <LineVertDivider 
                                        firstColor={background} 
                                        secondColor={logo} 
                                        thirdColor={background} 
                                        style={{zIndex: 'inherit', marginBottom: '10px'}}
                                    />
                                    {infoCard('23%','Open (12am EST)', 35000)}
                                    <LineVertDivider 
                                        firstColor={background} 
                                        secondColor={logo} 
                                        thirdColor={background} 
                                        style={{zIndex: 'inherit', marginBottom: '10px'}}
                                    />
                                    {infoCard('23%','EPS', 1000000)}
                                    <LineVertDivider 
                                        firstColor={background} 
                                        secondColor={logo} 
                                        thirdColor={background} 
                                        style={{zIndex: 'inherit', marginBottom: '10px'}}
                                    />
                                    {infoCard('23%','Avg Div Yield', 50000)}

                                </VerticallyCenteredRow>
                                <VerticallyCenteredRow style={{width: '100%', justifyContent: 'space-around'}}>
                                    {infoCard('50%','Volumne Traded Today', 50000)}
                                    <LineVertDivider 
                                        firstColor={background} 
                                        secondColor={logo} 
                                        thirdColor={background} 
                                        style={{ zIndex: 'inherit', marginBottom: '10px'}}
                                    />
                                    {infoCard('50%','Average Volume Traded', 35000)}

                                </VerticallyCenteredRow>
                                </> : 
                                <>
                                <VerticallyCenteredRow style={{width: '100%', justifyContent: 'space-around'}}>
                                    {infoCard('45%','52 Week High',get52WeekHigh(pageContent))}
                                    <LineVertDivider 
                                        firstColor={background} 
                                        secondColor={logo} 
                                        thirdColor={background} 
                                        style={{zIndex: 'inherit', marginBottom: '10px'}}
                                    />
                                    {infoCard('45%','52 Week Low',get52WeekLow(pageContent))}
                                    </VerticallyCenteredRow>
                                <VerticallyCenteredRow style={{width: '100%', justifyContent: 'space-around'}}>
                                    {infoCard('45%','Daily High',getDailyHigh(pageContent))}

                                    <LineVertDivider 
                                        firstColor={background} 
                                        secondColor={logo} 
                                        thirdColor={background} 
                                        style={{zIndex: 'inherit', marginBottom: '10px'}}
                                    />
                                    {infoCard('45%','Daily Low',getDailyLow(pageContent))}
                                </VerticallyCenteredRow>
                                <VerticallyCenteredRow style={{width: '100%', justifyContent: 'space-around'}}>
                                    {infoCard('45%','Market Cap',1000000)}
                                    <LineVertDivider 
                                        firstColor={background} 
                                        secondColor={logo} 
                                        thirdColor={background} 
                                        style={{zIndex: 'inherit', marginBottom: '10px'}}
                                    />
                                    {infoCard('45%','Open (12am EST)',35000)}
                                    </VerticallyCenteredRow>
                                <VerticallyCenteredRow style={{width: '100%', justifyContent: 'space-around'}}>
                                    {infoCard('45%','EPS',1000000)}
                                    <LineVertDivider 
                                        firstColor={background} 
                                        secondColor={logo} 
                                        thirdColor={background} 
                                        style={{zIndex: 'inherit', marginBottom: '10px'}}
                                    />
                                    {infoCard('45%','Avg Div Yield',50000)}
                                </VerticallyCenteredRow>
                                <VerticallyCenteredRow style={{width: '100%', justifyContent: 'space-around'}}>
                                    {infoCard('45%','Volume Traded Today',50000)}
                                    <LineVertDivider 
                                        firstColor={background} 
                                        secondColor={logo} 
                                        thirdColor={background} 
                                        style={{ zIndex: 'inherit', marginBottom: '10px'}}
                                    />
                                    {infoCard('45%','Average Volume Traded',35000)}

                                </VerticallyCenteredRow>
                                </>}
                                
                                
                            </Column>
                            </>: dropDown == 'orders' ? <>
                                <Column style={{ justifyContent:'space-evenly', alignItems: 'center', width: '100%'}}>
                                    <SpacedCenteredText text={textColor} style={{fontSize: '22px'}}>
                                            Completed Trades
                                    </SpacedCenteredText>
                                    <Column style={{width: '80%', alignItems: 'center', background: colorMode=='blue' ? '#1a2c50' : background, padding: '10px', maxHeight: '500px', marginBottom: '10px', borderRadius: '5px'}}>
                                    <VerticallyCenteredRow style={{width: '100%', justifyContent: 'space-evenly'}}>
                                        <SpacedCenteredText text={textColor} style={{width: '33.3%'}}>
                                            Date
                                        </SpacedCenteredText>
                                        <SpacedCenteredText text={textColor} style={{width: '33.3%'}}>
                                            Share(s)
                                        </SpacedCenteredText>
                                        <SpacedCenteredText text={textColor} style={{width: '33.3%'}}>
                                            Price/Share
                                        </SpacedCenteredText>
                                    </VerticallyCenteredRow>
                                    <LineDivider 
                                        firstColor={background} 
                                        secondColor={logo} 
                                        thirdColor={background} 
                                        style={{ width: '90%', marginBottom: '10px', minHeight: '2px', maxHeight: '2px'}}
                                    />
                                    <Column style={{width: '100%', overflow: 'auto', marginBottom: '10px',background: colorMode=='blue' ? '#1a2c50' : background}}>
                                    
                                    {horses.filter((horse) => horse.id === pageContent.id)
                                                    .reverse()
                                                    .map((horse) => {
                                                        const horseData = horses.find((horse) => horse.id === pageContent.id);
                                                        const horseTransactions = horseData?.transactions




                                                        return (
                                                            <Column>
                                                              {horseTransactions && [...horseTransactions].reverse().map((transaction) => (
                                                                <VerticallyCenteredRow
                                                                  style={{ marginBottom: '15px', width: '100%', justifyContent: 'space-evenly' }}
                                                                >
                                                                  <SpacedCenteredText text={textColor} style={{ width: '33.3%' }}>

                                                                    {moment(transaction.date.toString()).format('MMMM Do')}
                                                                  </SpacedCenteredText>
                                                                  <SpacedCenteredText text={textColor} style={{ width: '33.3%' }}>
                                                                    {transaction.shares.toLocaleString()}
                                                                  </SpacedCenteredText>
                                                                  <SpacedCenteredText text={textColor} style={{ width: '33.3%' }}>
                                                                    {transaction.price.toLocaleString()}
                                                                  </SpacedCenteredText>
                                                                </VerticallyCenteredRow>
                                                              ))}
                                                            </Column>
                                                          );


                                                    })}
                                                </Column>
                                                </Column>
                                                </Column>
                                        </> : <></>}


                            <LineDivider 
                                firstColor={background} 
                                secondColor={logo} 
                                thirdColor={background} 
                                style={{ width: '80%', marginBottom: '15px', marginTop:'10px'}}
                            />
                            <img src={pageContent.photo} style={{borderRadius: '7px', marginBottom: '15px', width: !resized ? '100%' : '75%', zIndex: 'inherit', boxShadow: colorMode=='light' ? `0px 02px 6px gray`: undefined}}/>

                            
                        </Column>
                        <Column style={{alignItems:'center', width: !resized ? '54%' : '90%', zIndex: 'inherit'}}>
                                {okayToRender && <Graph currentData={pageContent} currentName={""} height={windowSize.windowWidth>990 ? .35*windowSize.windowWidth : .65*windowSize.windowWidth} width={windowSize.windowWidth>990 ? .54*windowSize.windowWidth : .8*windowSize.windowWidth}/>}
                                <VerticallyCenteredRow style={{background: background, width: '75%', justifyContent: 'space-evenly', marginTop:'20px', flexDirection:isMobileOnly?'column':'row'}}>
                                    <CenteredText text={logo} style={{fontSize:'20px', alignItems: 'center', width:'fit-content', paddingLeft:'0px'}}> My Buy Requests </CenteredText>
                                    {myOrders.buy_requests.length > 0 && <>
                                    <FormControlLabel control={<Checkbox checked={showBuyCompleted} sx={{
                                        color: logo,
                                        '&.Mui-checked': {
                                        color: logo,
                                        
                                        },
                                    }}/>} label="Show Completed" style={{color: logo, minWidth: '200px', marginLeft: '',justifyContent:'center'}} onClick={() => setShowBuyCompleted(!showBuyCompleted)}/></>}
                                </VerticallyCenteredRow>
                                <LineDivider 
                                    firstColor={background} 
                                    secondColor={logo} 
                                    thirdColor={background} 
                                    style={{ width: '90%', marginLeft: '5%', marginBottom: '20px'}}
                                />
                            <Column style={{alignItems: 'center', background: colorMode=='blue' ? '#1a2c50' : background, padding: '10px', height: 'fit-content', marginBottom: '20px', borderRadius: '5px'}}>
                            {myOrders.buy_requests.filter((request) => request.horse_id == pageContent.id && (showBuyCompleted || !request.complete)).map((request) => (
                                <Row key={request.id} style={{marginBottom: '15px'}}>
                                    <div style={{color: textColor}}>
                                        {request.shares.toLocaleString(undefined, {maximumFractionDigits: 4})} share(s) of {horses.filter((horse) => horse.id == request.horse_id)[0].name} at ${request.price.toLocaleString(undefined, {minimumFractionDigits: 2})}
                                    </div>
                                    {request.complete && <div style={{color: 'green', marginLeft: '10px'}}>
                                        Complete
                                    </div>}
                                    {!request.complete && <div style={{color: 'orange', marginLeft: '10px'}}>
                                        Pending
                                    </div>}
                                    {!request.complete && <Button 
                                        onClick={() => CancelOrder(request.id, 'buy')} 
                                        color='inherit' 
                                        style={{color: 'red', height: '20px', marginTop: '2px'}}
                                    >Cancel Order</Button>}
                                </Row>

                            ))}
                            {(myOrders.buy_requests.filter((request) => request.horse_id == pageContent.id && (showBuyCompleted || !request.complete)).length==0 && user!=null) && <>
                                <div style={{color: textColor}}>
                                    No Buy Requests are pending for {pageContent.name}
                                </div>
                            </>}
                            {user==null && <>
                                <div style={{color: textColor}}>
                                    Please sign in to make a buy request for {pageContent.name}
                                </div>
                            </>}
                            
                            </Column>
                            
                            
                                <VerticallyCenteredRow style={{background: background, width: '75%', justifyContent: 'space-evenly', flexDirection:isMobileOnly?'column':'row'}}>
                                    <CenteredText text={logo} style={{fontSize:'20px', alignItems: 'center', width:'fit-content', paddingLeft: '0px'}}> My Sell Requests </CenteredText>
                                    {myOrders.sell_requests.length > 0 && <>
                                    <FormControlLabel control={<Checkbox checked={showSellCompleted} sx={{
                                        color: logo,
                                        '&.Mui-checked': {
                                        color: logo,
                                        },
                                    }}/>} label="Show Completed" style={{color: logo, minWidth: '200px',justifyContent:'center'}} onClick={() => setShowSellCompleted(!showSellCompleted)}/></>}
                                </VerticallyCenteredRow>
                                <LineDivider 
                                    firstColor={background} 
                                    secondColor={logo} 
                                    thirdColor={background} 
                                    style={{ width: '90%', marginLeft: '5%', marginBottom: '20px'}}
                                />
                            
                            <Column style={{alignItems: 'center', background: colorMode=='blue' ? '#1a2c50' : background, height: 'fit-content', padding: '10px', borderRadius: '5px'}}>
                            {myOrders.sell_requests.filter((request) => request.horse_id == pageContent.id && (showSellCompleted || !request.complete)).map((request) => (
                                <Row key={request.id} style={{marginBottom: '15px', height: 'fit-content', alignItems: 'center'}}>
                                    <div style={{color: textColor, textAlign: 'center'}}>
                                        {request.shares.toLocaleString(undefined, {maximumFractionDigits: 4})} share(s) of {horses.filter((horse) => horse.id == request.horse_id)[0].name} at ${request.price.toLocaleString(undefined, {minimumFractionDigits: 2})}
                                    </div>
                                    {request.complete && <div style={{color: 'green', marginLeft: '10px'}}>
                                        Complete
                                    </div>}
                                    {!request.complete && <div style={{color: 'orange', marginLeft: '10px'}}>
                                        Pending
                                    </div>}
                                    {request.buy_req && <div style={{color: textColor, marginLeft: '10px', textAlign: 'center'}}>
                                        Attached Buy Request for {request.buy_req.shares.toLocaleString(undefined, {maximumFractionDigits: 4})} share(s) of {horses.filter((horse) => horse.id == request.buy_req?.horse_id)[0].name} at ${request.buy_req?.price.toLocaleString(undefined, {minimumFractionDigits: 2})}
                                    </div>}
                                    {!request.complete && <Button 
                                        onClick={() => CancelOrder(request.id, 'sell')} 
                                        color='inherit' 
                                        style={{color: 'red', height: '20px', marginTop: '2px'}}
                                    >Cancel Order</Button>}
                                </Row>

                            ))}
                            {(myOrders.sell_requests.filter((request) => request.horse_id == pageContent.id && (showSellCompleted || !request.complete)).length==0 && user!=null) && <>
                                <div style={{color: textColor}}>
                                    No Sell Requests are pending for {pageContent.name}
                                </div>
                            </>}
                            {user==null && <>
                                <div style={{color: textColor}}>
                                    Please sign in to make a sell request for {pageContent.name}
                                </div>
                            </>}
                            <div style={{color: textColor, marginTop:'25px'}}>
                                Note: for horse to horse exchanges, buy orders are sent after the sell order is complete
                            </div>
                            </Column>
                        </Column>
                    </Row>
                    
                    {news.filter((news) => Object.keys(news.horses).length > 0 && news.horses.includes(pageContent.id)).length > 0 &&
                        <SpacedCenteredText text={textColor} style={{width:'fit-content', fontSize: '45px', background: background, zIndex:'inherit'}}>
                            News
                        </SpacedCenteredText>
                    }
                    <Column ref={ref} style={{width: '90%', zIndex: 0, alignItems:'center', marginBottom: '50px'}}>
                        {news.filter((news) => Object.keys(news.horses).length > 0 && news.horses.includes(pageContent.id)).map((news) => 
                        <Column style={{background: background, alignItems:'center'}} key={news.id}>
                            <LineDivider 
                                firstColor={background} 
                                secondColor={logo} 
                                thirdColor={background} 
                                style={{marginBottom: '15px', width: '100%', zIndex: 'inherit'}}
                            />
                            <ArticleThumbnail article={news} setModal={setArticleContent} modalOn={articleContent!=null} mini={false}/>
                        </Column>)}
                    </Column>
                    {events.filter((event) => Object.keys(event.horses).length > 0 && event.horses.includes(pageContent.id)).length > 0 &&
                        <SpacedCenteredText text={textColor} style={{width:'fit-content', fontSize: '45px', background: background}}>
                            Upcoming Events
                        </SpacedCenteredText>
                    }
                    <Column style={{width: '90%', alignItems:'center'}}>
                        {events.filter((event) => Object.keys(event.horses).length > 0 && event.horses.includes(pageContent.id)).map((event) => 
                        <Column style={{background: background, alignItems:'center'}} key={event.id}>
                            <LineDivider 
                                firstColor={background} 
                                secondColor={logo} 
                                thirdColor={background} 
                                style={{marginBottom: '15px', width: '100%', zIndex: 'inherit'}}
                            />
                            <ArticleThumbnail article={event} setModal={setArticleContent}  modalOn={articleContent!=null} mini={false}/>
                        </Column>)}
                    </Column>
                </Column>
                }            
            </Column>
        </div>
        </>          
    );
};

import { produce } from "immer";
import { payloadAction, actionFactory, ActionUnion } from "reductser";
import { useSelectFromRedux } from "../../utils/_hooks";

const actionMap = {
    changeColorScheme: payloadAction<"dark" | "light" | "blue">(),
    changeBackground: payloadAction<boolean>()
};

export const colorActions = actionFactory(actionMap, "COLOR");

export type ColorAction = ActionUnion<typeof colorActions>;

const lightModeColorState: ColorState["scheme"] = {
    background: 'white',
    text: 'black',
    logo: '#5DADE2',
    sliderBg: '#ffd373',
    bgSecondary: '#e6e6e6',
    accent: '#014324'
};

const darkModeColorState: ColorState["scheme"] = {
    background: '#000000',
    text: 'white',
    logo: '#5DADE2',
    sliderBg: '#FFD373',
    bgSecondary: '#333333',
    accent: '#014324'

};

// const blueModeColorState: ColorState["scheme"] = {
//     background: '#203764',
//     text: 'white',
//     logo: '#ffc000',
//     sliderBg: '#ffd373',
//     bgSecondary: '#4d5f83'

// };

export interface ColorState {
    mode: string;
    particles: boolean;
    scheme: {
        background: string,
        text: string,
        logo: string,
        sliderBg: string,
        bgSecondary: string,
        accent: string
    };
    general: {
        error: string,
        success: string,
        logo: string,
        darkhighlight: string,
        lighthighlight: string,
        lightaccent: string
    }
}

export const getInitialState = (): ColorState => ({
    mode: localStorage.getItem('color mode') == 'light'? 'light':'dark',
    particles: localStorage.getItem('background') == 'false' ? false : true,
    scheme: localStorage.getItem('color mode') == 'dark' ? darkModeColorState : lightModeColorState,
    general: {
        error: 'red',
        success: 'green',
        logo: '#82a1ee',
        darkhighlight: '#5a70c2',
        lighthighlight: '#94B2f8',
        lightaccent: '#82A1EE'
    }
    
});

const colorReducer = (state = getInitialState(), action: ColorAction) =>
    produce(state, (draftState) => {
        if (action.reducer === "COLOR") {
            switch (action.type) {
                case "changeColorScheme":
                    if (action.payload === "dark") {
                        localStorage.setItem('color mode', 'dark')
                        draftState.mode = action.payload;
                        draftState.scheme = darkModeColorState;
                        localStorage.setItem("theme", "dark");
                    } else if (action.payload === "light") {
                        localStorage.setItem('color mode', 'light')
                        draftState.mode = action.payload;
                        draftState.scheme = lightModeColorState;
                        localStorage.setItem("theme", "light");
                    } 
                    break;
                case "changeBackground":
                    draftState.particles = action.payload;
                    localStorage.setItem('background', action.payload ? "true" : 'false');
                    break;
                default:
                    break;
            }
        }
    });

export default colorReducer;

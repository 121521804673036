import React, { useEffect, useRef, useState } from "react";

// redux
import { useSelectFromRedux, useDispatchPromise } from "../../utils/_hooks";
import { useDispatch } from "react-redux";
import EditIcon from '@mui/icons-material/Edit'
import { CenteredText, Column, LineDivider, Row, SpacedCenteredText } from "../_shared";
import { Button, createTheme, TextField, ThemeProvider } from "@mui/material";
import { UserActions } from "../../state/user";
import { ContentActions } from "../../state/content";
import { CenteredDiv } from "../site/styled";
import api from "../../api";
import { serverSignIn, serverSignUp } from "../../state/user/operations";
import { UserAccount } from "../../types";
import axios from "axios";

//components

export default (_props: any) => {

    const [
        user, 
        text, 
        error, 
        success,
        logo,
        background,
        signUpError
    ] = useSelectFromRedux((state) => [
        state.user.user, 
        state.color.scheme.text, 
        state.color.general.error,
        state.color.general.success,
        state.color.scheme.logo,
        state.color.scheme.background,
        state.user.signUpError
    ]);
    const dispatch = useDispatch();

    const [checkPassword, setCheckPassword] = useState<string>('');


    const [cuser, setcuser] = useState<UserAccount>({
        id: 0,
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        phone: 'unconfigured',
        address: 'unconfigured',
        city: 'unconfigured',
        state: 'unconfigured',
        zip: 'unconfigured',
        dob: 'unconfigured',
        ssn: 'unconfigured',
        citizenship: 'unconfigured',
        employment: 'unconfigured',
        ownerships: [],
        admin: false,
        tokens: 10000,
        watch_list: []
    })

    const theme = createTheme({
        palette: {
            primary: {
                main: '#E5C078'
            }
        }
    })

    const [pwdsame, setpwdsame] = useState<boolean>(true);

    useEffect(() => {
        setpwdsame(cuser.password == checkPassword)
    }, [checkPassword]);

    const [incomplete, setincomplete] = useState<boolean>(false);
    const [bademail, setbademail] = useState<boolean>(false);

    useEffect(() => {
        setbademail(false)
    }, [cuser.email])

    const signUpFunc = async () => {
        if(cuser.first_name === '' ||
            cuser.last_name === '' ||
            cuser.email === '' ||
            cuser.password === '' ||
            checkPassword === '' ||
            !pwdsame //||
            // cuser.phone === '' ||
            // cuser.address === '' ||
            // cuser.city === '' ||
            // cuser.state === '' ||
            // cuser.zip === '' ||
            // cuser.dob === '' ||
            // cuser.citizenship === '' ||
            // cuser.employment == ''
        ) {
            setincomplete(true)
        } else {

            var config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `https://api.usebouncer.com/v1.1/email/verify?email=${cuser.email}&timeout=10`,
            headers: { 
                'x-api-key': 'l6j3Qc7ub4pN6AWAGWFN6UUmwsCpxMxol41VfMkI'
            }
            };

            const response = await axios(config)

            if(response.data.status == 'deliverable' && response.data.reason == 'accepted_email') {
                dispatch(serverSignUp(cuser))
            } else {
                setbademail(true)
            }
        }
    }


    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems:'center', overflow: 'scroll', height: '100vh', transitionDuration: '500ms'}}>
            <SpacedCenteredText text={text} style={{
                display: 'flex', 
                flexDirection:'column', 
                marginTop:'150px', 
                fontSize: '40px', 
                zIndex: 5,
                width:'fit-content'
            }}>
                Sign Up
            </SpacedCenteredText>
            <Column style={{
                alignItems: 'center', 
                zIndex: 4, 
                background: background, 
                marginTop: '30px', 
                minWidth: '300px',
                padding: '20px', 
                borderRadius: '10px',
                borderWidth: '5px',
                borderColor: logo,
                borderStyle: 'outset'
                }}>
                {/* <CenteredDiv text={text} style={{paddingBottom: '20px'}}>
                    Password
                </CenteredDiv> */}
                <ThemeProvider theme={theme}>
                    <TextField 
                        id='standard-basic' 
                        label='First Name'
                        variant='standard'
                        focused
                        value={cuser.first_name}
                        onChange={(event) => setcuser({...cuser, first_name: event.target.value})}
                        sx={{input: {color: text}, color: text}}
                        style={{marginBottom: '10px', width: '200px', background: background, zIndex: 4}}
                    />
                    <TextField 
                        id='standard-basic' 
                        label='Last Name'
                        variant='standard' 
                        focused
                        value={cuser.last_name}
                        onChange={(event) => setcuser({...cuser, last_name: event.target.value})}
                        sx={{input: {color: text}, color: text}}
                        style={{marginBottom: '10px', width: '200px', background: background, zIndex: 4}}
                    />
                    <TextField 
                        id='standard-basic' 
                        label='Email'
                        variant='standard' 
                        focused
                        value={cuser.email}
                        onChange={(event) => setcuser({...cuser, email: event.target.value})}
                        sx={{input: {color: text}, color: text}}
                        style={{marginBottom: '10px', width: '200px', background: background, zIndex: 4}}
                    />
                    {/* <TextField 
                        id='standard-basic' 
                        label='Phone'
                        variant='standard' 
                        focused
                        value={cuser.phone}
                        onChange={(event) => setcuser({...cuser, phone: event.target.value})}
                        sx={{input: {color: text}, color: text}}
                        style={{marginBottom: '10px', width: '200px', background: background, zIndex: 4}}
                    />
                    <TextField 
                        id='standard-basic' 
                        label='Address'
                        variant='standard' 
                        focused
                        value={cuser.address}
                        onChange={(event) => setcuser({...cuser, address: event.target.value})}
                        sx={{input: {color: text}, color: text}}
                        style={{marginBottom: '10px', width: '200px', background: background, zIndex: 4}}
                    />
                    <TextField 
                        id='standard-basic' 
                        label='City'
                        variant='standard' 
                        focused
                        value={cuser.city}
                        onChange={(event) => setcuser({...cuser, city: event.target.value})}
                        sx={{input: {color: text}, color: text}}
                        style={{marginBottom: '10px', width: '200px', background: background, zIndex: 4}}
                    />
                    <TextField 
                        id='standard-basic' 
                        label='State'
                        variant='standard' 
                        focused
                        value={cuser.state}
                        onChange={(event) => setcuser({...cuser, state: event.target.value})}
                        sx={{input: {color: text}, color: text}}
                        style={{marginBottom: '10px', width: '200px', background: background, zIndex: 4}}
                    />
                    <TextField 
                        id='standard-basic' 
                        label='Zip Code'
                        variant='standard' 
                        focused
                        value={cuser.zip}
                        onChange={(event) => setcuser({...cuser, zip: event.target.value})}
                        sx={{input: {color: text}, color: text}}
                        style={{marginBottom: '10px', width: '200px', background: background, zIndex: 4}}
                    />
                    <TextField 
                        id='standard-basic' 
                        label='Date of Birth (MM/DD/YYYY)'
                        variant='standard' 
                        focused
                        value={cuser.dob}
                        onChange={(event) => setcuser({...cuser, dob: event.target.value})}
                        sx={{input: {color: text}, color: text}}
                        style={{marginBottom: '10px', width: '200px', background: background, zIndex: 4}}
                    />
                    <TextField 
                        id='standard-basic' 
                        label='SSN'
                        variant='standard' 
                        focused
                        value={cuser.ssn}
                        onChange={(event) => setcuser({...cuser, ssn: event.target.value})}
                        sx={{input: {color: text}, color: text}}
                        style={{marginBottom: '10px', width: '200px', background: background, zIndex: 4}}
                    />
                    <TextField 
                        id='standard-basic' 
                        label='Citizenship'
                        variant='standard' 
                        focused
                        value={cuser.citizenship}
                        onChange={(event) => setcuser({...cuser, citizenship: event.target.value})}
                        sx={{input: {color: text}, color: text}}
                        style={{marginBottom: '10px', width: '200px', background: background, zIndex: 4}}
                    />
                    <TextField 
                        id='standard-basic' 
                        label='Employment'
                        variant='standard' 
                        focused
                        value={cuser.employment}
                        onChange={(event) => setcuser({...cuser, employment: event.target.value})}
                        sx={{input: {color: text}, color: text}}
                        style={{marginBottom: '10px', width: '200px', background: background, zIndex: 4}}
                    /> */}
                    <TextField 
                        id='standard-basic' 
                        label='Password'
                        variant='standard' 
                        focused
                        type='password'
                        color='primary'
                        value={cuser.password}
                        onChange={(event) => setcuser({...cuser, password: event.target.value})}
                        sx={{input: {color: text}, color: text}}
                        style={{marginBottom: '10px', width: '200px', background: background, zIndex: 4}}
                    />
                    <TextField 
                        id='standard-basic' 
                        label='Re-enter Password'
                        variant='standard' 
                        focused
                        type='password'
                        color='primary'
                        value={checkPassword}
                        onChange={(event) => setCheckPassword(event.target.value)}
                        sx={{input: {color: text}, color: text}}
                        style={{marginBottom: '10px', width: '200px', background: background, zIndex: 4}}
                    />
                    {!pwdsame && <CenteredText text={error} style={{paddingLeft: 0}}>
                        Passwords do not match.
                    </CenteredText>}
                </ThemeProvider>
                {/* <CenteredText text={error} style={{paddingLeft: 0}}>
                    {signInError}
                </CenteredText> */}
                {signUpError && <CenteredText text={error} style={{paddingLeft: 0, marginTop: '10px'}}>
                    {signUpError}
                </CenteredText>}
                {incomplete && <CenteredText text={error} style={{paddingLeft: 0, marginTop: '10px'}}>
                    Incomplete data entered. Make sure you filled out every field.
                </CenteredText>}
                {bademail && <CenteredText text={error} style={{paddingLeft: 0, marginTop: '10px'}}>
                    Email address is undeliverable. Please use a valid email.
                </CenteredText>}
                <Button
                    variant='contained'
                    style={{alignSelf: 'center', background: logo, color: background, marginTop: '20px', marginBottom: '10px'}}
                    onClick={signUpFunc}
                >
                    Sign up
                </Button>
            </Column>
        </div>
    );
};
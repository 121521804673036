import React, { useEffect, useRef, useState } from "react";

// redux
import { useSelectFromRedux, useDispatchPromise } from "../../utils/_hooks";
import { useDispatch } from "react-redux";
import EditIcon from '@mui/icons-material/Edit'
import { CenteredText, Column, LineDivider, Row } from "../_shared";
import { Button, FormControlLabel, styled, TextField } from "@mui/material";
import { UserActions } from "../../state/user";
import { ContentActions } from "../../state/content";
import { CenteredDiv } from "../site/styled";
import api from "../../api";
import { changePasswordServer, signOut, userUpdate } from "../../state/user/operations";
import { createTheme, ThemeProvider } from "@mui/material";
import Switch, { SwitchProps } from '@mui/material/Switch';
import { colorActions } from "../../state/color";

//components

export default (_props: any) => {

    const [
        user, 
        text, 
        error, 
        success,
        logo,
        background,
        updateError,
        wrongPassword,
        colorMode,
        particles
    ] = useSelectFromRedux((state) => [
        state.user.user, 
        state.color.scheme.text, 
        state.color.general.error,
        state.color.general.success,
        state.color.scheme.logo,
        state.color.scheme.background,
        state.user.updateError,
        state.user.wrongPassword,
        state.color.mode,
        state.color.particles
    ]);
    const dispatch = useDispatch();

    if(user == null) {
        dispatch(ContentActions.setContent('home'))
        return(<>Error: User not Found</>)
    }

    // normal edit mode
    const [editMode, setEditMode] = useState<Boolean>(false);

    const [firstName, setFirstName] = useState<string>(user.first_name);
    const [lastName, setLastName] = useState<string>(user.last_name);
    const [email, setEmail] = useState<string>(user.email);
    const [phone, setPhone] = useState<string>(user.phone);
    const [address, setAddress] = useState<string>(user.address);
    const [city, setCity] = useState<string>(user.city);
    const [stat, setStat] = useState<string>(user.state);
    const [zip, setZip] = useState<string>(user.zip);
    const [citizenship, setCitizenship] = useState<string>(user.citizenship);
    const [employment, setEmployment] = useState<string>(user.employment);

    // password change

    const [changePassword, setChangePassword] = useState<Boolean>(false);

    const [oldPassword, setOldPassword] = useState<string>('');
    const [newPassword, setNewPassword] = useState<string>('');

    const theme = createTheme({
        palette: {
            primary: {
                main: '#E5C078'
            }
        }
    })
    
    const [shortPassword, setShortPassword] = useState<boolean>(false);



    const toggleEdit = () => {
        if(editMode) {
            dispatch(userUpdate({
                ...user,
                first_name: firstName,
                last_name: lastName,
                email: email,
                phone: phone,
                address: address,
                city: city,
                state: stat,
                zip: zip,
                citizenship: citizenship,
                employment: employment
            }));
        }
        setEditMode(!editMode);
    };

    const saveNewPassword = () => {
        if(newPassword.length < 8) {
            setShortPassword(true)
        } else {
            setShortPassword(false)
            setChangePassword(false)
            dispatch(changePasswordServer({id: user.id, oldPassword: oldPassword, newPassword: newPassword}))
            setNewPassword('')
            setOldPassword('')
        }
    }

    const IOSSwitch = styled((props: SwitchProps) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} style={{zIndex:4}}/>
      ))(({ theme }) => ({
        width: 42,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
          padding: 0,
          margin: 2,
          transitionDuration: '300ms',
          '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
              backgroundColor: theme.palette.mode === 'dark' ? 'black' : 'black',
              opacity: 1,
              border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.5,
            },
          },
          '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
          },
          '&.Mui-disabled .MuiSwitch-thumb': {
            color:
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[600],
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
          },
        },
        '& .MuiSwitch-thumb': {
          boxSizing: 'border-box',
          width: 22,
          height: 22,
        },
        '& .MuiSwitch-track': {
          borderRadius: 26 / 2,
          backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
          opacity: 1,
          transition: theme.transitions.create(['background-color'], {
            duration: 500,
          }),
        },
      }));

    return (
        <ThemeProvider theme={theme}>
        <div style={{display: 'flex', flexDirection: 'column', overflow: 'scroll', height: '100vh', transitionDuration: '500ms'}}>
            <EditIcon 
                color='inherit' 
                style={{right: '30px', top: '120px', position: 'fixed', color: text, cursor: 'pointer', zIndex: 4,  transitionDuration: '500ms'}}
                onClick={toggleEdit}
            />
            {/* <div style={{display: 'flex', flexDirection: 'row', left: '30px', top: '120px', position: 'fixed', color: logo, cursor: 'pointer', zIndex: 20}}>
                <Switch 
                    style={{display: 'flex', left: '30px', top: '120px', position: 'fixed', color: logo, cursor: 'pointer', zIndex: 20}}
                    checked={particles}
                    onChange={() => dispatch(colorActions.changeBackground(!particles))}
                    inputProps={{ 'aria-label': 'controlled' }}
                />
                <CenteredText text={text} style={{display: 'flex', width: '250px', marginLeft: '-30px', transitionDuration: '500ms'}}>
                    {particles ? "Disable" : "Enable"} Background Effect
                </CenteredText>
            </div> */}
            <CenteredText text={text} style={{paddingTop: '130px', fontSize: '40px', zIndex: 4,  transitionDuration: '500ms'}}>
                {!editMode && <div>
                    {firstName} {lastName}
                    <CenteredText text={text} style={{transitionDuration: '500ms', fontSize: '20px'}}>
                        {!editMode && <div>
                            {email}
                            {/* {email} | {phone} */}
                        </div>}
                    </CenteredText>
                    <LineDivider 
                        firstColor={background} 
                        secondColor={logo} 
                        thirdColor={background} 
                        style={{marginBottom: '2%', width: '100%', marginTop: '2%'}}
                    />
                    <div style={{fontSize: '30px'}}>
                        Currency: ${(+user.tokens).toFixed(3)}
                    </div>
                    <LineDivider 
                        firstColor={background} 
                        secondColor={logo} 
                        thirdColor={background} 
                        style={{marginBottom: '2%', width: '100%', marginTop: '2%'}}
                    />
                </div>}
                {updateError && <CenteredText text={error} style={{paddingLeft: 0}}>
                    {updateError}
                </CenteredText>}
                {editMode && <Column style={{
                    alignItems: 'center', 
                    zIndex: 4, 
                    background: background, 
                    marginTop: '30px',
                    padding: '20px', 
                    borderRadius: '10px',
                    borderWidth: '5px',
                    borderColor: logo,
                    borderStyle: 'outset',
                    width: '250px',
                    alignSelf: 'center'
                }}>
                <ThemeProvider theme={theme}>
                    <TextField 
                        id='standard-basic' 
                        label='First Name' 
                        variant='standard'
                        color='primary'
                        focused
                        defaultValue={firstName}
                        onChange={(event) => setFirstName(event.target.value)}
                        sx={{input: {color: text}}}
                        style={{marginBottom: '10px'}}
                    />
                    <TextField 
                        id='standard-basic' 
                        label='Last Name' 
                        variant='standard'
                        focused
                        value={lastName}
                        onChange={(event) => setLastName(event.target.value)}
                        sx={{input: {color: text}}}
                        style={{marginBottom: '10px'}}
                    />
                    <TextField 
                        id='standard-basic' 
                        label='Email' 
                        variant='standard'
                        focused
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        sx={{input: {color: text}}}
                        style={{marginBottom: '10px'}}
                    />
                </ThemeProvider>
                </Column>}
            </CenteredText>
            {/* <CenteredText text={text} style={{zIndex: 4, background: background, transitionDuration: '500ms'}}>
                {!editMode && <div>
                    {address}, {city}, {stat} {zip}
                </div>}
                {editMode && <Column style={{alignSelf: 'center', zIndex: 4, background: background, transitionDuration: '500ms'}}>
                    <TextField 
                        id='standard-basic' 
                        label='Address' 
                        // variant='standard'
                        focused
                        value={address}
                        onChange={(event) => setAddress(event.target.value)}
                        sx={{input: {color: text}}}
                        style={{marginBottom: '10px'}}
                    />
                    <TextField 
                        id='standard-basic' 
                        label='City' 
                        // variant='standard'
                        focused
                        value={city}
                        onChange={(event) => setCity(event.target.value)}
                        sx={{input: {color: text}}}
                        style={{marginBottom: '10px'}}
                    />
                    <TextField 
                        id='standard-basic' 
                        label='State' 
                        // variant='standard'
                        focused
                        value={stat}
                        onChange={(event) => setStat(event.target.value)}
                        sx={{input: {color: text}}}
                        style={{marginBottom: '10px'}}
                    />
                    <TextField 
                        id='standard-basic' 
                        label='Zip Code' 
                        // variant='standard'
                        focused
                        value={zip}
                        onChange={(event) => setZip(event.target.value)}
                        sx={{input: {color: text}}}
                        style={{marginBottom: '10px'}}
                    />
                </Column>}
            </CenteredText>
            <CenteredText text={text} style={{transitionDuration: '500ms'}}>
                {!editMode && <div style={{zIndex: 20}}>
                    {citizenship} | {employment}
                </div>}
                {editMode && <Column style={{alignSelf: 'center', zIndex: 4, background: background}}>
                        <TextField 
                            id='standard-basic' 
                            label='Citizenship' 
                            // variant='standard'
                            focused
                            value={citizenship}
                            onChange={(event) => setCitizenship(event.target.value)}
                            sx={{input: {color: text}}}
                            style={{marginBottom: '10px'}}
                        />
                        <TextField 
                            id='standard-basic' 
                            label='Employment' 
                            // variant='standard'
                            focused
                            value={employment}
                            onChange={(event) => setEmployment(event.target.value)}
                            sx={{input: {color: text}}}
                        style={{marginBottom: '10px'}}
                        />
                </Column>}
            </CenteredText> */}
            <Column style={{paddingTop: '50px', zIndex: 4, transitionDuration: '500ms'}}>
                {!changePassword && <Button
                    variant='contained'
                    style={{alignSelf: 'center', background: logo}}
                    onClick={() => setChangePassword(true)}
                >Change Password</Button>}
                {changePassword && <Column style={{alignItems: 'center', zIndex: 4, background: background}}>
                    <CenteredDiv text={text} style={{paddingBottom: '20px'}}>
                        Change Password
                    </CenteredDiv>
                    <TextField 
                        id='standard-basic' 
                        label='Old Password'
                        // variant='standard' 
                        focused
                        value={oldPassword}
                        onChange={(event) => setOldPassword(event.target.value)}
                        sx={{input: {color: text}, color: text}}
                        style={{marginBottom: '10px'}}
                    />
                    <TextField 
                        id='standard-basic' 
                        label='New Password'
                        // variant='standard' 
                        focused
                        value={newPassword}
                        onChange={(event) => setNewPassword(event.target.value)}
                        sx={{input: {color: text}, color: text}}
                        style={{marginBottom: '10px'}}
                    />
                    {shortPassword && <div style={{color: error, fontSize: '12px', marginBottom: '10px'}}>
                        Please enter a password with at least 8 characters
                    </div>}
                     <Button
                        variant='contained'
                        style={{alignSelf: 'center'}}
                        onClick={saveNewPassword}
                    >
                        Save Changes
                    </Button>
                </Column>}
                {wrongPassword && <CenteredText text={error} style={{fontSize: '12px', marginBottom: '10px', marginTop: '20px', paddingLeft: 0, width: '100%'}}>
                    Incorrect Password Entered. Please Try again
                </CenteredText>}
                <Button
                    variant='contained'
                    color='error'
                    style={{alignSelf: 'center', marginTop: '20px', marginBottom: '50px'}}
                    onClick={() => dispatch(signOut())}
                >Sign Out</Button>
            </Column>
        </div>
        </ThemeProvider>
    );
};
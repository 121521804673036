import React from 'react';
import { useSelectFromRedux } from '../../utils/_hooks';
import { CenteredDiv, TermsCenteredDiv} from '../site/styled';

export default (props: any) => {
    const [text, background] = useSelectFromRedux((state) => [state.color.scheme.text, state.color.scheme.background]);

    return (
        <div style={{display: 'flex', flexDirection: 'column', zIndex: 4}}>
            <CenteredDiv text={text} style={{paddingTop: '150px', marginBottom: '20px', fontSize: '20px', zIndex: 'inherit'}}>
            Privacy Policy            
            </CenteredDiv>
            <TermsCenteredDiv text={text} style={{zIndex: 'inherit'}}>
            This Privacy Policy explains Triple Crown Trading Inc. C Corporation’s (“Triple Crown Trading Inc.” or “we” or “our”) privacy practices for visitors to our proprietary Internet site at tctrading.co (the “Website”). We created this Privacy Policy to explain what information we gather from you when you visit our Website and how we may use and disclose this information. This Privacy Policy is incorporated into and made a part of Triple Crown Trading Inc.’s Terms of Use.
            </TermsCenteredDiv>
            <TermsCenteredDiv text={text} style={{zIndex: 'inherit'}}>
            By using our Website, you consent to the collection, use and disclosure of your personal information as described in this Privacy Policy. We reserve the right to change this Privacy Policy at any time. If we make any material changes to our Privacy Policy, we will post a new policy on the Website and update the “last updated” date set forth above. Policy changes will apply only to information collected after the date of the change.
            </TermsCenteredDiv>
            <TermsCenteredDiv text={text} style={{zIndex: 'inherit'}}>
            This Privacy Policy applies only to information collected through our Website and not to information collected offline.
            </TermsCenteredDiv>

            <CenteredDiv text={text} style={{marginBottom: '20px', fontSize: '20px', marginTop: '20px', zIndex: 'inherit'}}>
            What Information Do We Collect?
            </CenteredDiv>
            <TermsCenteredDiv text={text} style={{zIndex: 'inherit'}}>
            We collect information from you when you register to use or log into the Website, place an order for services through the Website, respond to communication such as e-mail or participate in other features of our Website.
            </TermsCenteredDiv>
            <TermsCenteredDiv text={text} style={{zIndex: 'inherit'}}>
            When registering to use the Website or ordering services from the Website, we may ask you for your name, e-mail address, mailing address, phone number, credit card information or other information. You may, however, visit certain unrestricted areas of the Website without providing such information.
            </TermsCenteredDiv>
            <TermsCenteredDiv text={text} style={{paddingLeft: '50px', zIndex: 'inherit'}}>
            1. We use IP addresses and session identifiers to analyze trends, to administer the Website, to track user activities, to infer user interests and to otherwise induce, deduce and gather information about individual users and market segments.
            </TermsCenteredDiv>
            <TermsCenteredDiv text={text} style={{paddingLeft: '50px', zIndex: 'inherit'}}>
            2. Like many websites, we use "cookies" to enhance your experience and gather information about visitors and visits to our Website. Please refer to the "Do we use cookies?" section below for information about cookies and how we use them.
            </TermsCenteredDiv>

            <CenteredDiv text={text} style={{marginBottom: '20px', fontSize: '20px', marginTop: '20px', zIndex:'inherit'}}>
            How Do We Use Your Information?
            </CenteredDiv>
            <TermsCenteredDiv text={text} style={{zIndex: 'inherit'}}>
            We may use the information that we collect from you when you register to use the Website, purchase services from the Website, surf the Website or use certain features of the Website in the following ways: 
            </TermsCenteredDiv>
            <TermsCenteredDiv text={text} style={{paddingLeft: '50px', zIndex:'inherit'}}>
            1. To allow us to better serve you in responding to your customer service requests.
            </TermsCenteredDiv>
            <TermsCenteredDiv text={text} style={{paddingLeft: '50px', zIndex: 'inherit'}}>
            2. To quickly process your transactions.
            </TermsCenteredDiv>
            <TermsCenteredDiv text={text} style={{paddingLeft: '50px', zIndex: 'inherit'}}>
            3. To administer a promotion, survey or other feature of the Website.
            </TermsCenteredDiv>
            
            <CenteredDiv text={text} style={{marginBottom: '20px', fontSize: '20px', marginTop: '20px', zIndex: 'inherit'}}>
            Do We Disclose the Information We Collect to Outside Parties?
            </CenteredDiv>
            <TermsCenteredDiv text={text} style={{zIndex: 'inherit'}}>
            We generally do not sell to, trade with or otherwise transfer to outside parties your personally identifiable information unless we provide you with advance notice, except as described herein. 
            </TermsCenteredDiv>
            <TermsCenteredDiv text={text} style={{zIndex: 'inherit'}}>
            The term "outside parties" does not include website hosting partners and other parties who assist us in operating our Website, conducting our business or servicing you, so long as those parties agree to keep this information confidential. In the event that we sell our company, or otherwise transfer any assets of our company, we may provide your information to the purchaser so that you may continue the relationship or business with us and our products. We may also release your information when we believe that release is necessary to comply with law, enforce the policies of our Website or protect our or others' rights, property or safety.
            </TermsCenteredDiv>
            <TermsCenteredDiv text={text} style={{zIndex: 'inherit'}}>
            However, non-personally identifiable visitor information may be provided to other parties for marketing, advertising or other uses.
            </TermsCenteredDiv>


            <CenteredDiv text={text} style={{marginBottom: '20px', fontSize: '20px', marginTop: '20px', zIndex: 'inherit'}}>
            Do We Use Cookies?
            </CenteredDiv>
            <TermsCenteredDiv text={text} style={{zIndex: 'inherit'}}>
            Yes. Cookies are small files that a site or its service provider transfers to your computer’s hard drive through your Web browser (if you allow) that enables the site's or service provider’s systems to recognize your browser and capture and remember certain information. For instance, we use cookies to help us remember and process the items in your shopping cart. We also use cookies to help us compile aggregate data about traffic and interactions on the Website so that we can offer better experiences and tools on the Website in the future.
            </TermsCenteredDiv>
            <TermsCenteredDiv text={text} style={{zIndex: 'inherit'}}>
            We may contract with third-party service providers to assist us in better understanding the visitors to our Website. These service providers are not permitted to use the information collected on our behalf except to help us conduct and improve our business.
            </TermsCenteredDiv>
            <TermsCenteredDiv text={text} style={{zIndex: 'inherit'}}>
            You can choose to have your computer warn you each time a cookie is being sent, or you can choose to turn off all cookies. You do this through your browser’s (i.e., Google Chrome or Internet Explorer) settings. Each browser is a little different, so look at your browser’s Help menu to learn the correct way to modify your cookies. If you disable cookies, you may not have access to many features that streamline your experience on our Website, and some of our services may not function properly.
            </TermsCenteredDiv>

            <CenteredDiv text={text} style={{marginBottom: '20px', fontSize: '20px', marginTop: '20px', zIndex: 'inherit'}}>
            Safeguarding Your Personal Information.
            </CenteredDiv>
            <TermsCenteredDiv text={text} style={{zIndex: 'inherit'}}>
            We follow generally accepted industry security standards to safeguard and help prevent unauthorized access, maintain data security and correctly use personal identification and financial information. However, no commercial method of information transfer over the Internet or electronic data storage is known to be 100% secure. As a result, we cannot guarantee the absolute security of such information during its transmission or its storage in our systems, and you should always take care to safeguard your personal information.
            </TermsCenteredDiv>

            <CenteredDiv text={text} style={{marginBottom: '20px', fontSize: '20px', marginTop: '20px', zIndex:'inherit'}}>
            What Should You Consider Before Clicking on Third Party Links?
            </CenteredDiv>
            <TermsCenteredDiv text={text} style={{zIndex: 'inherit'}}>
            This Privacy Policy applies solely to information collected by our Website. In an attempt to provide you with increased value, we may include third party links on our Website. These linked websites have separate and independent privacy policies. We therefore have no responsibility or liability for the content and activities of these linked websites. Nonetheless, we seek to protect the integrity of our Website and welcome any feedback about these linked websites (including if a specific link does not work).
            </TermsCenteredDiv>

            <CenteredDiv text={text} style={{marginBottom: '20px', fontSize: '20px', marginTop: '20px', zIndex: 'inherit'}}>
            Where Do I Direct Questions About this Privacy Policy?
            </CenteredDiv>
            <TermsCenteredDiv text={text} style={{zIndex: 'inherit'}}>
            We welcome your questions, comments and concerns about our Privacy Policy. Please send us any and all feedback pertaining to this Privacy Policy to triplecrowntrading2022@gmail.com.
            </TermsCenteredDiv>

            <CenteredDiv text={text} style={{marginBottom: '20px', fontSize: '20px', marginTop: '20px', zIndex: 'inherit'}}>
            What Are My California Privacy Rights?
            </CenteredDiv>
            <TermsCenteredDiv text={text} style={{zIndex: 'inherit'}}>
            Under California’s “Shine the Light” law, California residents who provide personal information in obtaining services for personal, family or household use are entitled to request and obtain from us once per calendar year information about the customer information that we shared, if any, with other businesses for their own direct marketing uses. If applicable, this information would include the categories of customer information and the names and addresses of those businesses with which we shared customer information for the calendar year previous to such request (e.g. requests made in 2014 will receive information regarding 2013 sharing activities).
            </TermsCenteredDiv>
            <TermsCenteredDiv text={text} style={{zIndex: 'inherit'}}>
            To obtain this information from us, please send an email message to triplecrowntrading2022@gmail.com with “Request for California Privacy Information” in the subject line and in the body of your message. We will then email you the requested information. Not all information sharing is covered by the “Shine the Light” requirements and only information on covered sharing will be included in our response.
            </TermsCenteredDiv>

            <TermsCenteredDiv text={text} style={{paddingBottom: '100px', paddingTop: '50px', justifyContent: 'right', zIndex:'inherit'}}>
            Last Updated 7/5/2022
            </TermsCenteredDiv>
        </div>
    )
}
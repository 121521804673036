import React from 'react';
import ArrowUpwardSharpIcon from '@mui/icons-material/ArrowUpwardSharp';
import ArrowDownwardSharpIcon from '@mui/icons-material/ArrowDownwardSharp';
import { Row, Column } from '../_shared';

export const percentDelta = (price_array, size, orientation) => {    
    const lastPrice = price_array[price_array.length-1]?.price ?? 0;
    const priceBefore = price_array[price_array.length-2]?.price ?? 0;
    const priceChange = Math.abs((lastPrice-priceBefore)).toFixed(0);
    const percent = Math.abs(((lastPrice-priceBefore)/priceBefore*100)).toFixed(0)
    if(orientation=='column'){
        if(lastPrice == priceBefore || priceBefore == 0) {
            return(
            <Column style={{alignItems: 'center', width:'fit-content', height: 'fit-content', background: 'transparent', color: 'gray'}}>
                <Row>
                    <div style={{color: 'inherit', fontWeight: 'bold', fontSize: size}}>
                    ~${0}
                    </div>
                </Row>
                <div style={{color: 'inherit', fontWeight: 'bold', fontSize: size}}>
                    ({percent}%)
                </div>
            </Column>)
        } else if (lastPrice > priceBefore) {
            return (
                <Column style={{alignItems: 'center', width:'fit-content', height: 'fit-content', background: 'transparent', color: '#4caf50'}}>
                    <Row style={{alignItems:'center', justifyContent: 'center'}}>
                        <ArrowUpwardSharpIcon color='inherit' style={{fontSize: size}}/>
                        <div style={{color: 'inherit', fontWeight: 'bold', fontSize: size}}>
                        ${priceChange}
                    </div>
                    </Row>
                    <div style={{color: 'inherit', fontWeight: 'bold', fontSize: size}}>
                        ({percent}%)
                    </div>
                </Column>
            )
        } else {
            return (
                <Column style={{alignItems: 'center', width:'fit-content', height: 'fit-content', background: 'transparent', color: '#f44336'}}>
                    <Row style={{alignItems:'center', justifyContent: 'center'}}>
                        <ArrowDownwardSharpIcon color='inherit' style={{fontSize: size}}/>
                        <div style={{color: 'inherit', fontWeight: 'bold', fontSize: size}}>
                        ${priceChange}
                    </div>
                    </Row>
                    <div style={{color: 'inherit', fontWeight: 'bold', fontSize: size}}>
                        ({percent}%)
                    </div>
                </Column>
            )
        }
    } else {
        if(lastPrice == priceBefore || priceBefore == 0) {
            return(
                <Row style={{justifyContent: 'center', alignItems: 'center', width:'fit-content', height: 'fit-content', background: 'transparent', color: 'gray'}}>
                    <div style={{textAlign: 'center', color: 'inherit', fontWeight: 'bold', fontSize: size}}>
                        ~${priceChange}   ({percent}%)
                    </div>
                </Row>)
        } else if (lastPrice > priceBefore) {
            return (
                <Row style={{justifyContent: 'center', alignItems: 'center', width:'fit-content', height: 'fit-content', background: 'transparent', color: '#4caf50'}}>
                    <ArrowUpwardSharpIcon color='inherit' style={{fontSize: size}}/>
                    <div style={{textAlign: 'center', color: 'inherit', fontWeight: 'bold', fontSize: size}}>
                        ${priceChange}   ({percent}%)
                    </div>
                </Row>
            )
        } else {
            return (
                <Row style={{justifyContent: 'center', alignItems: 'center', width:'fit-content', height: 'fit-content', background: 'transparent', color: '#f44336'}}>
                    <ArrowDownwardSharpIcon color='inherit' style={{fontSize: size}}/>
                    <div style={{textAlign: 'center', color: 'inherit', fontWeight: 'bold', fontSize: size}}>
                        ${priceChange}   ({percent}%)
                    </div>
                </Row>
            )
        }
    }
}
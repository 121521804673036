import { createTheme, ThemeProvider } from "@material-ui/core";
import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, 
    // Switch, Redirect
 } from "react-router-dom";
import { useSelectFromRedux } from "../utils/_hooks";
import { ContentActions, ContentState, possibleContents } from "../state/content";

// components
import Home from "./home";
import { useDispatch } from "react-redux";

function App() {
    const theme = createTheme({
        palette: {
            primary: {
                main: '#E5C078'
            }
        }
    })

    const dispatch = useDispatch();

    useEffect(() => {    
        window.addEventListener('popstate', (event) => {
            const path = window.location.pathname.replaceAll('-', ' ').replaceAll('/', '')
            if (possibleContents.includes(path)) {
                dispatch(ContentActions.setContent(path as ContentState['content']));
            }
        });
     }, []);

    return (
        <Router>
            <ThemeProvider theme={theme}>
                <Home/>
            </ThemeProvider>
        </Router>
    );
}

export default App;
function dispatch() {
    throw new Error("Function not implemented.");
}


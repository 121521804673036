import styled from "styled-components";
import { Button } from "@material-ui/core";
import { isMobileOnly } from "react-device-detect";
import { useSelectFromRedux } from "../../utils/_hooks";



interface TopBarProps{
    background: string;
    logo: string;
}

export const TopBar = styled.div`
    {}
    display: flex;
    width: 100%;
    position: fixed;
    overflow: scroll;
    z-index: 2;
    padding: 15px 40px 10px 30px;
    justify-content: space-between;
    align-items: center;    
    border: 1px solid transparent;
    borderLeft: transparent;
    borderTop: transparent;
    borderRight: transparent;
    
`;

export const StyledButton = styled(Button)`
    color: #0085FF;
    text-align: center;
    font-family: IBM Plex Mono;
    font-size: 14px;
    font-style: normal;
    font-weight: 400; 
    line-height: 28px;
`
export const StyledButton2 = styled(Button)`


    :hover {
        margin-left: 5px;
    }
`

export const DropDown = styled.div`
    width: 100%;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;


`
export const NameDiv = styled.text`
    color: #000;
    text-align: center;
    font-family: Cinzel;
    font-size: 20px;
    font-weight: 400;
    line-height: normal;  
    
    :hover {
        cusror: pointer;
    }

`
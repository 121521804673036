import { PublicDataActions } from ".";
import { PromiseOperation } from "..";
import api from "../../api";
import { Horse } from "../../types";
import { UserActions } from "../user";

export const loadPublicData : PromiseOperation<void> = () => async (dispatch) => {
    try {   
        const response = await api.get('/public/getHorses');
        dispatch(PublicDataActions.setHorses(response.data));
        dispatch(UserActions.setMyAssetData(response.data));
        const orders = await api.get('/public/getAllOrders');
        dispatch(PublicDataActions.setOrders(orders.data.m))
        const forum = await api.get('/public/getForums');
        dispatch(PublicDataActions.setForum(forum.data))
    } catch (error) {
        console.error
    }
    return;
}

export const serverAddNews : PromiseOperation<void> = (data) => async (dispatch) => {
    try {
        const response = await api.post('/public/createNews', data);
        console.log(response)
    } catch (error) {
        console.error
    }
    return;
}

export const serverAddEvent : PromiseOperation<void> = (data) => async (dispatch) => {
    try {
        const response = await api.post('/public/createEvent', data);
        console.log(response)
    } catch (error) {
        console.error
    }
    return;
}

export const serverAddHorse : PromiseOperation<void> = (data) => async (dispatch) => {
    try {
        const response = await api.post('/public/createHorse', data);
        console.log(response)
    } catch (error) {
        console.error
    }
    return;
}

export const serverModifyNews : PromiseOperation<void> = (data) => async (dispatch) => {
    try {
        const response = await api.post('/admin/modifyNews', data);
        console.log(response)
    } catch (error) {
        console.error
    }
    return;
}

export const serverModifyEvent : PromiseOperation<void> = (data) => async (dispatch) => {
    try {
        const response = await api.post('/admin/modifyEvent', data);
        console.log(response)
    } catch (error) {
        console.error
    }
    return;
}

export const serverModifyHorse : PromiseOperation<void> = (data) => async (dispatch) => {
    try {
        const response = await api.post('/admin/modifyHorse', data);
        console.log(response)
    } catch (error) {
        console.error
    }
    return;
}
import { PromiseOperation } from "..";
import api from "../../api";
import { Transaction } from "../../types";
import { ContentActions } from "../content";
import { PublicDataActions } from "../publicData";
import store from "../store";
import { UserActions } from "../user";

export const serverSignIn : PromiseOperation<void> = (data) => async (dispatch) => {
    try {   
        const response = await api.post('/user/signIn', data);
        if(response.data.s) {
            dispatch(UserActions.signIn({user: response.data.m, horses: store.getState().publicData.horses}));
            if(store.getState().content.content == 'sign in') {
                dispatch(ContentActions.setContent('trade'));
            }
            dispatch(UserActions.setSignInError(null));
            dispatch(UserActions.setSignUpError(null));
            localStorage.setItem('user', JSON.stringify(response.data.m));
            const leaderboard = await api.post('/public/getLeaderboard', {email: response.data.m.email});
            dispatch(PublicDataActions.setLeaderboard(leaderboard.data))
        } else {
            dispatch(UserActions.setSignInError(response.data.m))
        } 
    } catch (error) {
        console.error
    }
}

export const signOut : PromiseOperation<void> = () => async (dispatch) => {
    localStorage.removeItem('user')
    dispatch(UserActions.setUser(null))
    dispatch(ContentActions.setContent('home'))
    return;
}

export const serverSignUp : PromiseOperation<void> = (data) => async (dispatch) => {
    try {
        const response = await api.post('/user/create', data);
        console.log(response)
        if(response.data.s) {
            dispatch(ContentActions.setContent('sign in'));
            dispatch(UserActions.setSignUpError('User Registered Successfully'));
        } else {
            dispatch(UserActions.setSignUpError(response.data.m))
        } 
    } catch (error) {
        console.error
    }
    return;
}

export const userUpdate : PromiseOperation<void> = (data) => async (dispatch) => {
    try {
        const response = await api.post('/user/editProfile', data);
        if(response.data.s) {
            dispatch(UserActions.setUser(response.data.m));
            localStorage.setItem('user', JSON.stringify(response.data.m));
            dispatch(UserActions.setUpdateError(null))
        } else {
            dispatch(UserActions.setUpdateError(response.data.m))
        } 
    } catch (error) {
        console.error
    }
    return;
}

export const changePasswordServer : PromiseOperation<void> = (data) => async (dispatch) => {
    try {
        const response = await api.post('/user/changePassword', data);
        if(response.data.s) {
            console.log(response.data)
            dispatch(UserActions.setWrongPassword(false))
        } else {
            dispatch(UserActions.setWrongPassword(true))
        } 
    } catch (error) {
        console.error
    }
    return;
}

export const makeTransactionServer : PromiseOperation<void> = (data : Transaction) => async (dispatch) => {
    try {
        const response = await api.post('/user/makeTransaction', data);
        dispatch(UserActions.setTransactionResponse(response.data.m))
        const user = JSON.parse(localStorage.getItem('user') ?? '');
        dispatch(serverSignIn({email: user.email, password: user.password}))
    } catch (error) {
        console.error
    }
    
    return;
}

export const makeOrderServer : PromiseOperation<void> = (data) => async (dispatch) => {
    try {
        const response = await api.post('/user/makeOrder', data);
        dispatch(UserActions.setTransactionResponse(response.data.m))
        const user = JSON.parse(localStorage.getItem('user') ?? '');
        dispatch(serverSignIn({email: user.email, password: user.password}))
    } catch (error) {
        console.error
    }
    return;
}

export const addWatchListServer : PromiseOperation<void> = (data : {id: number, horse_id: number}) => async (dispatch) => {
    try {
        const response = await api.post('/user/addWatchList', data);
        const user = JSON.parse(localStorage.getItem('user') ?? '');
        dispatch(serverSignIn({email: user.email, password: user.password}))
    } catch (error) {
        console.error
    }
    return;
}

export const removeWatchListServer : PromiseOperation<void> = (data : {id: number, horse_id: number}) => async (dispatch) => {
    try {
        const response = await api.post('/user/removeWatchList', data);
        const user = JSON.parse(localStorage.getItem('user') ?? '');
        dispatch(serverSignIn({email: user.email, password: user.password}))
    } catch (error) {
        console.error
    }
    return;
}

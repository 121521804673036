import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { useSelectFromRedux } from '../../utils/_hooks';
import { CenteredDiv, TermsCenteredDiv} from '../site/styled';
import { CenteredText, Column, LineDivider, ModalContainer, Row, VerticalLineDivider } from '../_shared';
import CloseIcon from '@mui/icons-material/Close';
import { Post, Thread } from '../../types';
import api from '../../api';
import { PublicDataActions } from '../../state/publicData';
import { TextField, ThemeProvider, createTheme, Button, styled } from '@mui/material';
import { debounce } from 'lodash';
import { BrowserView, MobileView, isMobileOnly } from 'react-device-detect';


export default (props: any) => {
    const [text, error, success, background, logo, leaderboard, cuser, forum] 
        = useSelectFromRedux((state) => [
            state.color.scheme.text, 
            state.color.general.error, 
            state.color.general.success, 
            state.color.scheme.background, 
            state.color.scheme.logo, 
            state.publicData.leaderboard, 
            state.user.user,
            state.publicData.forum
        ]);
    
    const dispatch = useDispatch()

    const theme = createTheme({
        palette: {
            primary: {
                main: logo
            },
            secondary: {
                main: '#000000'
            }
        }
    })

    const [post, setPost] = useState<Post>({subject: '', content: '', thread_id: undefined, new_thread: true})

    const [incomplete, setIncomplete] = useState<Boolean>(false);
    
    const [complete, setComplete] = useState<Boolean>(false);

    const [newThread, setNewThread] = useState<Boolean>(false);

    const [openResponse, setOpenResponse] = useState<number>(-1);

    const postThread = async () => {
        if (post.new_thread) {
            if(!post.subject) {
                setIncomplete(true);
                return;
            }
        } else {
            if(!post.thread_id) {
                setIncomplete(true);
                return;
            }
        }
        if(!post.content) {
            setIncomplete(true)
            return;
        }
        await api.post('/public/postForum', {post: post, user: cuser})
        const forums = await api.get('/public/getForums')
        dispatch(PublicDataActions.setForum(forums.data))
        setComplete(true)
        setOpenResponse(-1)
    }

    const addResponse = (index: number, thread: Thread) => {
        setOpenResponse(index)
        setComplete(false)
        setPost({
            subject: '',
            content: '',
            thread_id: thread.id,
            new_thread: false
        })
    }

    useEffect(() => {
        const fetchData = async () => {
            const leaderboard = await api.post('/public/getLeaderboard', {email: cuser?.email});
            dispatch(PublicDataActions.setLeaderboard(leaderboard.data))
        }
        fetchData()
    }, [])


    const [windowSize, setWindowSize] = useState({
        windowWidth: window.outerWidth,
        windowHeight: window.outerHeight,
      });
    const [resized, setResized] = useState<Boolean>(window.outerWidth < 990)
    
      useEffect(() => {
        const handleResize = debounce(() => {
          setWindowSize({
            windowWidth: window.outerWidth,
            windowHeight: window.outerHeight,
          });
          setResized(window.outerWidth < 990);

        }, 250); // Adjust the debounce time (in milliseconds) as needed
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          handleResize.cancel(); // Cancel any pending debounced calls
          window.removeEventListener('resize', handleResize);
        };
      }, []);

    return (
        <>
        {/* <BrowserView> */}
        <div style={{display: 'flex', flexDirection: !resized ? 'row' : 'column-reverse', zIndex: 4, paddingTop: '130px', alignItems:!resized ? 'flex-start' : 'center'}}>
           <Column style={{width: !resized ?'65%':'90%', alignItems: 'center', position: 'relative'}}>
                <CenteredText text={text} style={{width:'100%', paddingLeft:'0px', fontSize: '40px', zIndex: 4, height: '50px', marginBottom: '30px'}} >
                    Forum
                </CenteredText>
                {cuser &&
                <div style={{position: (!isMobileOnly) ? 'absolute': 'relative', top: (!isMobileOnly) ? 10: -10, right: (!isMobileOnly) ? 10: 0 }}>
                    <ThemeProvider theme={theme}>
                        <Button variant="contained" color='secondary' style={{ color: '#000000', background: logo, width: '150px', zIndex: 5}} onClick={() => {
                            setNewThread(!newThread)
                            setComplete(false)
                            setPost({subject: '', content: '', thread_id: undefined, new_thread: true})
                            setOpenResponse(-1)
                        }}>
                            New Thread
                        </Button>
                    </ThemeProvider>
                </div>}
                {forum.map((thread, index) => (
                    <Column style={{width: '90%', alignItems: 'center'}} key={index}>
                        <LineDivider 
                            firstColor={background} 
                            secondColor={logo} 
                            thirdColor={background} 
                            style={{ width: '80%', marginBottom: '20px'}}
                        />
                        <div style={{width:'100%', paddingLeft:'20px', fontSize: '20px', zIndex: 4, height: '50px', textAlign: 'left', color: text}} >
                            {thread.subject}
                        </div>
                        {thread.posts.map((post, index) => (
                            <Column style={{width: '90%', alignItems: 'flex-end', color: text}} key={index}>
                                <div style={{width:'100%', paddingLeft:'20px', fontSize: '15px', zIndex: 4, height: '20px', textAlign: 'left', fontWeight: 'bold'}} >
                                    {post.user_name}
                                </div>
                                <div style={{width:'100%', paddingLeft:'20px', fontSize: '15px', zIndex: 4, textAlign: 'left'}} >
                                    {post.content.split('\n').map((line, index) => (
                                        <div key={index} style={{marginBottom: '10px'}}>
                                            {line} 
                                        </div>
                                    ))} 
                                </div>
                            </Column>
                        ))}
                        {openResponse != index && cuser &&
                        <ThemeProvider theme={theme}>
                            <Button variant="contained" color='secondary' style={{ color: '#000000', background: logo, width: '200px', zIndex: 4, marginBottom: '20px'}} onClick={() => addResponse(index, thread)}>
                                Add a Response
                            </Button>
                        </ThemeProvider>}
                        {openResponse == index &&
                        <Column style={{width: '90%', alignItems: 'left'}}>
                            <ThemeProvider theme={theme}>
                                <TextField 
                                    label='Response'
                                    variant='standard'
                                    color='primary'
                                    focused
                                    inputProps={{ style: {color: text}}}
                                    multiline
                                    value={post.content}
                                    onChange={(event) => setPost({...post, content: event.target.value})}
                                    sx={{input: {color: text}, background: 'transparent'}}
                                    style={{marginBottom: '10px', width: '90%', background: 'transparent', color: logo, zIndex: 4}}
                                />
                            </ThemeProvider>
                            <Row style={{justifyContent: 'space-between', width: '90%'}}>
                                <ThemeProvider theme={theme}>
                                    <Button variant="contained" color='secondary' style={{ color: '#000000', background: logo, width: '200px', zIndex: 4, marginBottom: '20px'}} onClick={() => postThread()}>
                                        Post Response
                                    </Button>
                                    <Button variant="contained" color='secondary' style={{ color: 'white', background: 'red', width: '100px', zIndex: 4, marginBottom: '20px'}} onClick={() => setOpenResponse(-1)}>
                                        Cancel
                                    </Button>
                                </ThemeProvider>
                            </Row>
                        </Column>
                        }
                    </Column>
                ))}
                {newThread &&
                <ModalContainer color={text} background={background} style={{borderColor: logo, borderWidth: '5px', borderStyle: 'solid', height: '80%' }} >
                    <CloseIcon style={{top: '15x', right: '15px', position: 'absolute', cursor: 'pointer'}} onClick={() => setNewThread(false)}/>
                    <Column style={{alignItems: 'center', zIndex: 4, background: 'transparent', marginTop: '30px', width: "100%"}}>
                        <ThemeProvider theme={theme}>
                            <TextField 
                                label='Subject'
                                variant='standard'
                                color='primary'
                                focused
                                value={post?.subject}
                                onChange={(event) => setPost({...post, subject: event.target.value})}
                                sx={{input: {color: text}, color: text}}
                                style={{marginBottom: '10px', width: '90%', background: 'transparent', zIndex: 4, color: text}}
                            />
                            <TextField 
                                label='Content'
                                variant='standard'
                                color='primary'
                                focused
                                multiline
                                inputProps={{ style: {color: text}}}
                                value={post.content}
                                onChange={(event) => setPost({...post, content: event.target.value})}
                                sx={{input: {color: text}, color: text}}
                                style={{marginBottom: '10px', width: '90%', background: 'transparent', zIndex: 4}}
                            />
                        </ThemeProvider>
                    </Column>

                    {incomplete && <CenteredText text={error} style={{paddingLeft: 0, marginTop: '10px'}}>
                            Information is missing.
                        </CenteredText>}
                        {complete && <CenteredText text={success} style={{paddingLeft: 0, marginTop: '10px'}}>
                            Successfully posted.
                        </CenteredText>}
                    <Button
                            variant='contained'
                            style={{alignSelf: 'center', background: logo, color: background, marginTop: '20px', marginBottom: '200px'}}
                            onClick={postThread}
                        >
                            Post Thread
                    </Button>
                </ModalContainer>}
           </Column>
           <Column style={{width: !resized ? '30%':'90%', zIndex: 4, alignItems: 'center', position: !resized ? 'relative': 'relative'}}>
                {!resized && <VerticalLineDivider 
                    firstColor={background} 
                    secondColor={logo} 
                    thirdColor={background} 
                    style={{height: '100%', zIndex: 12, position: 'absolute', left: -20}}
                />}
                <div style={{position: !resized ? 'fixed':'relative', background: 'transparent'}}>
                    <CenteredText text={text} style={{width:'100%', fontSize: '40px', zIndex: 4, height: '50px', paddingLeft:'0px'}} >
                        Leaderboard
                    </CenteredText>
                    <Column style={{ justifyContent: 'left', marginTop: '20px'}}>
                        {leaderboard.board.map((user, index) => (
                            <Row style={{marginLeft: (!isMobileOnly) ? '30px': '0px', width: '100%', alignItems:'center'}} key={index}>
                                <div style={{color: text, fontSize: (index < 3) ? '30px' : '15px', width: '40px'}}>
                                    {index+1}.
                                </div>
                                <div style={{
                                        color: logo, marginLeft: '10px', 
                                        fontSize: (index < 3) ? '30px' : '15px', 
                                        fontWeight: cuser?.email == user.email ? 'bold' : 'normal'
                                    }}>
                                    {user.name}
                                </div>
                                <div style={{color: text, marginLeft: '10px', fontSize: (index < 3) ? '30px' : '15px'}}>
                                    ${user.value.toLocaleString(undefined, {maximumFractionDigits: 1})}
                                </div>
                            </Row>
                        ))}
                        {leaderboard.place > 20 && <Column style={{color: text, alignItems: 'center', width: '50%', marginBottom: '10px'}}>
                            <div>
                                .
                            </div>
                            <div>
                                .
                            </div>
                            <div>
                                .
                            </div>
                        </Column>}
                        {leaderboard.place > 20 && <Row style={{marginLeft: '30px', width: '100%'}}>
                            <div style={{color: text, fontSize: '15px', width: '40px'}}>
                                {leaderboard.place}.
                            </div>
                            <div style={{
                                    color: logo, marginLeft: '10px', 
                                    fontSize: '15px', 
                                    fontWeight: 'bold'
                                }}>
                                {cuser?.first_name + ' ' + cuser?.last_name}
                            </div>
                            <div style={{color: text, marginLeft: '10px', fontSize:'15px'}}>
                                ${leaderboard.value}
                            </div>
                        </Row>}
                    </Column>
                </div>
                {resized && <LineDivider 
                    firstColor={background} 
                    secondColor={logo} 
                    thirdColor={background} 
                    style={{width: '100%', zIndex: 12, marginTop:'30px', marginBottom: '30px'}}
                />}
           </Column>
        </div>
        {/* </BrowserView>
        <MobileView>

        </MobileView> */}
        </>
    )
}